.modalContainer {
    display: flex;  
    padding: 2rem 4rem;
    align-items: center;
    flex-direction: column;
}

.headerText {
    margin: 1rem 0 2rem;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    font-weight: normal;
}

.progressBarContainer {
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;
    max-width: 100%;
    box-shadow: none;
    position: relative;
    background: rgba(0, 0, 0, .1);
}

.progressBar {
    min-width: 0;
    height: 1.75em;
    display: block;
    line-height: 1;
    position: relative;
    background: #388CEF;
}

.progressBarText {
    display: flex;
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
    align-self: flex-start;
    justify-content: space-between;
}
