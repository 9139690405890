.modalContainer {
    display: flex;
    padding: 4rem 6rem;
    flex-direction: column;
}

.headerText {
	font-weight: normal;
	font-size: 30px;
	line-height: 36px;
	text-align: center;
	color: #272828;
	margin: 2rem;
}
   
.saveButton {
    align-self: center;
}