.mainContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 4rem;
}

.headerGroup {
  display: flex;
}

.alertIcon {
  pointer-events: none;
  font-size: 30px !important;
  margin-right: 0 !important;
  color: #f56666 !important;
  background-color: #feeded !important;
}

.unreadIcon {
  pointer-events: none;
  font-size: 30px !important;
  margin-right: 0 !important;
  color: #8bc334 !important;
  background-color: #f1f8e7 !important;
}

.headerTextColumn {
  display: flex;
  margin-left: 1.5rem;
  flex-direction: column;
  justify-content: space-evenly;
}

.headerText {
  font-size: 30px;
  line-height: 36px;
  font-weight: normal;
}

.headerSubText {
  display: flex;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
}

.headerSubText text:nth-child(2) {
  font-weight: normal;
  margin: 0 4px;
}

.cardContent {
  display: flex;
  padding: 2rem;
  flex-direction: column;
}

.messageContent {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  text-align: justify;
  /* margin-bottom: 4rem; */
  padding-bottom: 3rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #e7e7e7;
}

.attachmentsHeader {
  display: flex;
  padding: 1.5rem;
  align-items: center;
  border-top: 1px solid #e7e7e7;
}

.attachmentIcon {
  pointer-events: none;
  font-size: 30px !important;
  margin-right: 20px !important;
  background-color: #f7f8f6 !important;
}

.attachmentText {
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
}

.attachmentsGroup {
  display: flex;
  flex-direction: column;
}

.attachmentRow {
  display: flex;
  padding: 1.5rem 0;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e7e7e7;
}

.attachmentRow:nth-last-child(1) {
  border-bottom: 1px solid #e7e7e7;
}

.attachmentDisplay {
  display: flex;
  align-items: center;
}

.attachmentDetails {
  display: flex;
  margin-left: 2rem;
  flex-direction: column;
}

.attachmentFileName {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
  font-weight: normal;
}

.attachmentSize {
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
}

.downloadButtonGroup {
  display: flex;
  flex-direction: column;
}

.downloadButton {
  font-weight: 900 !important;
  font-size: 33px !important;
  line-height: 38px !important;
  text-align: center !important;
  color: #8bc334 !important;
  background-color: #f1f8e7 !important;
  margin-right: 0 !important;
}

.downloadButton:hover {
  color: #ffffff !important;
  background-color: #8bc334 !important;
}

.dowloadButtonText {
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.actionButtonGroup {
  padding: 2rem 0rem;
  display: flex;
  flex-wrap: wrap;
}

.actionButtonGroup div,
.actionButtonGroup button:not(:last-child) {
  margin-right: 1rem;
}

.forwardButton,
.starButton,
.deleteButton {
  padding: 1rem 3rem;
}

.forwardButton,
.starButton,
.deleteButton {
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
}

.forwardButton {
  color: #8bc334;
  background: #f1f8e7;
}

.forwardButton:hover {
  color: #ffffff;
  background: #8bc334;
}

.starButton {
  color: #ffb800;
  background: #fff6e0;
}

.starButton:hover {
  color: #ffffff;
  background: #ffb800;
}

.deleteButton {
  color: #f56666;
  background: #fdecec;
}

.deleteButton:hover {
  color: #ffffff;
  background: #f56666;
}

.replyContainer {
  margin: 2rem 0;
  position: relative;
}

.replyContainer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom-color: white;
  border-top: 0;
  margin-left: -20px;
  margin-top: -20px;
}

.replyContent {
  display: flex;
  background: white;
  flex-direction: column;
}

.replyContent {
  padding: 4rem 5rem;
}

.replyHeaderText {
  font-size: 30px;
  line-height: 36px;
  font-weight: normal;
  margin-bottom: 1rem;
}

.replyHeaderSubText {
  display: flex;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  margin-bottom: 1.5rem;
}

.replyHeaderSubText text:nth-child(2) {
  margin: 0 4px;
  font-weight: normal;
}

.dropdownGroup {
  flex-grow: 1;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem;
  flex-direction: column;
}

.dropdownText {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
}

.dropdownBox {
  height: 60px;
  padding: 1.25rem 1rem !important;
  font-size: 20px !important;
  margin-top: 0.5rem !important;
  border-radius: 5px !important;
  border: 1px solid black !important;
}

.addMoreUsersButton {
  height: 60px;
  padding: 1rem 4rem;
  margin-bottom: 3rem;
  align-self: center;
  border: none;
  cursor: pointer;
  color: black;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: #e4e4e4;
}

.addMoreUsersButton:hover {
  background-color: #f2f2f2;
}

.replyInputBox {
  height: 310px;
}

.blueButton {
  margin-top: 2rem;
  align-self: center;
}

.modalContainer {
  display: flex;
  padding: 2rem 4rem 3rem;
  flex-direction: column;
}

.modalHeaderText {
  margin-top: 1rem;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
}

@media screen and (max-width: 895px) {
  .deleteButton {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 992px) {
  .mainContainer {
    padding: 4rem 6rem;
  }
}

@media screen and (min-width: 1024px) {
  .cardContent {
    padding: 2rem 3rem;
  }

  .attachmentRow {
    padding: 1.5rem;
  }

  .replyContent {
    padding: 4rem 6rem;
  }
}

@media screen and (min-width: 1100px) {
  .replyContent {
    padding: 4rem 10rem;
  }
}

@media screen and (min-width: 1200px) {
  .cardContent {
    padding: 2rem 7.5rem;
  }

  .mainContainer {
    padding: 4rem 8rem;
  }

  .replyContent {
    padding: 4rem 15.5rem;
  }
}
