.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 4rem;
  margin-bottom: 5rem;
}

.mainCard {
  display: flex;
  padding: 0 2rem;
}

.cardContent {
  /* width: 490px; */
  flex-grow: 1;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 4rem 2.5rem;
}

.serviceHeaderText {
  font-size: 36px;
  line-height: 46px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 10px;
}

.descriptionText {
  margin-bottom: 2rem;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  font-style: normal;
  display: block;
}

/* .openingTime { */

/* margin-right: 2rem; */

/* } */

.required {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #f56666;
}

/* .openingTime { */

/* margin-right: 2rem; */

/* } */

/* .closingTime { */

/* margin-right: 2rem; */

/* } */

.buttonGroup {
  width: 100%;
  display: flex;
  margin-top: 1.5rem;
}

.buttonGroup div {
  margin-right: 1rem;
}

.buttonMargin {
  margin-left: 10px;
}

.buttonStep {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.buttonStep button {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #979797;
  cursor: pointer;
  background-color: unset;
  border: unset;
  padding: 0;
}

.buttonStep button:hover {
  color: #222222;
}

.buttonStep button i {
  width: unset;
}

.buttonStep button.buttonStepBack i {
  margin-right: 0.5rem;
}

.buttonStep button.buttonFinishLater i {
  margin-left: 0.5rem;
  margin-right: 0;
}

.buttonStep button i {
  width: unset;
}

.buttonStep button.buttonStepBack i {
  margin-right: 0.5rem;
}

.buttonStep button.buttonFinishLater i {
  margin-left: 0.5rem;
  margin-right: 0;
}

.buttonStep i {
  background-color: unset !important;
}

.buttonNextStep {
  padding: 1.3rem 3rem;
  background-color: rgb(139 195 52 / 0.12);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #8bc334;
}

.buttonNextStep:hover {
  color: #ffffff;
  background-color: #8bc334;
}

.stepsColumn {
  width: 150px !important;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.stepItem {
  background: #8bc334;
  border-radius: 222px;
  color: #ffffff;
  padding: 0.5rem 1rem;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 10px;
}

.stepItemIndicatorContainer {
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 50%;
  /* margin-bottom: 10px; */
  margin-right: 10px;
  position: relative;
  background-color: #ffffff;
}

.stepItemContainer {
  margin-bottom: 10px;
  cursor: pointer;
}

.stepItemContainer .stepItemCurrentText {
  display: flex;
  align-items: center;
}

.stepItemContainer:hover .stepItemFill {
  background-color: #222222;
}

.stepItemContainer:hover .stepItemCurrentText {
  color: #222222;
}

.stepItemTickContainer .stepContainerText {
  color: #8bc334 !important;
}

.stepItemCrossContainer .stepContainerText {
  color: #f56666 !important;
}

.stepItemFill {
  width: 7px;
  height: 7px;
  top: 14px;
  left: 14px;
  border: none;
  border-radius: 50%;
  position: absolute;
  background-color: #c4c4c4;
}

.stepItemFillDone {
  position: absolute;
  top: 10px;
  right: 13px;
  display: inline-block;
  transform: rotate(45deg);
  height: 14px;
  width: 7px;
  border-bottom: 3px solid #8bc334;
  border-right: 3px solid #8bc334;
}

.stepItemUnfinished {
  top: 9px;
  left: 8px;
  position: absolute;
  font-size: 16px !important;
  color: #f56666 !important;
}

.CurrentItemContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: -25px;
}

.CurrentItemContainer .currentItemFill {
  background-color: #222222;
}

.CurrentItemContainer .stepItemCurrentText {
  color: #222222;
}

.stepItemCurrent {
  display: inline-block;
  width: 60px;
  height: 35px;
  border: none;
  border-radius: 0 50px 50px 0;
  margin-right: 10px;
  position: relative;
  background-color: #ffffff;
}

.currentItemFill {
  width: 7px;
  height: 7px;
  top: 14px;
  right: 14px;
  border: none;
  border-radius: 50%;
  position: absolute;
  background-color: #c4c4c4;
}

.stepItemCurrentText {
  color: #c0c2bf;
  /* width: 58%; */
}

.stepContainerText {
  color: #222222;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 992px) {
  .cardContent {
    width: 100%;
    padding: 5rem 4rem;
  }

  .stepsColumn {
    width: 200px;
  }
}

@media screen and (min-width: 1024px) {
  .required {
    font-size: 20px;
  }
}

@media screen and (min-width: 1100px) {
  .tableRow {
    font-size: 20px;
  }

  .cardContent {
    width: 872px;
  }

  .tableContainer {
    width: 760px;
  }

  .serviceHeaderText {
    font-size: 39px;
  }
}

@media screen and (min-width: 1100px) {
  .mainCard {
    display: flex;
  }
  .mainCard,
  .serviceHeaderContainer {
    max-width: 1037px;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
  }

  .stepsColumn {
    width: unset;
  }
}

@media screen and (min-width: 1150px) {
  .stepItemDoneText {
    width: unset;
  }
}

.serviceOperatingTimes {
  background: #ffffff;
  /* border-top: 1px solid #e4e4e4; */
  box-sizing: border-box;
}

.headerContainer {
  border: 1px solid #e4e4e4;
  flex-grow: 1;
}

.headerText {
  flex-shrink: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;

  color: #222222;
}

.toggleIcon {
  width: 55px !important;
  height: 55px !important;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 117% */

  display: flex;
  align-items: center;

  /* color: #ffffff; */
  /* background: rgba(139, 195, 52, 1); */
}

.tableContainer {
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}

.tableOperatingTimes > thead > tr > th {
  width: 25%;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  /* identical to box height, or 173% */

  color: #c9c9c9;
}

.tableCareOperatingTimes > thead > tr > th {
  width: 20%;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  /* identical to box height, or 173% */

  color: #c9c9c9;
}

.tableOperatingTimes > tbody > tr > td:first-child,
.tableOperatingTimes > thead > tr > th:first-child {
  width: 25%;
}

.tableOperatingTimes > tbody > tr > td:nth-child(2),
.tableOperatingTimes > thead > tr > th:nth-child(2) {
  width: 28%;
}

.tableOperatingTimes > tbody > tr > td:nth-child(3),
.tableOperatingTimes > thead > tr > th:nth-child(3) {
  width: 28%;
}

.tableOperatingTimes > tbody > tr > td:last-child,
.tableOperatingTimes > thead > tr > th:last-child {
  width: 17%;
}

/* / */

@media screen and (max-width: 920px) {
  .tableCareOperatingTimes > tbody > tr > td:first-child,
  .tableCareOperatingTimes > thead > tr > th:first-child {
    width: 31%;
  }

  .tableCareOperatingTimes > tbody > tr > td:nth-child(2),
  .tableCareOperatingTimes > thead > tr > th:nth-child(2) {
    width: 31%;
  }

  .tableCareOperatingTimes > tbody > tr > td:nth-child(3),
  .tableCareOperatingTimes > thead > tr > th:nth-child(3) {
    width: 31%;
  }

  .tableCareOperatingTimes > tbody > tr > td:nth-child(4),
  .tableCareOperatingTimes > thead > tr > th:nth-child(4) {
    width: 5%;
  }

  .tableCareOperatingTimes > tbody > tr > td:nth-child(5),
  .tableCareOperatingTimes > thead > tr > th:nth-child(5) {
    width: 31%;
  }

  .tableCareOperatingTimes > tbody > tr > td:last-child,
  .tableCareOperatingTimes > thead > tr > th:last-child {
    width: 31%;
  }
}

.tableCareOperatingTimes > tbody > tr > td:first-child,
.tableCareOperatingTimes > thead > tr > th:first-child {
  width: 20%;
}

.tableCareOperatingTimes > tbody > tr > td:nth-child(2),
.tableCareOperatingTimes > thead > tr > th:nth-child(2) {
  width: 20%;
}

.tableCareOperatingTimes > tbody > tr > td:nth-child(3),
.tableCareOperatingTimes > thead > tr > th:nth-child(3) {
  width: 20%;
}

/* .tableCareOperatingTimes > tbody > tr > td:nth-child(4),
.tableCareOperatingTimes > thead > tr > th:nth-child(4) {
  width: 25%;
} */

.tableCareOperatingTimes > tbody > tr > td:nth-child(5),
.tableCareOperatingTimes > thead > tr > th:nth-child(5) {
  width: 20%;
}

.tableCareOperatingTimes > tbody > tr > td:last-child,
.tableCareOperatingTimes > thead > tr > th:last-child {
  width: 25%;
}

/*  */

.tableOperatingTimes > tbody > tr:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}

.tableCareOperatingTimes > tbody > tr:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}

.tableOperatingTimes > thead > tr {
  border-bottom: 1px solid #e4e4e4;
}

.tableCareOperatingTimes > thead > tr {
  border-bottom: 1px solid #e4e4e4;
}

.tableCareOperatingTimes > tbody > tr > td:first-child,
.tableCareOperatingTimes > thead > tr > th:first-child {
  width: 18%;
}

.tableCareOperatingTimes > tbody > tr > td:not(:first-child):not(:last-child),
.tableCareOperatingTimes > thead > tr > th:not(:first-child):not(:last-child) {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.tableCareOperatingTimes > tbody > tr > td:last-child,
.tableCareOperatingTimes > thead > tr > th:last-child {
  padding-left: 0.5rem;
}

.tableCareOperatingTimes > thead > tr {
  border-bottom: 1px solid #e4e4e4;
}

.dayName {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;

  color: #272828;
  text-transform: capitalize;
}

.timePicker {
  height: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  /* or 200% */

  display: flex;
  align-items: center;

  color: #272828;
  margin-right: 1rem;
}

.timePicker1 {
  height: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  /* or 200% */

  display: flex;
  align-items: center;

  color: #272828;
}

.serviceHeaderContainer {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

.serviceHeaderContainer h1 {
  font-weight: 400;
  font-size: 39px;
  line-height: 50px;
  letter-spacing: -1px;
  color: #272827;
  margin-bottom: 0.8rem;
}

.serviceHeaderContainer p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
