.modalContainer {
  background: #ffffff;
  box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.06);
}

.warningIcon {
  width: 70px;
  height: 70px;
  background: rgba(245, 102, 102, 0.2);
  font-weight: 900;
  font-size: 30px;
  line-height: 34px;
  /* identical to box height */

  text-align: center;

  color: #f56666;
}

.headerText {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;

  color: #272828;
}

.headerSub {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;

  color: #f56666;
  background: #fde0e0;
  border-radius: 3px;
}

.satDescription {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  /* or 173% */

  color: #272828;
  height: 200px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  resize: none;
}

.copyButton {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #8bc334;
  width: 247px;
  height: 60px;
  background: rgba(139, 195, 52, 0.12);
  border-radius: 5px;
}

.copyButton:hover {
  background: #8bc334;
  color: #ffffff;
}

.footerText {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  text-align: center;

  color: #272828;
}

.closeButton {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #388cef;
  width: 180px;
  height: 60px;
  background: rgba(56, 140, 239, 0.12);
  border-radius: 5px;
}

.closeButton:hover {
  background: #388cef;
  color: #ffffff;
}

.confirmButton {
  width: 238px;
  height: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #f56666;
  background: rgba(245, 102, 102, 0.1);
  border-radius: 5px;
}

.confirmButton:hover {
  background: #f56666;
  color: #ffffff;
}

.topCloseButton {
  width: 50px;
  height: 50px;
  font-style: normal;
  font-weight: 900;
  font-size: 23px;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
  color: #f56666;
  background: rgba(245, 102, 102, 0.12);
}

.topCloseButton:hover {
  background: rgba(245, 102, 102, 0.2);
}

.borderLine {
  width: 100%;
  border: 1px solid #e7e7e7;
}
