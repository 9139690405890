.mainContainer {
    display: flex;
    padding: 4rem 6rem;
    flex-direction: column;
    background-color: white;
}

.urgentMessageIcon, .urgentMessageIconSelected {
    border-radius: 5px;
    padding: 12px 18px !important;
    font-size: 50px !important;
    line-height: 57px !important;
    border: 3px solid #E4E4E4 !important;
    background-color: #f7f8f6 !important;
}   

.urgentMessageIconSelected {
    border: 0 !important;
    color: #F56666 !important;
    border: 3px solid #feeded !important;
    background-color: #feeded !important;
}

.regularMessageIcon, .regularMessageIconSelected {
    border-radius: 5px;
    padding: 12px 18px !important;
    font-size: 50px !important;
    line-height: 57px !important;
    color: #E4E4E4 !important;
    background-color: white !important;
    border: 3px solid #E4E4E4 !important;
}

.regularMessageIconSelected {
    border: 0 !important;
    color: #8BC334 !important;
    border: 3px solid #f1f8e7 !important;
    background-color: #f1f8e7 !important;
}

.messageSelectionRow {
    display: flex;
    margin-bottom: 5rem;
}

.messageButtonGroup {
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.messageButtonGroup:nth-child(1) {
    border-right: 1px solid #E4E4E4;
}

.messageButtonGroup:nth-child(2) {
    border-left: 1px solid #E4E4E4;
}

.messageButtonText {
    margin-top: 1rem;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    font-weight: normal;
}

.messageSubjectInput {
    margin-bottom: 2.5rem;
}

.sendToEverybodyRow {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
}

.subText {
    margin-right: 1rem;
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
}

.dropdownGroup {
    flex-grow: 1;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 2rem;
    flex-direction: column;
}

.dropdownText {
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
}

.dropdownBox {
    height: 60px;
    padding: 0.75rem 1.25rem !important;
    font-size: 20px !important;
    margin-top: 0.5rem !important;
    border-radius: 5px !important;
    border: 1px solid black !important;
}

.addMoreUsersButton {
    height: 60px;
    padding: 1rem 4rem;
    margin-bottom: 4.5rem;
    align-self: flex-start;
    border: none;
    cursor: pointer;
    color: black;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
    border-radius: 5px;
    text-decoration: none;
    background-color: rgba(228, 228, 228, 0.5);
}

.addMoreUsersButton:hover {
	background-color: #E5E5E5;
}

.messageBox {
    height: 310px;
    margin-bottom: 1.5rem;
}

.blueButton {
    align-self: center;
}

@media screen and (min-width: 992px) {

    .mainContainer {
        padding: 4rem 8rem;
    }

}

@media screen and (min-width: 1200px) {

    .mainContainer {
        padding: 4rem 10rem;
    }

}

@media screen and (min-width: 1366px) {

    .mainContainer {
        padding: 4rem 12em;
    }

}