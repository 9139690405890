.mainContainer {
	max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
}  

.backButtonContainer {
	text-align: right;
}

.backButtonText {
    width: 50px;
    height: 50px;
    position: relative;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    align-self: flex-end;
    border-radius: 50%;
    background-color: #ffffff;
	outline: none;
}

.backButton {
    top: 13px;
    left: 13px;
    width: 25px;
    height: 25px;
    position: absolute;
}

.backButtonText:hover > .backButton{
	color: #222222!important;
}

.mainCard {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.cardContent {
    display: flex;
    flex-direction: column;
    padding: 60px 70px;
}

.textGroup {
    width: 310px;
}

.headerText {
    font-size: 39px;
    line-height: 50px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: .5rem;
    letter-spacing: -1px;
}

.bodyText {
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    font-style: normal;
}

.sendCodeButton {
    margin-top: 1.5rem;
}