.mainContainer {
  max-width: 1366px;
  margin: 4rem auto 5rem auto;
}

.content,
.cardContent {
  padding: 0 2rem;
}

.headerText {
  font-size: 39px;
  line-height: 50px;
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 1rem;
}

.headerSubText {
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
}

.cardContainer {
  display: flex;
  margin-top: 4rem;
  flex-direction: column;
}

.navigationOptionsRow {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

.navigationOptionSelected,
.navigationOption {
  width: 100%;
  padding: 45px 30px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  font-weight: normal;
}

.navigationOptionSelected {
  border-bottom: 4px solid #ffffff;
  background: #ffffff;
}

.navigationOption {
  border-bottom: 4px solid #eaeee8;
  color: #c9c9c9;
  background: #f7f8f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigationOption:hover {
  /* color: #222222; */
  /* background: #ffffff; */
}

.navigationOptionsRow > div:nth-child(1) {
  border-right: 2px solid #eaeee8;
}

.navigationOptionsRow > div:nth-child(2) {
  border-right: 2px solid #eaeee8;
  border-left: 2px solid #eaeee8;
}

.navigationOptionsRow > div:nth-child(3) {
  border-left: 2px solid #eaeee8;
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  margin-bottom: 4px;
  padding: 2rem;
  display: none;
}

.searchInput {
  margin-right: 0rem;
  font-size: 26px !important;
  line-height: 36px !important;
  font-weight: normal !important;
  color: #bebebe !important;
  margin-bottom: 1rem;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.searchInput input {
  border: 1px solid #bebebe !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
}

.searchInput input:focus {
  color: #bebebe !important;
}

.searchInput::placeholder {
  color: #bebebe !important;
}

.filterDropdown,
.filterDropdown > div {
  color: #bebebe !important;
  font-size: 26px;
  line-height: 36px;
  align-self: center;
  font-weight: normal !important;
}

.filterDropdown:not(:last-child) {
  margin-right: 3rem;
}

.reportsContainer {
  display: flex;
  background: #ffffff;
  margin-bottom: 4rem;
  padding: 4rem 12rem;
}

.navigationOption {
  position: relative;
}

.navigationOption:hover div {
  display: block !important;
}

.navigationOption div {
  position: absolute !important;
  top: -22%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 24px !important;
  text-align: center !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  background-clip: padding-box !important;
  color: #f56666 !important;
  background-color: #edd5d5 !important;
  border: 1px solid #edd5d5 !important;
  width: 160px;
  display: none !important;
}

.pageButtonGroup {
  display: flex;
  margin-top: 5rem;
  justify-content: center;
  display: none;
}

.pageButtonStartEnd {
  font-size: 20px !important;
  margin-right: 10px !important;
  background-color: #f7f8f6 !important;
}

.pageButtonStartEnd:nth-last-child(1) {
  margin-right: 0 !important;
}

.pageButtonSelected,
.pageButton {
  padding: 18px 26px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
}

.pageButton {
  color: #222222;
  background: #f7f8f6;
}

.pageButton:hover {
  background: #e5e5e5;
}

.pageButtonSelected {
  color: #388cef;
  background: #e7f1fd;
}

@media screen and (min-width: 992px) {
  .navigationOption div {
    top: -30%;
  }
}

@media screen and (min-width: 1024px) {
  .searchInput {
    -ms-flex: 0 0 36%;
    flex: 0 0 36%;
    max-width: 300px;
    margin-bottom: 0;
    margin-right: 3rem;
  }
}

@media screen and (min-width: 1100px) {
  .content,
  .cardContent {
    padding: 0 8rem;
  }
  .searchInput {
    font-size: 30px !important;
  }
  .filterDropdown > div,
  .filterDropdown > i {
    font-size: 30px !important;
  }
}
