.reportRecordContainer {
  padding: 4rem 2rem;
  margin-bottom: 4px;
  background-color: white;
}

.blueButton {
  margin-top: 2rem;
  align-self: center;
}

.modalContainer {
  display: flex;
  padding: 2rem 4rem 3rem;
  flex-direction: column;
}

.modalHeaderText {
  margin-top: 1rem;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
}

.reportDetailsGroup {
  /* -ms-flex: 0 0 100%; */
  /* flex: 0 0 100%; */
  /* max-width: 100%; */
  /* display: flex; */
  /* flex-direction: column; */
  padding-right: 20px;
}
 
.reportHeader {
  font-size: 30px;
  line-height: 36px;
  font-weight: normal;
  margin-bottom: 1.5rem;
}

.reportSubDetailsGroup {
  display: flex;
}

.subDetailsTitle,
.subDetailsData {
  display: flex;
  font-size: 20px;
  line-height: 28px;
  flex-direction: column;
}

.subDetailsTitle text:not(:last-child),
.subDetailsData text:not(:last-child) {
  margin-bottom: 8px;
}

.subDetailsTitle {
  margin-right: 3rem;
}

.subDetailsTitle text:nth-child(n) {
  font-weight: 500;
}

.subDetailsData text:nth-child(n) {
  font-weight: normal;
}

.reportActionsGroup {
  /* -ms-flex: 0 0 100%; */
  /* flex: 0 0 100%; */
  /* max-width: 290px; */
  /* margin-top: 2rem; */
  /* display: flex; */
  /* flex-direction: column; */
}

.reportActionsRow {
  display: flex;
  /* justify-content: space-between; */
  /* justify-content: flex-start; */
}

.reportButtonGroup {
  text-align: center;
  /* display: flex; */
  /* flex-direction: column; */
}

.reportButtonGroup:first-child {
  margin-right: 3rem;
}

.reportTasksButton,
.reportEditButton,
.reportRemoveButton {
  font-size: 33px !important;
  line-height: 38px !important;
  padding: 1.5rem !important;
  max-width: 80px;
  text-align: center !important;
}

.reportTasksButton {
  margin-right: 0 !important;
  color: #8bc334 !important;
  background-color: #f1f8e7 !important;
}

.reportTasksButton:hover {
  color: #ffffff !important;
  background-color: #8bc334 !important;
}

.reportEditButton {
  margin-right: 0 !important;
  color: #388cef !important;
  background-color: #e7f1fd !important;
}

.reportEditButton:hover {
  color: #ffffff !important;
  background-color: #388cef !important;
}

.reportRemoveButton {
  margin-right: 0 !important;
  color: #f56666 !important;
  background-color: #feeded !important;
}

.reportRemoveButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.reportButtonText {
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  display: block;
}

.somethingButton {
  margin-top: 2rem;
  padding: 1.3rem 0rem;
  border: none;
  cursor: pointer;
  color: black;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  background-color: rgb(228 228 228 / 0.5);
  outline: none;
}

.somethingButton:hover {
  background-color: #e5e5e5;
}

.progressBarContainer {
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  max-width: 100%;
  box-shadow: none;
  background-color: #388cef;
  position: relative;
}

.progressBar {
  min-width: 0;
  height: 1.75em;
  display: block;
  line-height: 1;
  position: relative;
}

.progressBarText {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 992px) {
  /* .reportDetailsGroup { */
    /* -ms-flex: 0 0 55%; */
    /* flex: 0 0 55%; */
    /* max-width: 55%; */
    /* margin-right: 5%; */
  /* } */
  /* .reportActionsGroup { */
    /* -ms-flex: 0 0 40%; */
    /* flex: 0 0 40%; */
    /* max-width: 40%; */
    /* margin-top: 0; */
  /* } */
  /* .reportActionsRow { */
    /* justify-content: flex-end; */
  /* } */
}

@media screen and (min-width: 1024px) {

	.reportRecordContainer {
	  padding: 4rem;
	}	
	
}

@media screen and (min-width: 1100px) {
  /* .reportDetailsGroup { */
    /* -ms-flex: 0 0 60%; */
    /* flex: 0 0 60%; */
    /* max-width: 60%; */
  /* } */
  /* .reportActionsGroup { */
    /* -ms-flex: 0 0 35%; */
    /* flex: 0 0 35%; */
    /* max-width: 35%; */
    /* margin-top: 0; */
  /* } */
}

@media screen and (min-width: 1200px) {
  /* .reportDetailsGroup { */
    /* -ms-flex: 0 0 65%; */
    /* flex: 0 0 65%; */
    /* max-width: 65%; */
  /* } */
  /* .reportActionsGroup { */
    /* -ms-flex: 0 0 30%; */
    /* flex: 0 0 30%; */
    /* max-width: 30%; */
  /* } */
}
