.modalContainer {
  display: flex;
  padding: 4rem 5rem;
  flex-direction: column;
}

.headerText {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
  margin-bottom: 2rem;
}

.buttonGroup {
  display: flex;
  align-self: center;
}

.nextTipButton {
  border: none;
  margin-right: 1rem;
  border-radius: 5px;
  padding: 1rem 3rem;
  color: #8bc334;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  background-color: #f1f8e7;
  outline: none;
}

.nextTipButton:hover {
  color: #ffffff;
  background-color: #8bc334;
}
