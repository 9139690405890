.modalContainer {
    display: flex;
    flex-direction: column;
}

.closeButton {
    top: 0;
    right: 0;
    position: absolute;
    border-radius: 0 !important;
    margin-right: 0 !important;
    color: #F56666 !important;
    background-color: #feeded !important;
}

.closeButton:hover {
    color: #ffffff !important;
    background-color: #F56666 !important;
}

.modalContent {
    display: flex;
    align-content: center;
    padding: 4rem 6rem;
    flex-direction: column;
}

.spinnerDiv {
    margin-top: 30vh;
    text-align: center;
}

.headerText {
    font-size: 39px;
    line-height: 46px;
    text-align: center;
    font-weight: normal;
    letter-spacing: -1px;
    align-self: center;
    margin-bottom: 2rem;
}

.subText {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    font-weight: normal;
    align-self: center;
    margin-bottom: 1.5rem;
}

.notesBox {
    height: 35vh;
}

.blueButton {
    margin-top: 1rem;
    align-self: center;
}

@media screen and (min-width: 1024px) {
    .modalContent {
        padding: 4rem 10rem;
    }
}

@media screen and (min-width: 1100px) {
    .modalContent {
        padding: 4rem 12.5rem;
    }
}