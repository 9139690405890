.buttonContainer {
  border: none;
  border-radius: 5px;
  padding: 1.3rem 3rem;
  color: #f56666;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  background-color: #feeded;
  outline: none;
}

.buttonContainer:hover {
  color: #ffffff;
  background-color: #f56666;
}

.disabled {
  border: none;
  border-radius: 5px;
  padding: 1.3rem 3rem;
  color: #4b4848;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  background-color: #cacaca;
  outline: none;
  cursor: default !important;
}
