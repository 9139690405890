.modalContainer {
    display: flex;
    padding: 2rem 5rem 4rem 5rem;
    flex-direction: column;
}

.closeButton {
    top: 0;   
    right: 0;
    position: absolute;
    margin-right: 0 !important;
    color: #F56666 !important;
    border-radius: 0!important;
    background-color: #feeded !important;
}

.closeButton:hover {
    color: #ffffff !important;
    background-color: #F56666 !important;
}

.headerText {
    margin: 1rem 0 2rem;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    font-weight: normal;
}

.inputRow {
    display: flex;
    margin-bottom: 1.5rem;
    justify-content: space-between;
}

.inputBox {
    flex-grow: 1;
    padding: 1rem;
}

.saveButton {
    align-self: center;
}

.inputRow > div {
    width: 49%;
}

.inputRow > div:first-child {
    margin-right: 2%;
}