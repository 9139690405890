.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 4rem;
}

.backButtonContainer {
  text-align: right;
}

.backButtonText {
  width: 50px;
  height: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  align-self: flex-end;
  border-radius: 50%;
  background-color: #ffffff;
  outline: none;
}

.backButton {
  top: 13px;
  left: 13px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.backButtonText:hover > .backButton {
  color: #222222 !important;
}

.content {
  display: flex;
  padding: 0 4rem;
  flex-direction: column;
  margin-bottom: 2rem;
}

.headerContainer {
  display: flex;
  margin-bottom: 4rem;
  justify-content: space-between;
}

.headerTextGroup {
}

.headerText {
  font-size: 39px;
  line-height: 46px;
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 1rem;
}

.headerSubText {
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
}

.backToDashboardButton {
  height: 50px;
  padding: 0 3rem;
  border: none;
  cursor: pointer;
  color: #c9c9c9;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: #ffffff;
  outline: none;
}

.backToDashboardButton:hover {
  color: #222222;
}

.feedbackRow {
  display: flex;
  cursor: pointer;
  padding: 1rem 2rem;
  margin-bottom: 4px;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f8f6;
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
  border-radius: 6px;
}

.feedbackRow > div:first-child > text:first-child {
  color: #c9c9c9;
  margin-right: 8px;
}

.activeFeedbackRow {
  background-color: #ffffff;
}

.feedbackButton {
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  text-decoration-line: underline;
}

.activeFeedbackButton {
  color: #8bc334;
}

.feedbackButtonIcon {
  font-size: 12px !important;
  margin-left: 1rem !important;
  margin-right: 0 !important;
}

.activeFeedbackButtonIcon {
  color: #ffffff !important;
  background-color: #8bc334 !important;
}

.cardContainer {
  display: flex;
  margin-top: 1.5rem;
  padding: 3rem 4rem;
  background-color: #ffffff;
}

.cardLeftColumn {
  flex: 0.6;
  display: flex;
  padding-right: 5.5rem;
  flex-direction: column;
  border-right: 1px solid #e7e7e7;
}

.cardHeaderText {
  font-size: 30px;
  line-height: 36px;
  font-weight: normal;
  margin-bottom: 1.5rem;
}

.cardSubText {
  font-size: 15px;
  font-weight: 300;
  line-height: 26px;
  #ffffff-space: pre-line;
  margin-bottom: 3.5rem;
}

.cardRightColumn {
  flex: 0.4;
}

.cardRightColumn > div {
  display: flex;
  padding: 0 2.5rem;
  align-items: flex-start;
  flex-direction: column;
}

.cardRightColumn > div > *:nth-child(6) {
  margin-bottom: 3rem;
}

.stepLine {
  height: 50px;
  margin-left: 2.5rem;
  border: 1px dashed #c4c4c4;
}

.statusInactive,
.statusActive {
  color: #c9c9c9;
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  padding: 0.5rem 1rem;
  background-color: #fafafa;
}

.statusActive {
  color: #ffffff;
  background-color: #8bc334;
}

.reviewDetailHeader {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  margin-bottom: 0.5rem;
}

.reviewDetailSubText {
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  margin-bottom: 1rem;
}

.reportButtonRow {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
}

.prevNextReportButton,
.activePrevNextReportButton {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  border: none;
  cursor: pointer;
  color: #c9c9c9;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: #ffffff;
  outline: none;
}

.prevNextReportButton > *:first-child,
.activePrevNextReportButton > *:first-child {
  margin-right: 1rem;
}

.prevNextReportButton:hover {
  color: #222222;
}

.activePrevNextReportButton {
  color: black;
}

.feedback h1 {
  font-size: 32px !important;
}

@media screen and (max-width: 820px) {
  .headerTextGroup {
    max-width: 380px;
  }
}

@media screen and (min-width: 1100px) {
  .content {
    padding: 0 8rem;
  }
}
