.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
}

.backButtonContainer {
  text-align: right;
}

.backButtonText {
  width: 50px;
  height: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  align-self: flex-end;
  border-radius: 50%;
  background-color: #ffffff;
  outline: none;
}

.backButton {
  top: 13px;
  left: 13px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.backButtonText:hover > .backButton {
  color: #222222 !important;
}

.spinnerDiv {
  margin-top: 30vh;
  text-align: center;
}

.content {
  display: flex;
  padding: 0 4rem;
  flex-direction: column;
  margin-bottom: 5rem;
}

.headerContainer {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
}

.headerContainer > div {
  display: flex;
}

.nqaPicker {
  display: flex;
  padding: 0.5rem;
  position: relative;
  align-items: center;
  margin-right: 1.5rem;
  flex-direction: column;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.11);
}

.nqa1Circle,
.nqa2Circle,
.nqa3Circle,
.nqa4Circle,
.nqa5Circle,
.nqa6Circle,
.nqa7Circle {
  width: 80px;
  height: 80px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nqa1Circle {
  background-color: #61a447;
}

.nqa2Circle {
  background-color: #fbd539;
}

.nqa3Circle {
  background-color: #e395a5;
}

.nqa4Circle {
  background-color: #accf6b;
}

.nqa5Circle {
  background-color: #f3a23e;
}

.nqa6Circle {
  background-color: #db308f;
}

.nqa7Circle {
  background-color: #3a96cf;
}

.nqaPickerText {
  margin-left: 8px;
  font-size: 11px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
}

.nqaPickerText > *:nth-child(2) {
  padding: 0 !important;
  font-size: 10px !important;
  line-height: 8px !important;
  margin-right: 0 !important;
}

.nqaSelectionRow {
  top: 0;
  left: 0;
  height: 90px;
  display: flex;
  padding: 0.5rem;
  position: absolute;
  border-radius: 5px;
  background-color: #ffffff;
}

.nqaSelectionRow > div {
  margin-right: 4px;
}

.headerTextGroup {
  display: flex;
  flex-direction: column;
}

.headerText {
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -1px;
  margin-bottom: 15px;
}

.headerSubText {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.backToDashboardButton {
  font-size: 16px;
  width: 260px;
  height: 50px;
  /* padding: 0 3rem; */
  border: none;
  cursor: pointer;
  color: #c9c9c9;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: #ffffff;
  outline: none;
  margin-left: 2rem;
}

.backToDashboardButton:hover {
  color: #222222;
}

.nqaLevelSelectionRow {
  display: flex;
}

.nqaLevelSelectionRow > div:not(:last-child) {
  margin-right: 4px;
}

.nqaLevelCardInvisible {
  visibility: hidden;
}

.nqaLevelCard,
.nqaLevelCardSelected,
.nqaLevelCardInvisible {
  flex: 0.33;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  color: #c9c9c9;
  background-color: #f7f8f6;
  max-width: 33%;
  -ms-flex: 0 0 33%;
}

.nqaLevelCard:hover {
  color: #222222;
  background-color: #ffffff;
}

.nqaLevelCardSelected {
  color: #222222;
  position: relative;
  background-color: #ffffff;
}

.nqaLevelCardSelected::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #ffffff;
  border-bottom: 0;
  margin-left: -20px;
  margin-bottom: -20px;
}

.nqaLevelCardInvisible {
  visibility: hidden;
}

.nqaLevelCard,
.nqaLevelCardSelected,
.nqaLevelCardInvisible {
  padding: 2rem 4rem;
}

.nqaLevelCardHeader {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 20px;
}

.progressBarContainer {
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  max-width: 100%;
  box-shadow: none;
  position: relative;
  background-color: #e7e7e7;
  margin-bottom: 10px;
}

.progressBar {
  height: 1rem;
  display: block;
  line-height: 1;
  position: relative;
  background-color: #61a447;
}

.progressBarText {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.nqaLevelInfoRow {
  display: flex;
  margin: 3rem 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  align-items: center;
}

.nqaLevelInfoIcon {
  width: 60px !important;
  height: 60px !important;
  padding: 0 !important;
  font-size: 30px !important;
  pointer-events: none !important;
  background-color: #ffffff !important;
}

.nqa1Info {
  color: #61a447 !important;
}

.nqa2Info {
  color: #fbd539 !important;
}

.nqa3Info {
  color: #e395a5 !important;
}

.nqa4Info {
  color: #accf6b !important;
}

.nqa5Info {
  color: #f3a23e !important;
}

.nqa6Info {
  color: #db308f !important;
}

.nqa7Info {
  color: #3a96cf !important;
}

.nqaSubLevelSelectionRow {
  display: flex;
}

.nqaSubLevelSelectionRow > div:nth-child(1) {
  margin-right: 4px;
}

.nqaSubLevelSelectionRow > div:nth-child(3) {
  margin-left: 4px;
}

.nqaSubLevelCard,
.nqaSubLevelCardSelected {
  flex: 0.3333;
  display: flex;
  cursor: pointer;
  padding: 3rem 4rem;
  margin-bottom: 4px;
  align-items: center;
  justify-content: center;
  color: #c9c9c9;
  background-color: #f7f8f6;
}

.nqaSubLevelCard:hover {
  color: #222222;
  background-color: #ffffff;
}

.nqaSubLevelCardSelected {
  color: #222222;
  margin-bottom: 0;
  background-color: #ffffff;
}

.nqaSubLevelCardText {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
}

.nqaSubLevelInfoCard {
  display: flex;
  padding: 4rem 4rem 8rem;
  background-color: #ffffff;
}

.nqaSubLevelInfoCardLeftColumn {
  flex: 0.65;
  display: flex;
  padding-right: 4rem;
  flex-direction: column;
  border-right: 1px solid #e7e7e7;
}

.nqaSubLevelInfoCardHeader {
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 1.5rem;
}

.nqaSubLevelInfoCardSubText {
  font-size: 15px;
  font-weight: 300;
  line-height: 26px;
  margin-bottom: 1.5rem;
}

.nqaSubLevelInfoCardRightColumn {
  flex: 0.35;
}

.nqaSubLevelInfoCardRightColumn > div {
  display: flex;
  padding-left: 2rem;
  flex-direction: column;
}

.nqaSubLevelInfoCardRightColumn > div > text:last-child {
  align-self: center;
}

.nqaSubLevelCategoriesRow {
  display: flex;
}

.nqaSubLevelCategoryCard,
.nqaSubLevelCategoryCardSelected {
  flex: 0.3333;
  display: flex;
  cursor: pointer;
  padding: 4rem 2rem;
  align-items: center;
  justify-content: center;
  margin: -4rem 10px 6rem;
  border-radius: 5px;
  color: #0c0404;
  background-color: #f7f8f6;
  -ms-flex: 0 0 3333%;
}

.nqaSubLevelCategoryCardSelected {
  color: #ffffff;
  position: relative;
}

/* .nqa1Info { */
/* background-color: #dfedda;  */
/* } */
.nqa1Info .nqaSubLevelCategoryText {
  color: #61a447 !important;
}

/* .nqa2Info { */
/* background-color: #fef7d7;  */
/* } */
.nqa2Info .nqaSubLevelCategoryText {
  color: #fbd539 !important;
}

/* .nqa3Info { */
/* background-color: #E395A533;  */
/* } */
.nqa3Info .nqaSubLevelCategoryText {
  color: #e395a5 !important;
}

/* .nqa4Info { */
/* background-color: #eef5e1;  */
/* } */
.nqa4Info .nqaSubLevelCategoryText {
  color: #accf6b !important;
}

/* .nqa5Info { */
/* background-color: #fdecd8;  */
/* } */
.nqa5Info .nqaSubLevelCategoryText {
  color: #f3a23e !important;
}

/* .nqa6Info { */
/* background-color: #DB308F33;  */
/* } */
.nqa6Info .nqaSubLevelCategoryText {
  color: #db308f !important;
}

/* .nqa7Info { */
/* background-color: #d8eaf5;  */
/* } */
.nqa7Info .nqaSubLevelCategoryText {
  color: #3a96cf !important;
}

.nqaSubLevelCategoryCardSelectedArrow {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom: 0;
  margin-left: -20px;
  margin-bottom: -20px;
}

.nqaSubLevelCategoryCard .nqaSubLevelCategoryText {
  color: #c9c9c9;
}

.nqaSubLevelCategoryText {
  font-weight: 500;
  font-size: 20px;
  line-height: 2rem;
  text-align: center;
  color: #ffffff;
}

.questionFilterText text {
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  color: #272828;
}

.questionFilterText {
  max-width: 550px;
  margin-right: auto;
  margin-left: auto;
}

.questionFilterRow > text:not(:last-child) {
  margin-right: 4px;
}

.questionFilterRow > text:last-child {
  width: 477px;
}

.questionFilterRow > text:last-child:hover {
  color: #222222;
}

.questionFilterRow > text {
  margin-bottom: 4px;
}

.customFilterContainer {
  background-color: #f7f8f6;
}

.customFilterContainer button {
  color: #b7b8b7;
  background: #ffffff;
  border-radius: 27px;
  padding: 15px 25px;
}

.customFilterContainer button:hover {
  color: #222222;
}

.customFilterContainer .btnGroup button {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.customFilterContainer .customPostedBy {
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
}

.addCustomGoalsEvidences,
.questionFilter,
.questionFilterSelected {
  font-weight: 500;
  font-size: 15px;
  /* font-size: 20px; */
  line-height: 26px;
  cursor: pointer;
  text-align: center;
  color: #b7b8b7;
  background-color: #f7f8f6;
  border-radius: 5px;
}

.questionFilter,
.questionFilterSelected {
  padding: 1rem 3rem;
}

.questionFilter:hover {
  color: #61a447;
}

.addCustomGoalsEvidences {
  font-size: 18px;
  line-height: 21px;
}

.addCustomGoalsEvidences:hover {
  color: #222222;
}

.customHeaderText {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #272828;
}

.questionFilterSelected {
  color: #ffffff;
}

.nqaQuestions {
  display: flex;
  flex-direction: column;
}

.nqaQuestionCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.nqaQuestionCard:not(:last-child) {
  margin-bottom: 20px;
}

.nqaQuestionCardMain {
  display: flex;
  flex-direction: column;
  padding: 4.706vh 4.118vw 2.353vh 5.294vw;
}

.nqaQuestionCardRow {
  display: flex;
  justify-content: space-between;
}

.nqaQuestionLeftColumn {
  flex: 0.6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.evidenceStrategySetText {
  display: flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #8bc334;
  padding: 1rem 1.5rem;
  background-color: #f8fbf3;
  margin-top: 0.8rem;
}

.unsetHelperText {
  display: flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #f56666;
  padding: 1rem 1.5rem;
  background-color: #feeded;
  margin-top: 0.8rem;
}

.noNoteText,
.noteCreatedText {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #222222;
  padding: 1rem 1.5rem;
  background-color: #f2f2f2;
  margin-top: 0.8rem;
}

.noteCreatedText {
  color: #388cef;
  background-color: #f3f8fe;
}

.nqaQuestionRightColumn {
  flex: 0.38;
  display: flex;
  flex-direction: column;
}

.nqaQuestionHeader {
  line-height: 2.647vh;
  font-size: 2.206vw;
  font-weight: normal;
  margin-bottom: 1rem;
  display: none;
}

.nqaQuestionDescription {
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
}

.nqaQuestionButtonRow {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: space-between;
}

.nqaCustomQuestionButtonRow {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: flex-end;
}

.nqaQuestionButtonGroup {
  display: flex;
  flex-direction: column;
}

.nqaQuestionButtonGroup:nth-child(1) button:hover {
  color: #8bc334 !important;
}

.nqaQuestionButtonGroup:nth-child(2) button:hover {
  color: #ffb800 !important;
}

.nqaQuestionButtonGroup:nth-child(3) button:hover {
  color: #388cef !important;
}

.nqaQuestionButtonGroup:nth-child(2) {
  margin: 0 10px;
}

.yesButton,
.noButton,
.notApplicableButton,
.questionButton {
  font-weight: normal;
  font-size: 50px !important;
  line-height: 57px !important;
  text-align: center !important;
  margin-right: 0 !important;
}

.yesButton,
.noButton,
.notApplicableButton {
  padding: 10px 9px !important;
}

.questionButton {
  color: #e4e4e4 !important;
  background-color: #ffffff !important;
  border: 3px solid #e4e4e4 !important;
  padding: 10px 9px !important;
}

.questionButtonText {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-top: 1rem;
}

.yesButton {
  color: #8bc334 !important;
  border: 3px solid #f1f8e7 !important;
  background-color: #f1f8e7 !important;
}

.noButton {
  color: #ffb800 !important;
  border: 3px solid #fff6e0 !important;
  background-color: #fff6e0 !important;
}

.notApplicableButton {
  color: #388cef !important;
  border: 3px solid #e7f1fd !important;
  background-color: #e7f1fd !important;
}

.editEvidenceButton,
.provideEvidenceButton,
.addStrategyButton,
.createNoteButton,
.viewTasksButton {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  outline: none;
  padding: 20px;
}

.provideEvidenceButton:hover,
.viewTasksButton:hover {
  background-color: #e5e5e5;
}

.editEvidenceButton {
  background-color: #8bc334;
}

.editEvidenceButton:hover {
  background-color: #97d439;
}

.provideEvidenceButton {
  color: #222222;
  background-color: rgba(228, 228, 228, 0.5);
}

.addStrategyButton {
  background-color: #ffb800;
}

.addStrategyButton:hover {
  background-color: #ffd600;
}

.createNoteButton {
  background-color: #388cef;
}

.createNoteButton:hover {
  background-color: #54a3ff;
}

.nqaQuestionCardTaskRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-top: 1px solid #e4e4e4; */
  /* padding: 4.706vh 4.118vw 2.353vh 5.294vw; */
}

.nqaQuestionCardTaskRow > *:first-child {
  flex: 0.6;
  display: flex;
  align-items: center;
}

.nqaQuestionCardTaskRow > *:last-child {
  flex: 0.4;
}

.nqaTaskCount {
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  padding-right: 1.5rem;
}

.nqaTaskText {
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
}

.viewTasksButton {
  width: 100%;
  color: #222222;
  background-color: rgba(228, 228, 228, 0.5);
}

.viewTasksButton > *:first-child {
  font-size: 18px !important;
  margin-bottom: 4px !important;
}

@media screen and (min-width: 992px) {
  .headerText {
    font-size: 39px;
    line-height: 46px;
  }

  .nqaQuestionRightColumn {
    flex: 0.35;
  }

  .nqaQuestionCardTaskRow > *:first-child {
    flex: 0.65;
  }

  .nqaQuestionCardTaskRow > *:last-child {
    flex: 0.35;
  }
}

@media screen and (min-width: 1024px) {
  .backToDashboardButton {
    font-size: 20px;
  }

  .nqaLevelCardHeader {
    font-size: 30px;
    line-height: 36px;
  }
}

@media screen and (min-width: 1100px) {
  .content {
    padding: 0 8rem;
  }
}

@media screen and (max-width: 992px) {
  .mainContainer {
    margin-bottom: 5rem;
  }
}

.archiveText {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  color: #f56666;
}

.archiveInfo {
  width: 36px;
  height: 32px;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 32px;
  color: #f56666;
  opacity: 0.3;
}

.archiveInfoGroup {
  margin-top: 70px;
}

.toolTipCard {
  border-radius: 3px;
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
}

.archiveInfoGroup .ant-tooltip-content {
  width: 390px !important;
}

.satQuestion {
  height: 55px;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
}

.satLefttInput {
  width: 35px;
  height: 35px;
  font-weight: 900;
  font-size: 15px;
  line-height: 32px;
  /* or 213% */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #8bc334;
  border-radius: 3px;
}

.satLeftText {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;

  color: #272828;
}

.infoSat {
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 32px;
  /* identical to box height, or 128% */

  color: #8bc334;
}

.infoSatButton {
  width: 182px;
  height: 35px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;

  color: #388cef;
  background: rgba(56, 140, 239, 0.12);

  border-radius: 2px;
}

.infoSatButton:hover {
  background: #388cef;
  color: #ffffff;
}

.accordanceContainer {
  width: 99.3%;
  /* height: 100px; */
  background: #ffffff;
  border-radius: 5px;
}

.accordanceScaleIcon {
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 40px;

  width: 69px;
  height: 69px;
  background: #f7f8f6;
  border-radius: 9999px;
}

.headerLaw {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
}

.headerLawSub {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  color: #222222;
}

.accordancePbContainer {
  width: 170px;
}

.accordanceProgressText {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  color: #272828;
}

.accordanceProgressBar {
  height: 12px;
}

/* .accordanceProgressComplete {
  background: #61a447;
} */

.accordanceProgressInComplete {
  background: #e7e7e7;
}

.accordanceFinishButton {
  width: 170px;
  height: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
}

.accordance_nqa1 {
  background: rgba(97, 164, 71, 1);
}

.accordance_nqa2 {
  background: rgba(251, 213, 57, 1);
}

.accordance_nqa3 {
  background: rgba(227, 149, 165, 1);
}

.accordance_nqa4 {
  background: rgba(172, 207, 107, 1);
}

.accordance_nqa5 {
  background: rgba(243, 162, 62, 1);
}

.accordance_nqa6 {
  background: rgba(219, 48, 143, 1);
}

.accordance_nqa7 {
  background: rgba(58, 150, 207, 1);
}

.accordanceFinishButton.accordance_nqa1:hover {
  background: rgba(97, 164, 71, 0.9);
}

.accordanceFinishButton.accordance_nqa2:hover {
  background: rgba(251, 213, 57, 0.9);
}

.accordanceFinishButton.accordance_nqa3:hover {
  background: rgba(227, 149, 165, 0.9);
}

.accordanceFinishButton.accordance_nqa4:hover {
  background: rgba(172, 207, 107, 0.9);
}

.accordanceFinishButton.accordance_nqa5:hover {
  background: rgba(243, 162, 62, 0.9);
}

.accordanceFinishButton.accordance_nqa6:hover {
  background: rgba(219, 48, 143, 0.9);
}

.accordanceFinishButton.accordance_nqa7:hover {
  background: rgba(58, 150, 207, 0.9);
}

.checkedCompliance {
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 18px;
  background: #ffffff;
}

.accordanceScaleIcon_nqa1 {
  color: rgba(97, 164, 71, 1);
}

.accordanceScaleIcon_nqa2 {
  color: rgba(251, 213, 57, 1);
}

.accordanceScaleIcon_nqa3 {
  color: rgba(227, 149, 165, 1);
}

.accordanceScaleIcon_nqa4 {
  color: rgba(172, 207, 107, 1);
}

.accordanceScaleIcon_nqa5 {
  color: rgba(243, 162, 62, 1);
}

.accordanceScaleIcon_nqa6 {
  color: rgba(219, 48, 143, 1);
}

.accordanceScaleIcon_nqa7 {
  color: rgba(58, 150, 207, 1);
}

.satToolTip {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  padding: 1rem;
  margin-bottom: 0.2rem;
  color: #ffffff;
  width: 381px;
  background: #222222;
  border-radius: 3px;
}

.toolTip {
  width: 0;
  height: 0;
  bottom: -10px;
  margin-right: 8px;
  border: 5px solid transparent;
  border-bottom: 0;
  border-top: 10px solid #222222;
}

.satToolTipHeader {
  font-weight: 300;
}

.toolTipHr {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
