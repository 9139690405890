.pageButtonGroup {
  display: flex;
  /* margin-top: 5rem; */
  justify-content: center;
  /* display: none; */
}

.pageButtonStartEnd {
  font-size: 20px !important;
  margin-right: 10px !important;
  background-color: #f7f8f6 !important;
}

.pageButtonStartEnd:hover {
  background-color: #e5e5e5 !important;
}

.pageButtonSelected {
  color: #388cef;
  background: #e7f1fd;
}

.pageButtonStartEnd:nth-last-child(1) {
  margin-right: 0 !important;
}

.pageButtonSelected,
.pageButton {
  padding: 18px 26px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
}

.pageButton {
  color: #222222;
  background: #f7f8f6;
}

.pageButton:hover {
  background: #e5e5e5;
}
