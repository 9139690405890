.mainContainer {
  border-bottom: 1px solid #e4e4e4 !important;
}

.serviceOperatingTimes {
  background: #ffffff;
  /* border-top: 1px solid #e4e4e4; */
  box-sizing: border-box;
}

.headerContainer {
  border: 1px solid #e4e4e4;
}

.headerText {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;

  color: #222222;
}

.toggleIcon {
  width: 55px;
  height: 55px;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 117% */

  display: flex;
  align-items: center;

  /* color: #ffffff; */
  /* background: rgba(139, 195, 52, 1); */
}

/* .toggleIcon:hover {
  background: rgba(139, 195, 52, 0.9);
} */

.tableContainer {
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}

.tableOperatingTimes > thead > tr > th {
  width: 25%;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  /* identical to box height, or 173% */

  color: #c9c9c9;
}

.tableCareOperatingTimes > thead > tr > th {
  width: 25%;
  font-style: normal;
  font-weight: normal;

  /* identical to box height, or 173% */

  color: #c9c9c9;
}

.tableOperatingTimes > tbody > tr > td:first-child,
.tableOperatingTimes > thead > tr > th:first-child {
  width: 30%;
}

.tableOperatingTimes > tbody > tr > td:nth-child(2),
.tableOperatingTimes > thead > tr > th:nth-child(2) {
  width: 28%;
}

.tableOperatingTimes > tbody > tr > td:nth-child(3),
.tableOperatingTimes > thead > tr > th:nth-child(3) {
  width: 28%;
}

.tableOperatingTimes > tbody > tr > td:last-child,
.tableOperatingTimes > thead > tr > th:last-child {
  width: 14%;
}

.tableOperatingTimes > tbody > tr:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}

.tableOperatingTimes > thead > tr {
  border-bottom: 1px solid #e4e4e4;
}

.tableCareOperatingTimes > tbody > tr > td:first-child,
.tableCareOperatingTimes > thead > tr > th:first-child {
  width: 25%;
}

.tableCareOperatingTimes > thead > tr {
  border-bottom: 1px solid #e4e4e4;
}

.tableCareOperatingTimes > tbody > tr:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}

.tableOperatingTimes > thead > tr {
  border-bottom: 1px solid #e4e4e4;
}

.dayName {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;

  color: #272828;
  text-transform: capitalize;
}

.timePicker {
  width: 10rem;
  height: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  /* or 200% */

  display: flex;
  align-items: center;

  color: #272828;
}

.timePicker1 {
  height: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  /* or 200% */

  display: flex;
  align-items: center;

  color: #272828;
}

@media screen and (max-width: 860px) {
  .timePicker1 {
    width: 7.5rem;
  }

  .tableCareOperatingTimes > thead > tr > th {
    font-size: 13px;
    line-height: 24px;
  }
}

@media screen and (min-width: 860px) {
  .timePicker1 {
    width: 9rem;
  }

  .tableCareOperatingTimes > thead > tr > th {
    font-size: 15px;
    line-height: 26px;
  }
}

@media screen and (min-width: 1260px) {
  .timePicker1 {
    width: 11rem;
  }
}

.saveButton {
  width: 243px;
  height: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
  background: rgba(139, 195, 52, 1);
  border-radius: 5px;
}

.saveButton:hover {
  background: rgba(139, 195, 52, 0.9);
}
