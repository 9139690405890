.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10rem;
  margin-top: 6rem;
}

.container {
  position: absolute;
  width: 900px;
  /* height: 597px; */

  background: #ffffff;
  box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
}

.closeButton {
  top: 0;
  right: 0;
  position: absolute;
  border-radius: 0 !important;
  margin-right: 0 !important;
  color: #f56666 !important;
  background-color: #feeded !important;
}

.closeButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.backButtonArrow {
  width: 50px;
  height: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  align-self: flex-end;
  border-radius: 50%;
  background-color: #ffffff;
  outline: none;
}

.backButtonArrow:hover > .backButton {
  color: #222222 !important;
}

.backButton {
  top: 13px;
  left: 13px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.mainContent {
  display: flex;
  align-content: center;
  padding: 4rem 5rem;
  flex-direction: column;
}

.headerText {
  font-weight: normal;
  font-size: 30px;
  line-height: 32px;
  text-align: left;
  letter-spacing: -1px;
  margin-bottom: 0.5rem;
}

.subText {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
}

.inputColumn {
  display: flex;
  margin-top: 1rem;
}

.inputColumn > div:first-child {
  margin-right: 2rem;
}

.inputColumn > div {
  flex: 0.5;
  display: flex;
  flex-direction: column;
}
.inputTextAreaColumn div {
  margin-top: 2rem;
}

.inputTextAreaColumn {
  width: 100%;
}

.inputComments {
  resize: none;
  padding: 1rem;
  font-size: 20px;
  border-radius: 3px;
  margin-top: 0.5rem;
  border: 1px solid black;
  height: 150px;
}

.dropdownGroup {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

.required {
  color: #f56666;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}

.dropdownBox {
  height: 60px;
  padding: 1.25rem 1rem !important;
  font-size: 20px !important;
  margin-top: 0.5rem !important;
  border-radius: 5px !important;
  border: 1px solid black !important;
  max-width: unset !important;
}

.inputColumn .dropdownGroup div {
  margin-top: unset;
}

.dropdownText {
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
}

.blueButton button {
  margin-top: 40px;
}

.blueButton button {
  color: #ffffff;
  background-color: #388cef;
  padding: 1.3rem 5rem;
}

.blueButton button:hover {
  color: #388cef;
  background-color: #e7f1fd;
}

.backButtonContainer {
  text-align: right;
  display: none;
}

@media screen and (min-width: 1024px) {
  .required {
    font-size: 20px;
  }
}

@media screen and (min-width: 768px) {
  .backButtonContainer {
    display: block;
  }
}
