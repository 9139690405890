.createReportContainer {
    background: #ffffff;
}

.descriptionText {
    font-size: 20px;
    line-height: 32px;
    font-weight: normal;
}

.reportTypeRow {
    padding: 4rem 0 2.5rem;
    border-bottom: 1px solid #E7E7E7;
    margin-bottom: 2.5rem;
}

.reportTypeRow .reportTypeColumn {
	width: 25%;
}

.reportTypeSelected,
.reportType{
    font-size: 50px !important;
    line-height: 57px !important;   
    padding: 12px 15px !important;
    margin-right: 0!important;
    border-radius: 5px !important;
}

.reportType {
    color: #E4E4E4 !important;
    background-color: #ffffff !important;
    border: 3px solid #E4E4E4 !important;
    
}

.reportType:hover {
	color: #8BC334 !important;
}

.reportTypeSelected {
    color: #8BC334 !important;
    border: 3px solid #f1f8e7 !important;
    background-clip: padding-box;
    background-color: #f1f8e7 !important;
}

.reportTypeText {
    margin-top: 1rem;
    font-size: 16px;
    line-height: 31px;
    font-weight: normal;
}

.reportTypeSubText {
    margin-top: 0.5rem;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
}

.nqaReportFilterHeader {
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
    margin-bottom: 2rem;
}

.nqaReportFilterRow {
    /* display: flex; */
    /* flex-wrap: wrap; */
    margin-bottom: 4.5rem;
    /* justify-content: flex-start; */
}

.nqaReportFilterRow button {
    width: 13%;
    height: 80px;
}

/* .nqaReportFilterRow button:not(:nth-last-child(-n+3)) { */
    /* margin-bottom: 1rem; */
/* } */

/* .nqaReportFilterRow button:not(:nth-child(4)) { */
    /* margin-right: 2.6%; */
/* } */

.nqaReportFilterSelected,
.nqaReportFilter {
    border-radius: 5px;
    cursor: pointer;
    /* padding: 19px 15px; */
    font-size: 20px;
    line-height: 36px; 
    text-align: center;
    font-weight: normal;
    outline: none; 
}

.nqaReportFilter {
    color: #E4E4E4;
    background-color: #ffffff;
    border: 3px solid #E4E4E4;
}

.nqaReportFilterSelected {
    border: none;
}

.nqaReportFilterSelected:nth-child(1) {
    color: #61A447;
    background-color: #61A44733;
    background-clip: padding-box;
    border: 3px solid #61A44733;
}

.nqaReportFilter:nth-child(1):hover {
	color: #61A447;
}

.nqaReportFilterSelected:nth-child(2) {
    color: #FBD539;
    background-color: #FBD53933;
    background-clip: padding-box;
    border: 3px solid #FBD53933;
}

.nqaReportFilter:nth-child(2):hover {
	color: #FBD539;
}

.nqaReportFilterSelected:nth-child(3) {
    color: #E395A5;
    background-color: #E395A533;
    background-clip: padding-box;
    border: 3px solid #E395A533;
}

.nqaReportFilter:nth-child(3):hover {
	color: #E395A5;
}

.nqaReportFilterSelected:nth-child(4) {
    color: #ACCF6B;
    background-color: #ACCF6B33;
    background-clip: padding-box;
    border: 3px solid #ACCF6B33;
}

.nqaReportFilter:nth-child(4):hover {
	color: #ACCF6B;
}

.nqaReportFilterSelected:nth-child(5) {
    color: #F3A23E;
    background-color: #F3A23E33;
    background-clip: padding-box;
    border: 3px solid #F3A23E33;
}

.nqaReportFilter:nth-child(5):hover {
	color: #F3A23E;
}

.nqaReportFilterSelected:nth-child(6) {
    color: #DB308F;
    background-color: #DB308F33;
    background-clip: padding-box;
    border: 3px solid #DB308F33;
}

.nqaReportFilter:nth-child(6):hover {
	color: #DB308F;
}

.nqaReportFilterSelected:nth-child(7) {
    color: #3A96CF;
    background-color: #3A96CF33;
    background-clip: padding-box;
    border: 3px solid #3A96CF33;
}

.nqaReportFilter:nth-child(7):hover {
	color: #3A96CF;
}

.reportName > div {
    margin-top: 0;
    margin-bottom: 1.5rem;
}

@media screen and (min-width: 992px) {

	.nqaReportFilterSelected,
	.nqaReportFilter {
		font-size: 25px;
	}
	
	.reportTypeText {
        font-size: 18px;
    }

}

@media screen and (min-width: 1024px) {
  
    .reportTypeText {
        font-size: 22px;
    }
	
	.nqaReportFilterSelected,
	.nqaReportFilter {
		font-size: 28px;
	}
  
}

@media screen and (min-width: 1100px) {
  
	.nqaReportFilterSelected,
	.nqaReportFilter {
		font-size: 30px;
	}
  
    .reportTypeText {
        font-size: 26px;
    }
  
}

@media screen and (min-width: 1280px) {

    /* .nqaReportFilterRow { */
        /* justify-content: space-between; */
    /* } */
    
    /* .nqaReportFilterRow button { */
        /* width: unset; */
        /* height: unset; */
    /* } */

    /* .nqaReportFilterRow button:not(:nth-last-child(-n+3)) { */
        /* margin-bottom: unset; */
    /* } */

    /* .nqaReportFilterRow button:not(:nth-child(4)) { */
        /* margin-right: unset; */
    /* } */

}