.modalContainer {
    display: flex;
    padding: 2rem 4rem 4rem;
    flex-direction: column;
}

.headerText {
    margin: 2rem;
    font-size: 20px;
    line-height: 28px;
    text-align: center;  
    font-weight: normal;
}

.blueButton {
    align-self: center;
}