.modalContainer {
    display: flex;
    padding: 4rem 5rem;
    flex-direction: column;
}

.closeButton {
    top: 0;
    right: 0;  
    position: absolute;
    margin-right: 0 !important;
    color: #F56666 !important;
    border-radius: none !important;
    background-color: #feeded !important;
}

.closeButton:hover {
    color: #ffffff !important;	
    background-color: #F56666 !important;	
}

.cancelButton,
.cancelButtonSelected {
    font-weight: normal !important;    
    font-size: 25px !important;
    line-height: 29px !important;
    text-align: center !important;
    border-radius: 5px !important;    
    margin-right: 1rem !important;
}

.cancelButtonSelected {
    color: #F56666 !important;
    padding: 12px 15px !important;
    background: rgba(245, 102, 102, 0.12) !important;    
}

.cancelButton {
    color: #E4E4E4 !important;
    padding: 9px 12px !important;
    border: 3px solid #E4E4E4 !important;  
    background-color: unset !important;  
}

.cancelButton:hover {
    color: #F56666 !important;    
}

.headerText {
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    font-weight: normal;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 4.2rem;
}

.subscriptionLists {
    display: flex;
    flex-wrap: wrap;    
}

.subscription {
    display: flex;
    align-items: center;
    justify-content: space-between;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;  
    padding: 10px 0;
}

.subscription {
    border-top: 1px solid #E7E7E7;    
}

.subscription:last-child {
    border-bottom: 1px solid #E7E7E7;   
}

.cancelText {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #222222;
}

.subscriptionName {
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #272828;  
}

.buttonContainer {
    text-align: center;
    margin-top: 20px;
}