.buttonContainer {
  color: #388cef;
  background-color: #e7f1fd;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border: none;
  border-radius: 5px;
  padding: 1.3rem 3rem;
  cursor: pointer;
  outline: none;
}

.buttonContainer:hover {
  color: #ffffff;
  background-color: #388cef;
}

/* .buttonContainer svg {
  fill: #388cef;
}

.buttonContainer svg:hover {
  fill: white;
} */
