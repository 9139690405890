.mainContainer {
  display: flex;

  /* padding: 4rem 2.5rem; */
  /* margin-top: 4rem; */
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 4rem;
  margin-bottom: 2.5rem;
  flex-direction: column;
  background-color: white;
}

.searchFilterRow {
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem;
  background-color: white;
  margin-bottom: 1rem;
}

.searchInput {
  font-weight: normal !important;
  font-size: 26px !important;
  line-height: 36px;
  color: #bebebe !important;
  height: 80px;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 2rem;
}

.searchInput input {
  border: 1px solid #bebebe !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
}

.searchInput input:focus {
  color: #bebebe !important;
}

.searchInput::placeholder {
  color: #bebebe !important;
}

.searchFilterRow .filterDropdown {
  align-self: center;
}

.filterDropdown:not(:last-child) {
  margin-right: 2rem;
}

.filterDropdown > div,
.filterDropdown > i {
  color: #bebebe !important;
  font-size: 26px !important;
  line-height: 36px !important;
  font-weight: normal !important;
}

.messageContent {
  /* border-bottom: 1px solid #e7e7e7; */
  padding-bottom: 2.2rem;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.messageRow {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 1.2rem;
  margin-right: 1.5rem;

  /* margin-right: 6rem; */
  margin-left: 1.5rem;
}

.messageRow:not(:last-child) {
  margin-bottom: 1.2rem;
}

.messageRow:last-child {
  margin-bottom: 0;
}

.messageDetailsColumn {
  display: flex;
  align-items: center;
  -ms-flex: 0 0 90%;
  flex: 0 0 90%;
  max-width: 90%;
  margin-bottom: 2rem;
}

.iconHeaderGroup {
  display: flex;
  margin-right: 1rem;
}

.iconHeaderGroup *:nth-child(2) {
  display: flex;
  flex-direction: column;
}

.iconHeaderAlert {
  color: #f56666 !important;
  background-color: #feeded !important;
  font-weight: normal !important;
  font-size: 25px !important;
  line-height: 32px !important;
  pointer-events: none;
}

.newBadgeAlert {
  width: 100%;
  padding: 0.5rem 0.7rem;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  text-align: center;
  color: #f56666;
  background-color: #feeded;
}

.iconHeaderMessage {
  color: #388cef !important;
  background-color: #e7f1fd !important;
  font-weight: normal !important;
  font-size: 25px !important;
  line-height: 32px !important;
  pointer-events: none;
}

.newBadgeMessage {
  width: 100%;
  padding: 0.5rem 0.7rem;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  text-align: center;
  color: #8bc334;
  background-color: #f1f8e7;
}

.iconHeaderMessageRead {
  font-weight: normal !important;
  font-size: 25px !important;
  line-height: 32px !important;
  color: #c4c4c4 !important;
  background-color: #f7f8f6 !important;
}

.starred {
  width: 100%;
  pointer-events: none;
  padding-left: 20px !important;
  font-size: 16px !important;
  color: #ffb800 !important;
  background-color: #fff6e0 !important;
}

.messageTextGroup {
  display: flex;
  flex-direction: column;
}

.messageSubjectText {
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #388cef;
  margin-bottom: 0.8rem;
  text-decoration: underline;
}

.messageSubText {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}

.messageSubText text:nth-child(1) {
  margin-right: 4px;
  font-weight: normal;
}

.actionButtonGroup {
  display: flex;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.actionButtonGroup button:last-child {
  margin-right: 0;
}

.attachmentButton {
  font-weight: normal !important;
  font-size: 30px !important;
  line-height: 32px !important;
  color: #c4c4c4 !important;
  background-color: #f7f8f6 !important;
}

.attachmentButton:hover {
  background-color: #e5e5e5 !important;
}

.deleteButton {
  font-weight: normal !important;
  height: 60px;
  width: 60px;
  padding: unset !important;
  font-size: 30px !important;
  line-height: 32px !important;
  color: #f56666 !important;
  background-color: #feeded !important;
}

.deleteButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.pageButtonGroup {
  display: flex;
  margin-top: 4rem;
  justify-content: center;
}

.pageButtonStartEnd {
  font-size: 20px !important;
  margin-right: 10px !important;
  background-color: #f7f8f6 !important;
}

.pageButtonStartEnd:nth-last-child(1) {
  margin-right: 0 !important;
}

.pageButton,
.pageButtonSelected {
  padding: 18px 26px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  outline: none;
  margin-right: 10px;
}

.pageButton {
  color: #222222;
  background: #f7f8f6;
}

.pageButton:hover {
  background: #e5e5e5;
}

.pageButtonSelected {
  color: #388cef;
  background: #e7f1fd;
}

@media screen and (min-width: 1024px) {
  .messageDetailsColumn {
    -ms-flex: 0 0 72%;
    flex: 0 0 72%;
    max-width: 72%;
  }
  /* .messageRow {
    margin-right: 14rem;
  } */

  .messageDetailsColumn {
    margin-bottom: 0;
  }

  .actionButtonGroup {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 173px;
    justify-content: flex-end;
  }

  /* .searchInput {
    -ms-flex: 0 0 36%;
    flex: 0 0 36%;
    max-width: 300px;
    margin-bottom: 0;
    margin-right: 3rem;
  } */
}

@media screen and (min-width: 1100px) {
  .filterDropdown:not(:last-child) {
    margin-right: 3rem;
  }
}

@media screen and (min-width: 1100px) {
  .filterDropdown > div,
  .filterDropdown > i {
    font-size: 30px !important;
  }

  .searchInput {
    font-size: 30px !important;
  }
}

.messagesInfoRow {
  padding-left: 1.5rem;

  height: 127px;
  width: 100%;
}

.inboxHeader {
  font-style: normal;
  font-size: 30px;
  font-weight: normal;
  line-height: 36px;
  color: #272828;
}

.inboxHeaderCount {
  font-style: normal;
  font-size: 30px;
  font-weight: normal;
  line-height: 36px;
  color: #272828;
}

.unreadHeaderCount {
  font-style: normal;
  font-size: 30px;
  font-weight: normal;
  line-height: 36px;
  color: #272828;
}

.unreadHeader {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #272828;
}

.messagesInfo {
  /* margin-right: 50px; */
}
