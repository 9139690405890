.modalContainer {
  display: flex;
  padding: 2rem 4rem 4rem;
  flex-direction: column;
}

.closeButton {
  top: 0;
  right: 0;
  position: absolute;
  margin-right: 0 !important;
  color: #f56666 !important;
  background-color: #feeded !important;
  border-radius: 0 !important;
}

.closeButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.selectedServiceGroup {
  display: flex;
  align-items: center;
}

.headerText {
  margin-top: 1rem;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
  margin-bottom: 3rem;
}

.serviceSelectedButtonContainer {
  border: none;
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 2rem 2rem;
  color: white;
  cursor: pointer;
  font-size: 20px !important;
  line-height: 24px !important;
  text-align: left;
  font-weight: 500 !important;
  background-color: #8bc334;
}

.serviceButtonContainer {
  border: none;
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 2rem 2rem;
  color: #8bc334;
  cursor: pointer;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-align: left;
  background-color: #f1f8e7;
}

.serviceButtonContainer:hover {
  color: #ffffff;
  background-color: #8bc334;
}

.displayAddServiceButtonGroup {
  display: flex;
  align-items: center;
  /* display: none; */
}

.displayAddServiceButtonGroup div {
  /* flex: 0.5; */
  flex: 1;
}

.displayAllServiceButton {
  margin-right: 20px;
  display: none;
}

.serviceCheck {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.serviceNotCheck {
  width: 50px;
  height: 50px;
  background: rgba(139, 195, 52, 0.15);
}

.serviceButtonContainer:hover .serviceNotCheck {
  background: rgba(255, 255, 255, 0.2);
}

.lapsedButton {
  border-radius: 5px;
  padding: 2rem 2rem;
  color: white;
  font-size: 20px !important;
  line-height: 24px !important;
  text-align: left;
  font-weight: 500 !important;
  margin-bottom: 1rem;
  background: rgba(245, 102, 102, 0.12);
  color: #f56666;
}

.lapsedIcon {
  width: 50px;
  height: 50px;
  background: rgba(245, 102, 102, 0.15);
  border-radius: 3px;
}

.subscribeButton {
  padding: 2rem 2rem;
  border-radius: 5px;
  background: rgba(245, 102, 102, 0.15);
}

.subscribeButton:hover {
  background: rgba(245, 102, 102, 0.18);
}
