.modalContainer {
    display: flex;
    padding: 2rem 4rem 3rem;
    flex-direction: column;
}

.closeButton {
    top: 0;
    right: 0;
    position: absolute;  
    margin-right: 0 !important;
    color: #F56666 !important;
    background-color: #feeded !important;
	outline: none;
}

.closeButton:hover {
    color: #ffffff !important;
    background-color: #F56666 !important;	
}

.modalContainer>div:nth-child(3) { 
    display: flex;
    align-items: center;
    justify-content: center;
}

.descriptionText {
    margin: 1rem 0 2.5rem;
    font-size: 20px; 
    line-height: 28px;
    text-align: center;
    font-weight: normal;
}

.getReportsButton {
    border: none;
    border-radius: 5px;
    padding: 1rem 3rem;
    color: #8BC334;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
    background-color: #f1f8e7;
	outline: none;
}

.getReportsButton:hover {
	color: #ffffff;
	background-color: #8BC334;	
}

.blueButton {
    margin-right: 20px;
}