.closeIcon {
  height: 35px;
  width: 35px;
  background: #f56666;
  background: rgba(245, 102, 102, 0.12);

  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #f56666;
}

.headerText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

.serviceItem {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */

  display: flex;
  align-items: center;
  text-align: center;
  color: #b0b0b0;

  height: 50px;
  border: 1px solid #ebebeb;
  background: #f9f9f9;
  box-sizing: border-box;
  border-radius: 3px;
}

.serviceItem:hover {
  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #8ac04a;
}

.serviceItem.selected {
  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #8ac04a;
}
