.mainContainer {
  display: flex;
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;

  flex-direction: column;
}

.spinnerDiv {
  margin-top: 30vh;
  text-align: center;
}

.content {
  display: flex;
  padding: 0 4rem;
  flex-direction: column;
}

.commingSoon {
  font-size: 14px;
  margin-left: 0.3rem;
  font-weight: 450;
}

.comingSoonText {
  font-weight: 700;
}

.commingsoonContiner {
  background-color: #ffffff;
  margin-bottom: 4rem;
  padding: 0.375rem;
}

.dashboardHeaderContainer {
  display: flex;
  margin-bottom: 3rem;
  justify-content: space-between;
}

.dashboardTextContainer {
  display: flex;
  flex-direction: column;
}

.dashboardTextHeader {
  font-size: 39px;
  line-height: 46px;
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 12px;
}

.dashboardTextDescription {
  /* max-width: 510px; */
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
}

.feedbackButtonsContainer {
  display: flex;
  justify-content: space-between;
}

.buttonGroup:last-child {
  /* display: none; */
}

.buttonGroup:first-child {
  /* margin-right: 3rem; */
  position: relative;
}

.feedbackButtonsHeader {
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  margin-bottom: 1rem;
  display: block;
}

.requestFeedbackButton {
  padding: 1.3rem 3rem;
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: #7d68fc;
  outline: none;
}

.requestFeedbackButton:hover + div {
  display: block !important;
}

.requestFeedbackButton:hover,
.viewFeedbackButton:hover {
  opacity: 0.5;
}

.reportsCount {
  color: #388cef;
  margin: auto 4px;
}

.viewFeedbackButton {
  padding: 1.3rem 3rem;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: #ffffff;
  outline: none;
}

.nqaCardsContainer {
  display: flex;
  flex-direction: column;
}

.nqaCardRow {
  display: flex;
  flex-wrap: wrap;
}

.nqaCardRow .nqaCard:nth-child(1) .progressBarContainer {
  background-color: #dfedda;
}

.nqaCardRow .nqaCard:nth-child(1) .progressBarContainer > .progressBar {
  background-color: #61a447;
}

.nqaCardRow .nqaCard:nth-child(2) .progressBarContainer {
  background-color: #fef7d7;
}

.nqaCardRow .nqaCard:nth-child(2) .progressBarContainer > .progressBar {
  background-color: #fbd539;
}

.nqaCardRow .nqaCard:nth-child(3) .progressBarContainer {
  background-color: #e395a533;
}

.nqaCardRow .nqaCard:nth-child(3) .progressBarContainer > .progressBar {
  background-color: #e395a5;
}

.nqaCardRow .nqaCard:nth-child(4) .progressBarContainer {
  background-color: #eef5e1;
}

.nqaCardRow .nqaCard:nth-child(4) .progressBarContainer > .progressBar {
  background-color: #accf6b;
}

.nqaCardRow .nqaCard:nth-child(5) .progressBarContainer {
  background-color: #fdecd8;
}

.nqaCardRow .nqaCard:nth-child(5) .progressBarContainer > .progressBar {
  background-color: #f3a23e;
}

.nqaCardRow .nqaCard:nth-child(6) .progressBarContainer {
  background-color: #db308f33;
}

.nqaCardRow .nqaCard:nth-child(6) .progressBarContainer > .progressBar {
  background-color: #db308f;
}

.nqaCardRow .nqaCard:nth-child(7) .progressBarContainer {
  background-color: #d8eaf5;
}

.nqaCardRow .nqaCard:nth-child(7) .progressBarContainer > .progressBar {
  background-color: #3a96cf;
}

.nqaHeaderRow {
  display: flex;
  margin-bottom: 3rem;
  align-items: center;
  justify-content: space-between;
}

.nqaHeaderText {
  font-size: 39px;
  line-height: 50px;
  font-weight: normal;
  letter-spacing: -1px;
}

.nqaHeaderCheck {
  width: 50px !important;
  height: 50px !important;
  padding: 0 !important;
  font-size: 24px !important;
  color: #8bc334 !important;
  margin-right: 0 !important;
  pointer-events: none !important;
  background-color: #f1f8e7 !important;
}

.progressBarContainer {
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  max-width: 100%;
  box-shadow: none;
  position: relative;
}

.progressBar {
  min-width: 0;
  height: 1.75em;
  display: block;
  line-height: 1;
  position: relative;
}

.progressBarText {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.nqaButton {
  padding: 1.5rem 0rem;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: rgb(229 229 229 / 0.5);
  display: block;
  width: 100%;
  outline: none;
}

.nqaButton:hover {
  background-color: #e5e5e5;
}

.serviceDetailsProgressCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 1.5rem 2rem 2rem;
  justify-content: space-between;
}

.serviceDetailPercent {
  font-size: 55px;
  line-height: 65px;
  position: relative;
  text-align: center;
  font-weight: normal;
  letter-spacing: -1px;
  margin-top: 1rem;
}

.serviceDetailPercentIcon {
  top: 8px;
  position: absolute;
  font-size: 29px;
  line-height: 34px;
  text-align: center;
  font-weight: normal;
  letter-spacing: -1px;
}

.serviceDetailText {
  font-size: 20px;
  line-height: 24px;
  align-self: center;
  text-align: center;
  font-weight: normal;
  margin-top: -2rem;
}

.serviceDetailButton {
  padding: 1.5rem;
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: #8bc334;
  outline: none;
}

.serviceDetailButton:hover {
  background-color: #97d439;
}

.nqaCard {
  padding: 1.5rem 2rem 2rem;
  background-color: #ffffff;
}

.serviceDetailIconContainer {
  background-color: #8bc3341f;
  border-radius: 50%;
  padding: 18px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0.9rem;
  text-align: center;
}

.serviceDetailIcon {
  font-size: 35px !important;
  line-height: 40px;
  color: #8bc334;
  margin-right: 0 !important;
}

.buttonGroup div {
  position: absolute !important;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 24px !important;
  text-align: center !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  background-clip: padding-box !important;
  color: #f56666 !important;
  background-color: #edd5d5 !important;
  border: 1px solid #edd5d5 !important;
  width: 160px;
  display: none !important;
}

.viewerHeader {
  width: 1124px;
  height: 70px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 5px;
}

.viewerText {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.buttonContainer {
  padding: 2rem 1rem;
  border: none;
  cursor: pointer;
  color: #8bc334;
  width: 105.62px !important;
  height: 50px !important;
  font-size: 17px !important;

  border-radius: 5px;
  text-decoration: none;
  background-color: #f1f8e7;
  outline: none;
}

.buttonContainer:hover {
  color: #ffffff;
  background-color: #8bc334;
}

.viewerInfoIcon {
  font-style: normal;
  font-weight: 900;
  font-size: 17px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  text-transform: capitalize;

  color: #8f5fe8;
}

.closeButton {
  padding: 2rem 1rem;
  color: #f56666 !important;
  border: none;
  background-color: #feeded !important;
  font-size: 17px !important;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  width: 105.62px !important;
  height: 50px !important;
  outline: none;
}

.closeButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.viewerButtonText {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

@media screen and (max-width: 1099px) {
  .dashboardHeaderContainer {
    flex-wrap: wrap;
  }
  .dashboardTextContainer {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 768px) {
  .nqaCardRow > div {
    -ms-flex: 0 0 50%;
    flex: 0 0 49.63%;
    max-width: 50%;
  }
}

@media screen and (max-width: 1099px) and (min-width: 768px) {
  .nqaCardRow:nth-child(1) .nqaCard:not(:nth-child(even)) {
    margin-right: 4px;
  }
  .nqaCardRow:nth-child(1) .nqaCard:not(:nth-last-child(-n + 2)) {
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 1299px) and (min-width: 1100px) {
  .nqaCardRow:nth-child(1) .nqaCard:not(:nth-child(3n + 3)) {
    margin-right: 4px;
  }
  .nqaCardRow:nth-child(1) .nqaCard:not(:nth-last-child(-n + 2)) {
    margin-bottom: 4px;
  }
}

@media screen and (min-width: 1100px) {
  .nqaCardRow > div {
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%;
  }
  .dashboardTextDescription {
    /* max-width: 340px; */
    max-width: 510px;
  }
  .content {
    padding: 0 8rem;
  }
}

@media screen and (min-width: 1200px) {
  /* .dashboardTextDescription { */
  /* max-width: 450px; */
  /* } */
}

@media screen and (min-width: 1300px) {
  .nqaCardRow > div {
    -ms-flex: 0 0 25%;
    flex: 0 0 24.7%;
    max-width: 25%;
  }
  .nqaCardRow:nth-child(1) .nqaCard:not(:nth-child(4n + 4)) {
    margin-right: 4px;
  }
  .nqaCardRow:nth-child(1) .nqaCard:not(:nth-last-child(-n + 4)) {
    margin-bottom: 4px;
  }
  /* .dashboardTextDescription { */
  /* max-width: 510px; */
  /* } */
}
