.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
}

.backButtonContainer {
  text-align: right;
}

.backButtonText {
  width: 50px;
  height: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  align-self: flex-end;
  border-radius: 50%;
  background-color: #ffffff;
  outline: none;
}

.backButton {
  top: 13px;
  left: 13px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.backButtonText:hover > .backButton {
  color: #222222 !important;
}

.content {
  display: flex;
  padding: 0 4rem;
  flex-direction: column;
  margin-bottom: 5rem;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.headerTextGroup {
  display: flex;
  flex-direction: column;
}

.headerText {
  font-size: 39px;
  line-height: 46px;
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 1rem;
}

.headerSubText {
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
}

.backToDashboardButton {
  height: 50px;
  padding: 0 3rem;
  border: none;
  cursor: pointer;
  color: #c9c9c9;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: #ffffff;
  outline: none;
}

.backToDashboardButton:hover {
  color: #222222;
}

.cardContainer {
  margin-top: 4rem;
}

.navigationOptionsRow {
  display: flex;
}

.navigationOptionSelected {
  background: #ffffff;
}

.navigationOptionSelected,
.navigationOption {
  cursor: pointer;
  padding: 3rem 2rem;
  margin-bottom: 0;
  font-size: 26px;
  line-height: 26px;
  text-align: center;
  font-weight: normal;
  /* -ms-flex: 0 0 50%; */
  /* flex: 0 0 33%; */
  /* max-width: 50%; */
}

.navigationOption {
  margin-bottom: 4px;
  color: #c9c9c9;
  background: #f7f8f6;
}

.navigationOption:last-child {
  /* display: none; */
}

.navigationOptionSelected:first-child {
  border-right: 2px solid #eaeee8;
}

.navigationOption:first-child {
  border-right: 2px solid #eaeee8;
}

.navigationOptionSelected:nth-child(2) {
  border-left: 2px solid #eaeee8;
}

.navigationOption:nth-child(2) {
  border-left: 2px solid #eaeee8;
}

.navigationOption:hover {
  color: #222222;
  background: #ffffff;
}

.middleNavigationOptionBorder {
  /* margin-left: 4px; */
  margin-right: 4px;
}

.cardContent {
  padding: 4rem;
  background: #ffffff;
}

@media screen and (max-width: 820px) {
  .headerTextGroup {
    max-width: 380px;
  }
}

@media screen and (min-width: 1024px) {
  .navigationOptionSelected,
  .navigationOption {
    padding: 3rem;
    font-size: 30px;
  }
}

@media screen and (min-width: 1100px) {
  .content {
    padding: 0 8rem;
  }
}
