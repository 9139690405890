.modalContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 734px;
  height: 523px;
}

.headerText {
  width: 518px;
  height: 100px;

  /* Title Big 39px */

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 39px;
  line-height: 50px;
  /* or 128% */

  text-align: center;
  letter-spacing: -1px;

  color: #272827;
}

.subText {
  /* Paragraph 16px */

  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  /* identical to box height, or 156% */

  text-align: center;

  color: #272828;
}

.headerClip {
  width: 288.53px;
  height: 142px;
}

.closeButton {
  width: 136px;
  height: 65px;
}

.closeButton {
  width: 136px;
  height: 65px;
  padding: 1.3rem 4rem;
  background-color: #f1f8e7;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: #8bc334;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  text-align: center;
  outline: none;
  display: flex;
  justify-content: center;
}

.closeButton:hover {
  color: #ffffff;
  background-color: #8bc334;
}
