.serviceDetails {
  border-bottom: 1px solid #e4e4e4 !important;
}

.headerServiceText {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  /* identical to box height */

  color: #222222;
}

.headerServiceSub {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  /* identical to box height, or 173% */

  color: #272828;
}

.serviceLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  /* or 140% */

  color: #272828;
}

.serviceInput {
  height: 60px;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  /* identical to box height, or 173% */
  padding-top: 1.4rem !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  color: #272828;
  background: #ffffff;

  box-sizing: border-box;
  border-radius: 3px;
}

.serviceDropdown {
  height: 60px;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  /* identical to box height, or 173% */
  padding-top: 1.4rem !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  color: #272828;
  background: #ffffff;
  border: 1px solid #000000 !important;
  box-sizing: border-box;
  border-radius: 3px;
}

.serviceDropdown > i {
  padding-top: 1.4rem !important;
}

.saveServiceButton {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
  width: 243px;
  height: 60px;
  background: rgba(139, 195, 52, 1);
  border-radius: 5px;
}

.saveServiceButton:hover {
  background: rgba(139, 195, 52, 0.9);
}
