.modalContainer {
    display: flex;
    padding: 2rem 4rem;
    flex-direction: column;
}
  
.closeButton {
    top: 0;
    right: 0;
    position: absolute;
    border-radius: 0 !important;
    margin-right: 0 !important;
    color: #F56666 !important;
    background-color: #feeded !important;
}

.closeButton:hover {
	color: #ffffff !important;  
	background-color: #F56666 !important;
}

.headerText {
    margin: 3rem 0 1.5rem;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    font-weight: normal;
}

.descriptionText {
    align-self: center;
    max-width: 450px;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    text-align: center;
}

.selectionGroup {
    display: flex;
    margin-top: 2rem;
    align-self: center;
    flex-direction: column;
}

.selectionRow {
    display: flex;
	flex-wrap: wrap;
}

.nqaButton {
	width: 49%;
    padding: 1.5rem 3rem;
    border: none;
    cursor: pointer;
    color: black;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
    border-radius: 5px;
    text-decoration: none;
    background-color: rgb(228 228 228 / .5);
	outline: none;
}

.nqaButton:nth-child(odd) {
	margin-right: 2%;
}

.nqaButton:not(:last-child) {
	margin-bottom: 1rem;
}

.nqaButtonSelected {
    width: 49%;
    padding: 1.5rem 3rem;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
    border-radius: 5px;
    text-decoration: none;
	outline: none;
}

.nqaButtonSelected:nth-child(odd) {
	margin-right: 2%;
}

.nqaButtonSelected:not(:last-child) {
	margin-bottom: 1rem;
}

.checkIcon {
    color: white !important;
    background-color: none !important;
}

.nqaButtonSelected:first-child {
    background-color: #61A447;
}

.nqaButton:first-child:hover {
    color: #61A447;
	background-color: #dfedda;
}

.nqaButtonSelected:nth-child(2) {
    background-color: #FBD539;
}

.nqaButton:nth-child(2):hover {
    color: #FBD539;
	background-color: #fef7d7;
}

.nqaButtonSelected:nth-child(3) {
    background-color: #E395A5;
}

.nqaButton:nth-child(3):hover {
    color: #E395A5;
	background-color: #E395A533;	
}

.nqaButtonSelected:nth-child(4) {
    background-color: #ACCF6B;
}

.nqaButton:nth-child(4):hover {
    color: #ACCF6B;
	background-color: #eef5e1;	
}

.nqaButtonSelected:nth-child(5) {
    background-color: #F3A23E;
}

.nqaButton:nth-child(5):hover {
    color: #F3A23E;
	background-color: #fdecd8;	
}

.nqaButtonSelected:nth-child(6) {
    background-color: #DB308F;
}

.nqaButton:nth-child(6):hover {
    color: #DB308F;
	background-color: #DB308F33;
}

.nqaButtonSelected:nth-child(7) {
    background-color: #3A96CF;
}

.nqaButton:nth-child(7):hover {
    color: #3A96CF;
	background-color: #d8eaf5;
}

.divider {
    margin: 3rem -4rem 0rem;
    border: 1px solid #e7e7e7;
}

.commentSection {
    width: 100%;
    margin-top: 2rem;
    align-self: center;
}

.commentSectionText {
    height: 130px;
    margin-bottom: 2rem;
}

.requestFeedbackButton {
    align-self: center;
    padding: 1.3rem 3rem;
    border: none;
    cursor: pointer;
    color: #7D68FC;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
    border-radius: 5px;
    text-decoration: none;
    background-color: #E5E1FE;
	outline: none;
}

.requestFeedbackButton:hover {
	color: #ffffff;
	background-color: #7D68FC;
}

.requestFeedNote {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    max-width: 445px; 
    margin-right: auto;
    margin-left: auto;
    margin-top: 43px;
}

.requestFeedNote a,
.requestFeedNote a:hover {
    color: #272828;
    text-decoration: underline;
}


@media screen and (min-width: 1100px) {

    .commentSection {
        width: 580px;
    }

}