.modalContainer {
  display: flex;
  flex-direction: column;
}

.closeButton {
  top: 0;
  right: 0;
  width: 55px;
  height: 55px;
  position: absolute;
  border-radius: 0 !important;
  margin-right: 0 !important;
  color: #f56666 !important;
  background-color: #feeded !important;
}

.closeButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.spinnerDiv {
  margin: 20vh;
  text-align: center;
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.divider {
  border: 1px solid #e7e7e7;
}

.headerText {
  margin-top: 4rem;
}

.headerText,
.tasksHeaderText {
  font-size: 39px;
  line-height: 46px;
  text-align: center;
  font-weight: normal;
  letter-spacing: -1px;
  align-self: center;
  display: block;
}

.subText {
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  font-weight: normal;
  align-self: center;
}

.downloadResourceHeader {
  font-size: 30px;
  line-height: 36px;
  font-weight: normal;
  margin-bottom: 1.5rem;
  align-self: flex-start;
}

.downloadResourceRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e7e7e7;
  padding: 1.5rem 0;
}

.downloadResourceRow:last-child {
  border-bottom: 1px solid #e7e7e7;
}

.downloadResourceDisplay {
  display: flex;
  padding: 1.5rem 0;
}

.resourceImageContainer {
  width: 75px;
  height: 75px;
  position: relative;
}

.resourceImage {
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  margin: auto;
  position: absolute;
}

.downloadResourceDetails {
  display: flex;
  margin-left: 1rem;
  flex-direction: column;
}

.downloadResourceFileName {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  margin-top: 1.5rem;
  margin-bottom: 8px;
}

.downloadResourceUploadDate {
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
}

.documentButtonGroup {
  display: flex;
  flex-direction: column;
}

.downloadButton {
  font-size: 40px !important;
  font-weight: 900 !important;
  line-height: 46px !important;
  margin-right: 0 !important;
  color: #8bc334 !important;
  background-color: #f1f8e7 !important;
  padding: 22px 20px !important;
  border-radius: 5px !important;
}

.downloadButton:hover {
  color: #ffffff !important;
  background-color: #8bc334 !important;
}

.documentButtonText {
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.videoTutorialRow {
  align-items: center;
}

.strategyTaskRow,
.generalCommentsRow,
.goalStrategyRow,
.downloadResourceGroup,
.modalHeaderRow,
.videoTutorialRow {
  display: flex;
  padding: 4rem 6rem;
  flex-direction: column;
}

.videoContainer {
  color: #ffffff;
  padding: 3rem 3.3rem 3.3rem 3.3rem;
  width: 551px;
  height: 338px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../static/images/webinarBox1.png");
}

.videoTextGroup {
  display: flex;
  flex-direction: column;
}

.videoLabel {
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  margin-bottom: 1rem;
}

.videoHeaderTitle {
  font-size: 30px;
  line-height: 34px;
  font-weight: normal;
}

.playButton {
  cursor: pointer !important;
  color: #ffffff !important;
  margin-right: 0 !important;
  font-size: 2rem !important;
  background-color: rgb(255 255 255 / 0.5) !important;
  padding: 23px 20px 20px 24px !important;
}

.videoContainer:hover > .playButton {
  color: #333333 !important;
  background-color: #ffffff !important;
}

.videoHelperText {
  font-size: 15px;
  font-weight: 300;
  line-height: 26px;
  text-align: center;
  margin-top: 0.5rem;
}

.goalStrategyBox {
  height: 35vh;
}

.strategyTaskForm {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
}

.tasksCountRow {
  padding-top: 4rem;
}

.tasksCountRow > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tasksCountRow > div > div {
  font-size: 39px;
  line-height: 46px;
  text-align: center;
  font-weight: normal;
  letter-spacing: -1px;
}

.tasksCountRow > div > div > text:first-child {
  color: #388cef;
  margin-right: 10px;
}

.showTasksButton,
.addTaskButton {
  height: 60px;
  padding: 1rem 3rem;
  align-self: center;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  color: black;
  background-color: #e4e4e4;
}

.showTasksButton:hover,
.addTaskButton:hover {
  background-color: #f2f2f2;
}

.tasksGroup {
  padding-top: 4rem;
}

.taskItemContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #c9c9c9;
  justify-content: space-between;
}

.taskContentRow {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

.taskDescriptionColumn {
  display: flex;
  flex-direction: column;
  padding: 2rem 0 2rem 2.5rem;
}

.taskDescriptionHeader {
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  margin-bottom: 8px;
}

.taskDescriptionSubtext {
  display: flex;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
}

.taskDescriptionSubtext > text:not(:last-child) {
  margin-right: 12px;
}

.taskDetailNotStarted {
  color: #f56666;
}

.taskDetailInProgress {
  color: #388cef;
}

.taskDetailCompleted {
  color: #8bc334;
}

.taskCollapseButton,
.taskCollapseButtonSelected {
  font-size: 40px !important;
  margin-right: 0 !important;
  color: #e4e4e4 !important;
  line-height: 46px !important;
  text-align: center !important;
  font-weight: normal !important;
  background-color: transparent !important;
}

.taskCollapseButtonSelected {
  color: #388cef !important;
}

.taskDetails {
  display: flex;
  flex-direction: column;
  padding: 2rem 2.5rem 4rem;
}

.dropdownRow {
  display: flex;
}

.dropdownRow > div:first-child {
  margin-right: 20px;
}

.dropdownGroup {
  flex: 0.5;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-direction: column;
}

.dropdownText {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
}

.dropdownBox,
.dropdownBoxStatus {
  height: 60px;
  font-size: 20px !important;
  margin-top: 0.5rem !important;
  border-radius: 5px !important;
  padding: 1.25rem 1.25rem !important;
  border: 1px solid black !important;
}

.datePickerGroup {
  position: relative;
}

.datePickerBox {
  height: 60px;
  width: 100%;
  padding: 1rem;
  font-size: 20px;
  margin-top: 0.5rem;
  border-radius: 3px;
  border: 1px solid black;
}

.datePickerIcon {
  top: 24px;
  right: 0.5rem;
  position: absolute;
  background-color: white;
  font-size: 20px !important;
}

.summaryTextBox {
  height: 200px;
  margin-bottom: 1rem;
}

.statusSelect1,
.statusSelect2,
.statusSelect3 {
  height: 60px;
  cursor: pointer;
  padding: 1rem 2rem;
  margin-top: 0.5rem;
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  border-radius: 3px;
  text-align: center;
  color: white;
}

.statusSelect1 {
  background-color: #f56666;
}

.statusSelect1:hover {
  background-color: #f78585;
}

.statusSelect2 {
  background-color: #388cef;
}

.statusSelect3 {
  background-color: #8bc334;
}

.statusSelect > text {
  margin-left: 2rem;
}

.statusSelect > *:last-child {
  float: right;
  font-size: 24px !important;
}

.taskConfirmationModal {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 3rem 3rem;
}

.blueButton {
  margin-top: 1rem;
  align-self: center;
}

.redButton {
  margin-top: 1rem;
  text-align: center;
}

.goalStrategyRow .subText {
  margin-bottom: 12px;
}

@media screen and (min-width: 1024px) {
  .strategyTaskRow,
  .generalCommentsRow,
  .goalStrategyRow,
  .downloadResourceGroup,
  .modalHeaderRow,
  .videoTutorialRow {
    padding: 4rem 10rem;
  }
}

@media screen and (min-width: 1100px) {
  .strategyTaskRow,
  .generalCommentsRow,
  .goalStrategyRow,
  .downloadResourceGroup,
  .modalHeaderRow,
  .videoTutorialRow {
    padding: 4rem 12.5rem;
  }
}
