.modalContainer {
    display: flex;
    flex-direction: column;
}

.closeButton {
    top: 0;
    right: 0;
    position: absolute;
    border-radius: 0 !important;
    margin-right: 0 !important;
    color: #F56666 !important;
    background-color: #feeded !important;
}

.closeButton:hover {
    color: #ffffff !important;
    background-color: #F56666 !important;
}

.modalContent {
    display: flex;
    align-content: center;
    padding: 4rem 5rem;
    flex-direction: column;
}

.headerText {
    font-weight: normal;
    font-size: 30px;
    line-height: 32px;
    text-align: left;
    letter-spacing: -1px;
    margin-bottom: .5rem;
}

.subText {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-align: left;
}

.inputColumn {
    display: flex;
    margin-top: 1rem;
}

.inputColumn > div:first-child {
    margin-right: 2rem;
}

.inputColumn > div {
    flex: 0.5;
    display: flex;
    flex-direction: column;
}
.inputTextAreaColumn div {
    margin-top: 2rem;    
}

.inputTextAreaColumn {
    width: 100%;   
}

.inputComments {
    resize: none;
    padding: 1rem;
    font-size: 20px;
    border-radius: 3px;
    margin-top: 0.5rem;
    border: 1px solid black;
    height: 150px;    
}

.dropdownGroup {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
}

.required {
    color: #F56666;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;    
}

.dropdownBox {
    height: 60px;
    padding: 1.25rem 1rem !important;
    font-size: 20px !important;
    margin-top: 0.5rem !important;
    border-radius: 5px !important;
    border: 1px solid black !important;
    max-width: unset!important;
}

.inputColumn .dropdownGroup div {
    margin-top: unset;
}

.dropdownText {
    font-size: 20px;
    line-height: 28px;
    font-weight: normal;
}

.blueButton button {
    margin-top: 30px;    
}

.blueButton button {
    color: #ffffff;
    background-color: #388CEF;    
    padding: 1.3rem 5rem;
}

.blueButton button:hover {
    color: #388CEF;
    background-color: #e7f1fd;    
}



@media screen and (min-width: 1024px) {

    .required {
        font-size: 20px;    
    }

}