.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
}

.backButtonContainer {
  text-align: right;
}

.backButtonText {
  width: 50px;
  height: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  align-self: flex-end;
  border-radius: 50%;
  background-color: #ffffff;
  outline: none;
}

.backButton {
  top: 13px;
  left: 13px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.backButtonText:hover > .backButton {
  color: #222222 !important;
}

.spinnerDiv {
  margin: 20vh;
  text-align: center;
}

.content {
  padding: 0 4em;
}

.headerTextGroup {
}

.headerTaskMessageGroup {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3.5rem;
  display: none;
}

.headerTaskMessageContainer:first-child {
  margin-bottom: 1rem;
}

.headerTaskMessageContainer {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  height: 60px;
  padding: 20px 24px;
  display: flex;
  background: #ffffff;
  align-items: center;
  justify-content: space-between;
}

.headerGroupRow {
  display: flex;
  justify-content: space-between;
}

.buttonLogout {
  color: #f56666;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  padding: 1.2rem 2rem;
  cursor: pointer;
}

.buttonLogout:hover {
  opacity: 0.8;
}

.headerText {
  font-size: 39px;
  font-weight: normal;
  line-height: 50px;
  margin-bottom: 1rem;
  letter-spacing: -1px;
  margin-top: 0;
}

.headerSubText {
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
}

.unsolvedTaskText {
  margin: auto 4px;
  color: #f56666;
}

.unreadMessageText {
  margin: auto 4px;
  color: #388cef;
}

.viewTasksButton {
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  text-decoration-line: underline;
  border: none;
  padding-right: 8px;
  border-radius: 5px;
  background-color: #ffffff;
  outline: none;
}

.viewTasksButtonArrow {
  font-size: 26px;
}

.viewTasksButtonArrow,
.viewTasksButton {
  color: #f56666;
  cursor: pointer;
}

.viewMessagesButton {
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  text-decoration-line: underline;
  border: none;
  padding-right: 8px;
  border-radius: 5px;
  background-color: #ffffff;
  outline: none;
}

.viewMessagesButtonArrow {
  font-size: 26px;
}

.viewMessagesButtonArrow,
.viewMessagesButton {
  color: #388cef;
  cursor: pointer;
}

.cardsGroup {
  display: flex;
  flex-wrap: wrap;
  margin: 4rem 0;
}

.cardContainer:first-child {
  margin-right: 1%;
}

.cardContainer:last-child {
  margin-top: 0.5rem;
}

.cardContainer {
  -ms-flex: 0 0 50%;
  flex: 0 0 49.5%;
  max-width: 50%;
  display: flex;
  padding: 3rem 2.5rem 4rem;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}

.icon {
  margin-top: 2.5rem !important;
  margin-bottom: 0.5em !important;
  color: #e5e5e5 !important;
  font-size: 80px !important;
}

.cardHeaderText {
  font-style: normal;
  font-weight: normal;
  font-size: 39px;
  line-height: 50px;
  text-align: center;
  letter-spacing: -1px;
  margin-bottom: 1rem;
}

.cardSubText {
  height: 80px;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-weight: normal;
  margin-bottom: 3rem;
}

.buttonGroup {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.buttonContainer {
  padding: 2rem 1rem;
  border: none;
  cursor: pointer;
  color: #8bc334;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: #f1f8e7;
  outline: none;
  display: block;
  width: 100%;
}

.buttonContainer:not(:last-child) {
  margin-bottom: 1rem;
}

.buttonContainer:hover {
  color: #ffffff;
  background-color: #8bc334;
}

/* .cardContainer:last-child .buttonContainer:last-child {
  display: none;
} */

.cardContainer:last-child .buttonContainer {
  position: relative;
}

.cardContainer:last-child .buttonContainer:hover > div {
  display: block !important;
}

.cardContainer:last-child .buttonContainer div {
  position: absolute !important;
  top: -40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 24px !important;
  text-align: center !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  background-clip: padding-box !important;
  color: #f56666 !important;
  background-color: #edd5d5 !important;
  border: 1px solid #edd5d5 !important;
  width: 160px;
  display: none !important;
}

@media screen and (max-width: 820px) {
  .headerTextGroup {
    max-width: 380px;
  }
}

@media screen and (min-width: 992px) {
  .buttonContainer {
    padding: 2rem 3rem;
  }
}

@media screen and (min-width: 1100px) {
  .content {
    padding: 0 8rem;
    /* margin-top: -2.12rem; */
  }
  .backButtonText {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1210px) {
  .headerTaskMessageContainer:first-child {
    margin-right: 1%;
    margin-bottom: 0;
  }
  .headerTaskMessageContainer {
    -ms-flex: 0 0 50%;
    flex: 0 0 49.5%;
    max-width: 50%;
  }
}

@media screen and (min-width: 1366px) {
  .cardContainer:nth-child(even) {
    margin-right: 0.5%;
    margin-left: 0.5%;
  }
  .cardContainer:first-child {
    margin-right: 0;
  }
  .cardContainer:last-child {
    margin-top: 0;
  }
  .cardContainer {
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%;
  }
}
