.mainContainer {
	max-width: 1366px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 4rem;
}

.backButtonContainer {
	text-align: right;
}

.backButtonText {
    width: 50px;
    height: 50px;
    position: relative;  
    border: none;
    cursor: pointer;
    margin-top: 1rem;
    margin-right: 1rem;   
    margin-bottom: 1rem;
    align-self: flex-end;
    border-radius: 50%;
    background-color: #ffffff;
	outline: none;
}

.backButton {
    top: 13px;
    left: 13px;
    width: 25px;
    height: 25px;
    position: absolute;
}

.backButtonText:hover > .backButton{
	color: #222222!important;
}

.content {
	padding: 0 4rem;
} 

.headerContainer {
    display: flex;
    justify-content: space-between;
}

.headerTextGroup {
    
}

.headerText {
    font-size: 39px;
    line-height: 50px;
    font-weight: normal;
    letter-spacing: -1px;
    margin-bottom: 1rem;
}

.headerSubText {
    font-size: 20px;
    line-height: 32px;
    font-weight: normal;
}

.backToDashboardButton {
    height: 50px;
    padding: 0 3rem;
    margin-bottom: 1rem;
    border: none;
    cursor: pointer; 
    color: #C9C9C9;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
    border-radius: 5px;
    text-decoration: none;
    background-color: #ffffff;
	outline: none;
}

.backToDashboardButton:hover {
	color: #222222;
}

.searchFilterRow {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem;
    margin-top: 4.5rem;
    margin-bottom: 4px;
    background-color: #ffffff;
    justify-content: space-between;
}

.searchFilterRow>div {
    display: flex;
    align-content: center;
}

.searchInput {
    max-width: 280px;
    height: 80px;
    border-radius: 4px;
    font-size: 26px !important;
    line-height: 36px !important;
    font-weight: normal !important;
    color: #BEBEBE !important;        
}

.searchInput input {
    border: 1px solid #BEBEBE !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;    
}

.searchInput input:focus {
    color: #BEBEBE !important;        
}

.searchInput::placeholder {
    color: #BEBEBE !important;    
}

.filterDropdown, .filterDropdown>div {
    color: #BEBEBE !important;
    font-size: 26px;
    line-height: 36px;
    align-self: center;
    font-weight: normal !important;
}

.filterDropdown {
    margin-left: 3rem;
}

.newServiceButton {
    padding: 1rem 4rem;
    border: none;
    cursor: pointer;
    color: #8BC334;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
    border-radius: 5px;
    text-decoration: none;
    background-color: #f1f8e7;
	outline: none; 
    margin-top: 1rem;
} 

.newServiceButton:hover {
	color: #ffffff;
	background-color: #8BC334;
}

.modalContainer {
    display: flex;
    padding: 2rem 4rem 4rem;
    flex-direction: column;
}

.modalContainer>div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeButton {
    top: 0;
    right: 0;
    position: absolute;
    margin-right: 0 !important;
    color: #F56666 !important;
    background-color: #feeded !important;
}

.closeButton:hover {
    color: #ffffff !important;
    background-color: #F56666 !important;	
}

.modalHeaderText {
    margin-top: 1rem;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    font-weight: normal;
    margin-bottom: 3rem;
}

.modalYesButton {
    border: none;
    border-radius: 5px;
    padding: 1rem 4rem;
    margin-right: 20px;
    color: #8BC334;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
    background-color: #f1f8e7;
	outline: none;
}

.modalYesButton:hover {
    color: #ffffff;	
    background-color: #8BC334;	
}

.modalNoButton {
    padding: 1rem 4rem;
}


@media screen and (min-width: 1024px) {

    .newServiceButton {
        margin-top: 0;
    } 

}

@media screen and (min-width: 1100px) {
    
    .content {
        padding: 0 8rem;
    }
    
    /* .filterDropdown { */
        /* margin-left: 3rem; */
    /* } */
    
        
    .searchInput {
        font-size: 30px !important;
    }

    .filterDropdown, .filterDropdown>div {
        font-size: 30px;
    }
    
}

@media screen and (min-width: 1200px) {
  
    .searchInput {
        max-width: 350px;
    }  
    
    .content {
        padding: 0 10rem;
    }
    
}