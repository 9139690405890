.serviceCardContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;
    background-color: white;
    padding: 4rem 3rem 3rem 4.5rem;
}

.serviceCardTextGroup {
    display: flex;
    flex-direction: column;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.serviceCardHeaderText {
    font-size: 30px;
    line-height: 36px;
    font-weight: normal;
    margin-bottom: 1.5rem;
}

.serviceCardSubTextGroup {
    display: flex;
}

.serviceCardSubTextGroup>div:first-child {
    flex: 0.45;
    display: flex;
    flex-direction: column;
}

.serviceCardSubText {
    font-size: 20px;
    line-height: 30px;
    font-weight: normal;
    margin-bottom: 1rem;
}

.serviceCardSubTextProgress {
    flex: 0.55;
    display: flex;
    padding-right: 4.5rem;
    flex-direction: column;
}

.progressBarContainer {
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;
    max-width: 100%;
    box-shadow: none;
    position: relative;
    background: rgba(0, 0, 0, .1);
}

.progressBar {
    min-width: 0;
    height: 1rem;
    display: block;
    line-height: 1;
    position: relative;
    background: #388CEF;
}

.progressBarText {
    display: flex;
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
    align-self: flex-start;
    justify-content: flex-end;
}

.serviceCardActionsGroup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 315px;
    margin-top: 2rem;
}

.serviceCardActionsRow {
    display: flex;
    justify-content: space-between;
}

.serviceCardButtonGroup {
    display: flex;
    flex-direction: column;
}

.serviceCardRemoveButton,
.serviceCardTasksButton,
.serviceCardEditButton {
    font-size: 40px !important;
    margin-right: 0 !important;    
    padding: 17px 20px !important;
}

.serviceCardTasksButton {
    color: #8BC334 !important;
    background-color: #f1f8e7 !important;
}

.serviceCardTasksButton:hover {
    color: #ffffff !important;
    background-color: #8BC334 !important;
}

.serviceCardEditButton {
    color: #388CEF !important;
    background-color: #e7f1fd !important;
}

.serviceCardEditButton:hover {
    color: #ffffff !important;
    background-color: #388CEF !important;
}

.serviceCardRemoveButton {
    color: #F56666 !important;
    background-color: #feeded !important;
}

.serviceCardRemoveButton:hover {
    color: #ffffff !important;
    background-color: #F56666 !important;
}

.serviceCardButtonText {
    margin-top: 1rem;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}

.somethingButton {
    margin-top: 1.5rem;
    padding: 1rem 0rem;
    border: none;
    cursor: pointer;
    color: black;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    border-radius: 5px;
    background: #E4E4E4;
}

.cancelledSubscription, .activeSubscription {
    display: flex;
    justify-content: center;
    padding: 0.75rem;
    font-weight: 500;
    line-height: 24px;
    font-size: 16px !important;
}

.activeSubscription {
    color: #8BC334 !important;
    background-color: #f8fbf3 !important;
}

.cancelledSubscription {
    color: #F56666 !important;
    background-color: #feeded !important;
}

@media screen and (min-width: 1100px) {
    .serviceCardTextGroup {
        display: flex;
        flex-direction: column;
        -ms-flex: 0 0 65%;
        flex: 0 0 65%;
        max-width: 65%;
    }
    .serviceCardActionsGroup {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        -ms-flex: 0 0 35%;
        flex: 0 0 35%;
        max-width: 35%;
    }
}

@media screen and (min-width: 1290px) {
    .cancelledSubscription, .activeSubscription {
        font-size: 20px !important;
    }
}