.container {
  position: relative;
  background: #eaeee8;
  height: auto;
}

.mainContent {
  position: relative;
  background: #ffffff;
  width: 734px;
  height: 752px;
  min-height: 752px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.06);
}

.welcomeText {
  position: absolute;
  top: 245px;
  /* left: 150px; */

  /* width: 583px; */
  /* height: 100px; */
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 39px;
  line-height: 50px;
  /* or 128% */

  text-align: center;
  letter-spacing: -1px;

  color: #272827;
}

.subText {
  position: absolute;
  top: 361px;
  left: 113.5px;
  width: 507px;
  height: 25px;

  /* Paragraph 16px */

  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  /* identical to box height, or 156% */

  text-align: center;

  color: #272828;
}

.buttonGroup {
  position: absolute;
  width: 674px;
  height: 204px;
  left: 30px;
  top: 419px;

  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 5px;
}

.buttonDivider {
  border-right: 1px solid #dbdbdb;
}

.headerClip {
  position: absolute;
  top: 64px;
  left: 223px;
}

.serviceButton {
  width: 122px;
  height: 122px;
  background-color: #e7f1fd;
}
.serviceButton:hover {
  color: #ffffff;
  background-color: #388cef;
}

.serviceButton:hover svg {
  fill: white;
}

.blueButton {
  width: 122px;
  height: 122px;
}

.serviceButton svg {
  fill: #388cef;
}

.serviceButton svg:hover {
  fill: white;
}

.serviceSelected {
  width: 122px;
  height: 122px;
  color: #ffffff;
  background-color: #388cef;
}

.serviceSelected svg {
  fill: white;
}

.serviceLabel {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #272828;
}

.nextButton {
  position: absolute;
  top: 645px;
  width: 275px;
  height: 65px;
  margin-left: 229px;
  padding: 1.3rem 4rem;
  background-color: #f1f8e7;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: #8bc334;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  text-align: center;
  outline: none;
}

.nextButton:hover {
  color: #ffffff;
  background-color: #8bc334;
}
