/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body,
button,
input,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Rubik", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.body {
  position: relative;
}

button:focus {
  outline: none !important;
}

.modal {
  border-radius: 0 !important;
}

@font-face {
  font-family: "Rubik";
  src: url("./static/fonts/Rubik-VariableFont_wght.ttf");
  font-weight: 100 900;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.ui.modal > .content {
  padding: 0 !important;
}

@media screen and (min-width: 768px) {
  body.loggedIn.noticePopup {
    margin-top: 205px !important;
  }
}

@media screen and (min-width: 1024px) {
  body.loggedIn.noticePopup {
    margin-top: 199px !important;
  }
}

@media screen and (min-width: 1366px) {
  body.loggedIn.noticePopup {
    margin-top: 152px !important;
  }
}

/* disabled pre flight for unrooted tags  */

.disabledPreflight h1 {
  display: block;
  font-size: 39px !important;
  margin-top: 0.67em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight h2 {
  display: block;
  font-size: 30px !important;
  margin-top: 0.83em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight h3 {
  display: block;
  font-size: 28px !important;
  margin-top: 1em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight h4 {
  display: block;
  font-size: 26px !important;
  margin-top: 1.33em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight h5 {
  display: block;
  font-size: 20px !important;
  margin-top: 1.67em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight h6 {
  display: block;
  font-size: 18px !important;
  margin-top: 2.33em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight p {
  display: block;
  font-size: 14px !important;
  margin-top: 1em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight blockquote {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}

.disabledPreflight dl {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight dd {
  display: block;
  margin-left: 40px;
}

.disabledPreflight hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

.disabledPreflight figure {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}

.disabledPreflight ol {
  display: block;
  font-size: 14px !important;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}

.disabledPreflight ul {
  display: block;
  font-size: 14px !important;
  list-style-type: disc;
  margin-top: 1em !important;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}

.disabledPreflight li {
  display: list-item;
}

.disabledPreflight img {
  display: inline-block;
}

.ant-popover-inner-content {
  padding: 0px !important;
}

.ant-popover-arrow {
  display: none !important;
}

.switch-op-times .ant-switch-checked {
  background: #8bc334 !important;
}

.switch-op-times .ant-switch {
  width: 85.16px !important;
  height: 40px !important;
}

.switch-op-times .ant-switch-handle {
  top: 4px;
  width: 32px !important;
  height: 32px !important;
  border-radius: 9999px;
  overflow: hidden;
}

.switch-op-times .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 32px - 2px);
}
