.mainContainer {
  max-width: 1366px;
  margin: 0 auto 4rem auto;
}

.resourcesContainer {
  padding: 0rem 4rem;
}

.backButtonContainer {
  text-align: right;
}

.backButtonText {
  width: 50px;
  height: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  align-self: flex-end;
  border-radius: 50%;
  background-color: #ffffff;
  outline: none;
}

.backButton {
  top: 13px;
  left: 13px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.backButtonText:hover > .backButton {
  color: #222222 !important;
}

.mainContainer span {
  display: block;
}

.chooseNQA,
.defaultRowHolder {
  display: flex;
  background-color: #ffffff;
  padding: 2rem 2rem 2rem 3rem;
  justify-content: space-between;
}

.defaultRowHolder {
  margin-bottom: 3.5rem;
  display: none;
}

.chooseNQA {
  display: none;
  margin-bottom: 3.6rem;
}

.newQIPUpdateText {
  display: flex;
  flex-direction: column;
}

.newQipVid {
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
}

.briefDesc {
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

.viewUpdateButton {
  padding: 1rem 3rem;
  border: none;
  cursor: pointer;
  color: #5226fe;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: #eae0ff;
  outline: none;
}

.viewUpdateButton:hover {
  color: #ffffff;
  background-color: #5226fe;
}

.userDashboardText {
  font-size: 39px;
  line-height: 50px;
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 10px;
}

.userDashboardAltText {
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
  margin-bottom: 3rem;
}

.havingProblemText {
  font-size: 30px;
  line-height: 36px;
  align-self: center;
  font-weight: normal;
}

.ArrowButton {
  font-size: 35px !important;
  color: #8bc334 !important;
  margin-right: 0 !important;
  background-color: #f1f8e7 !important;
}

.ArrowButton:hover {
  color: #ffffff !important;
  background-color: #8bc334 !important;
}

.nqaSelectionGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
}

.nqaStagesRow {
  display: flex;
  margin-top: -45px;
}

.nqaStageBubble {
  flex: 1;
  height: 120px;
  margin-right: 4px;
  position: relative;
  border-radius: 5px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  line-height: 36px;
  font-weight: normal;
}

.nqaStageBubble::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom: 0;
  margin-left: -20px;
  margin-bottom: -20px;
}

.nqaStageBubble:nth-child(1) {
  background: #61a447;
}

.nqaStageBubble:nth-child(1):hover {
  background: rgb(97 164 71 / 0.8);
}

.nqaStageBubble:nth-child(1)::after {
  border-top-color: #61a447;
}

.nqaStageBubble:nth-child(2) {
  background: #fbd539;
}

.nqaStageBubble:nth-child(2):hover {
  background: rgb(251 213 57 / 0.8);
}

.nqaStageBubble:nth-child(2)::after {
  border-top-color: #fbd539;
}

.nqaStageBubble:nth-child(3) {
  background: #e395a5;
}

.nqaStageBubble:nth-child(3):hover {
  background: rgb(227 149 165 / 0.8);
}

.nqaStageBubble:nth-child(3)::after {
  border-top-color: #e395a5;
}

.nqaStageBubble:nth-child(4) {
  background: #accf6b;
}

.nqaStageBubble:nth-child(4):hover {
  background: rgb(172 207 107 / 0.8);
}

.nqaStageBubble:nth-child(4)::after {
  border-top-color: #accf6b;
}

.nqaStageBubble:nth-child(5) {
  background: #f3a23e;
}

.nqaStageBubble:nth-child(5):hover {
  background: rgb(243 162 62 / 0.8);
}

.nqaStageBubble:nth-child(5)::after {
  border-top-color: #f3a23e;
}

.nqaStageBubble:nth-child(6) {
  background: #db308f;
}

.nqaStageBubble:nth-child(6):hover {
  background: rgb(219 48 143 / 0.8);
}

.nqaStageBubble:nth-child(6)::after {
  border-top-color: #db308f;
}

.nqaStageBubble:nth-child(7) {
  background: #3a96cf;
}

.nqaStageBubble:nth-child(7):hover {
  background: rgb(58 150 207 / 0.8);
}

.nqaStageBubble:nth-child(7)::after {
  border-top-color: #3a96cf;
}

.nqaLevelsRow {
  display: flex;
}

.nqaLevelBubble::before {
  content: "";
  padding-top: 100%;
  display: inline-block;
}

.nqaLevelBubble {
  flex: 1;
  margin-top: 24px;
  margin-right: 4px;
  margin-bottom: 4px;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  line-height: 36px;
  font-weight: normal;
}

.nqaLevelsRow:not(:nth-child(2)) {
  margin-top: -24px;
}

.nqaLevelsRow:nth-child(4) .nqaLevelBubble:not(:nth-child(1)) {
  visibility: visible;
}

.nqaLevelBubble:nth-child(1) {
  color: #61a447;
}

.nqaLevelBubble:nth-child(2) {
  color: #fbd539;
}

.nqaLevelBubble:nth-child(3) {
  color: #e395a5;
}

.nqaLevelBubble:nth-child(4) {
  color: #accf6b;
}

.nqaLevelBubble:nth-child(5) {
  color: #f3a23e;
}

.nqaLevelBubble:nth-child(6) {
  color: #db308f;
}

.nqaLevelBubble:nth-child(7) {
  color: #3a96cf;
}

.userDashboardGroupText {
  display: flex;
  flex-direction: column;
}

.navigationOptionsRow {
  display: flex;
}

.navigationOptionsRow div:last-child {
  border-left: 2px solid #eaeee8;
}

.navigationOptionsRow div:first-child {
  border-right: 2px solid #eaeee8;
}

.navigationOptionSelected,
.navigationOption {
  cursor: pointer;
  padding: 3rem 2rem;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
  /* -ms-flex: 0 0 33.33%; */
  /* flex: 0 0 33.33%; */
  /* max-width: 33.33%; */
  -ms-flex: 0 0 33.24%;
  flex: 0 0 33.24%;
  max-width: 33.24%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigationOptionSelected {
  margin-bottom: 0;
  background: #ffffff;
}

.middleNavigationOptionBorder {
  margin-right: 2px;
  margin-left: 2px;
}

.navigationOption {
  margin-bottom: 4px;
  color: #c9c9c9;
  background: #f7f8f6;
}

.navigationOption:hover {
  color: #222222;
  background: #ffffff;
}

.contentHolder {
  padding: 4rem 3rem;
  background: #ffffff;
}

.knowlegebaseContent {
  display: flex;
  flex-direction: column;
}

/* .availableDataRow { */
/* display: flex; */
/* flex-wrap: wrap; */
/* } */

/* .dataField { */
/* -ms-flex: 0 0 50%; */
/* flex: 0 0 50%; */
/* max-width: 50%; */
/* display: flex; */
/* align-items: center; */
/* cursor: pointer; */
/* } */

/* .dataField:hover { */
/* color: #5200ff; */
/* } */

.numberFont {
  font-size: 39px;
  line-height: 46px;
  text-align: center;
  font-weight: normal;
  letter-spacing: -1px;
  margin-right: 2rem;
}

.textFont {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
}

.searchBox {
  height: 80px;
  border-radius: 4px;
  font-size: 26px !important;
  line-height: 36px !important;
  font-weight: normal !important;
  color: #bebebe !important;
  display: block !important;
}

.searchBox input {
  border: 1px solid #bebebe !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  max-width: unset !important;
  width: 100%;
  color: #222222;
}

.searchBox::placeholder {
  color: #bebebe !important;
}

.latestWebinarsTextHeader {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  margin-top: 4rem;
}

.webinarContainer {
  color: #ffffff;
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2.5rem 2.8rem 2.5rem 2.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  /* -ms-flex: 0 0 48%;
  flex: 0 0 48%;*/
  width: 47.6%;
}

.webinarContainer:nth-child(odd) {
  margin-right: 1.2%;
}

.webinarContainer:nth-child(even) {
  margin-left: 1.2%;
}

.webinarContainer:not(:last-child) {
  margin-bottom: 1.5rem;
}

.webinarContainer:hover > .playButton {
  color: #333333 !important;
  background-color: #ffffff !important;
}

.webinarTextGroup {
  display: flex;
  flex-direction: column;
}

.webinarLabel {
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  margin-bottom: 8px;
}

.webinarHeaderTitle {
  font-size: 26px;
  line-height: 30px;
  font-weight: normal;
}

.playButton {
  color: #ffffff !important;
  margin-right: 0 !important;
  font-size: 21px !important;
  padding: 23px 20px 20px 24px !important;
  background-color: rgb(255 255 255 / 0.5) !important;
}

.tabContent text {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #272828;
}

.tabContent {
  margin-bottom: 4rem;
}

.subResourcesFilterSelected,
.subResourcesFilter,
.resourcesFilter,
.resourcesFilterSelected {
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  /* width: 13.38%; */
  width: 11.51%;
}

.subResourcesFilterSelected,
.subResourcesFilter {
  font-size: 12px;
  height: 40px;
}

.subResourcesFilterRow div:not(:last-child),
.resourcesFilterRow > div:not(:last-child) {
  margin-right: 1.056%;
}

.resourcesFilter,
.resourcesFilterSelected {
  font-size: 18px;
  height: 70px;
}

.subResourcesFilterRow.nqa_1 .subResourcesFilter {
  color: #61a447;
  background-color: #dfedda;
}

.subResourcesFilterRow.nqa_2 .subResourcesFilter {
  color: #fbd539;
  background-color: #fef7d7;
}

.subResourcesFilterRow.nqa_3 .subResourcesFilter {
  color: #e395a5;
  background-color: #f9eaed;
}

.subResourcesFilterRow.nqa_4 .subResourcesFilter {
  color: #accf6b;
  background-color: #eef5e1;
}

.subResourcesFilterRow.nqa_5 .subResourcesFilter {
  color: #f3a23e;
  background-color: #fdecd8;
}

.subResourcesFilterRow.nqa_6 .subResourcesFilter {
  color: #db308f;
  background-color: #f8d6e9;
}

.subResourcesFilterRow.nqa_7 .subResourcesFilter {
  color: #3a96cf;
  background-color: #d8eaf5;
}

.subResourcesFilterRow.nqa_1 .subResourcesFilter:hover,
.subResourcesFilterRow.nqa_1 .subResourcesFilterSelected {
  color: #ffffff;
  background-color: #61a447;
}

.subResourcesFilterRow.nqa_2 .subResourcesFilter:hover,
.subResourcesFilterRow.nqa_2 .subResourcesFilterSelected {
  color: #ffffff;
  background-color: #fbd539;
}

.subResourcesFilterRow.nqa_3 .subResourcesFilter:hover,
.subResourcesFilterRow.nqa_3 .subResourcesFilterSelected {
  color: #ffffff;
  background-color: #e395a5;
}

.subResourcesFilterRow.nqa_4 .subResourcesFilter:hover,
.subResourcesFilterRow.nqa_4 .subResourcesFilterSelected {
  color: #ffffff;
  background-color: #accf6b;
}

.subResourcesFilterRow.nqa_5 .subResourcesFilter:hover,
.subResourcesFilterRow.nqa_5 .subResourcesFilterSelected {
  color: #ffffff;
  background-color: #f3a23e;
}

.subResourcesFilterRow.nqa_6 .subResourcesFilter:hover,
.subResourcesFilterRow.nqa_6 .subResourcesFilterSelected {
  color: #ffffff;
  background-color: #db308f;
}

.subResourcesFilterRow.nqa_7 .subResourcesFilter:hover,
.subResourcesFilterRow.nqa_7 .subResourcesFilterSelected {
  color: #ffffff;
  background-color: #3a96cf;
}

.resourcesFilter {
  color: #222222;
  background-color: #f1f1f1;
}

.resourcesFilter:hover {
  background-color: #e5e5e5;
}

.resourcesFilterSelected {
  color: #ffffff;
}

.resourcesFilterRow .resourcesFilterSelected:nth-child(1) {
  background-color: #3393ff;
}

.resourcesFilterRow .resourcesFilterSelected:nth-child(2) {
  background-color: #61a447;
}

.resourcesFilterRow .resourcesFilterSelected:nth-child(3) {
  background-color: #fbd539;
}

.resourcesFilterRow .resourcesFilterSelected:nth-child(4) {
  background-color: #e395a5;
}

.resourcesFilterRow .resourcesFilterSelected:nth-child(5) {
  background-color: #accf6b;
}

.resourcesFilterRow .resourcesFilterSelected:nth-child(6) {
  background-color: #f3a23e;
}

.resourcesFilterRow .resourcesFilterSelected:nth-child(7) {
  background-color: #db308f;
}

.resourcesFilterRow .resourcesFilterSelected:nth-child(8) {
  background-color: #3a96cf;
}

.documentLists {
  border-bottom: 1px solid #e7e7e7;
}

.documentLists:first-child {
  border-top: 1px solid #e7e7e7;
}

/* .documentLists:nth-child() { */

/* } */

.fileContainer {
  display: flex;
  align-items: center;
}

.fileContainer text {
  display: block;
}

.filelogo {
  background-color: #e9e9e9;
  border-radius: 3px;
  margin-right: 1rem;
  width: 50px;
  height: 50px;
}

.filelogo svg {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  /* color: #FFFFFF; */
  color: #222222;
  vertical-align: middle;
}

.fileContent {
}

.filename {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
}

.fileType {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
}

.shopResourcesContent .shopResourcesContainer img {
  width: 100%;
}

.shopResourcesContent .shopResourcesContainer {
  margin-bottom: 20px;
  cursor: pointer;
}

.shopResourcesContent .shopResourcesContainer:hover {
  opacity: 0.8;
}

.shopResourcesContent .shopResourcesContainer:nth-child(odd) {
  padding-right: 10px;
}

.shopResourcesContent .shopResourcesContainer:nth-child(even) {
  padding-left: 10px;
}

.btnGroup button {
  font-size: 15px;
  line-height: 17px;
  padding: 15px;
  width: 110px;
}

.shopResourcesContent .pageButtonGroup {
  margin-top: 40px;
}

.pageButtonGroup {
  display: flex;
  justify-content: center;
}

.pageButtonStartEnd {
  font-size: 20px !important;
  margin-right: 10px !important;
  background-color: #f7f8f6 !important;
}

.pageButtonStartEnd:nth-last-child(1) {
  margin-right: 0 !important;
}

.pageButtonSelected,
.pageButton {
  padding: 18px 26px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
}

.pageButton {
  color: #222222;
  background: #f7f8f6;
}

.pageButton:hover {
  background: #e5e5e5;
}

.pageButtonSelected {
  color: #388cef;
  background: #e7f1fd;
}

@media screen and (min-width: 992px) {
  .contentHolder {
    padding: 4rem 5rem;
  }

  .navigationOptionSelected,
  .navigationOption {
    padding: 3rem;
    font-size: 30px;
  }

  .subResourcesFilterSelected,
  .subResourcesFilter {
    font-size: 14px;
  }

  .navigationOptionSelected,
  .navigationOption {
    padding: 3rem;
    font-size: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .resourcesFilter,
  .resourcesFilterSelected {
    font-size: 20px;
    height: 80px;
  }

  .subResourcesFilterSelected,
  .subResourcesFilter {
    font-size: 16px;
  }

  .nqaStageBubble {
    height: 160px;
    font-size: 30px;
  }

  .webinarContainer {
    /* -ms-flex: 0 0 48%;
    flex: 0 0 48%;
    max-width: 48%; */
    margin-bottom: 20px;
  }

  .webinarContainer:nth-child(odd) {
    margin-right: 1.2%;
  }

  .webinarContainer:nth-child(even) {
    margin-left: 1.2%;
  }
}

@media screen and (min-width: 1100px) {
  .resourcesContainer {
    padding: 0rem 8rem;
  }

  .webinarContainer:nth-child(odd) {
    margin-right: 1.2%;
  }

  .webinarContainer:nth-child(even) {
    margin-left: 1.2%;
  }

  .resourcesContainer {
    padding: 0rem 8rem;
  }

  .searchBox {
    font-size: 30px !important;
  }
}

@media screen and (min-width: 1200px) {
  .contentHolder {
    padding: 4.25rem 7.2rem;
  }

  .webinarHeaderTitle {
    font-size: 30px;
    line-height: 34px;
  }
}

@media screen and (min-width: 1250px) {
  /* .resourceFilter, */
  /* .resourceFilterSelected { */
  /* margin-right: 0; */
  /* margin-bottom: 0; */
  /* } */

  /* .resourceFilterRow { */
  /* justify-content: space-between;   */
  /* } */

  .resourceFilterRow {
    justify-content: space-between;
  }
}
