.mainContainer {
	max-width: 1366px;
	margin-right: auto;
	margin-left: auto;
}

.backButtonContainer {
	text-align: right;
}

.backButtonText {
    width: 50px;
    height: 50px;  
    position: relative;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    align-self: flex-end;
    border-radius: 50%;
    background-color: #ffffff;
	outline: none;
}

.backButton {
    top: 13px;
    left: 13px;
    width: 25px;
    height: 25px;
    position: absolute;
}

.backButtonText:hover > .backButton{
	color: #222222!important;
}

.content {
    padding: 0 4rem;
    margin-bottom: 2rem;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
}

.headerTextGroup {
    display: flex;
    flex-direction: column;
}

.headerText {
    font-size: 39px;
    line-height: 50px;
    font-weight: normal;
    letter-spacing: -1px;
    margin-bottom: 1rem;
}

.headerSubText {
    font-size: 20px;
    line-height: 32px;
    font-weight: normal;
}

.backToDashboardButton {
    height: 50px;
    padding: 0 3rem;
    border: none;
    cursor: pointer;
    color: #C9C9C9;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
    border-radius: 5px;
    text-decoration: none;
    background-color: #ffffff;
	outline: none;
}

.backToDashboardButton:hover {
	color: #222222;
}

.content {
    padding: 0 4rem;
}

.cardContainer {
    margin-top: 4rem;
}

.cardContainer .row {
    display: flex;
    flex-wrap: wrap;
}

.subscription {
    -ms-flex: 0 0 49.6%;
    flex: 0 0 49.6%;
    max-width: 50%;
    padding: 4rem 2rem;
    background-color: #ffffff;
}

.subscription:nth-child(-n+2){
    margin-bottom: 4px;
}

.subscription:nth-child(odd){
    margin-right: 2px;
}

.subscription:nth-child(even){
    margin-left: 2px;
}

.subscriptionHeader {
    font-weight: normal;
    font-size: 30px;
    line-height: 32px;
    min-height: 65px;
}

.text {
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    margin: 2rem 0;
    display: block;
}

.addNewservice {
    padding: 1.3rem 3rem;
    border: none;
    cursor: pointer;
    color: #8BC334;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
    border-radius: 5px;
    text-decoration: none;
    background-color: #f1f8e7;
    outline: none;
}

.addNewservice:hover {
    color: #ffffff;
    background-color: #8BC334;
}

.blueButton button,
.cancelSubscription,
.addNewservice {
    width: 100%;
}

@media screen and (min-width: 768px) {



}

@media screen and (min-width: 1100px) {

    .content {
        padding: 0 8rem;
    }

    .subscription {
        -ms-flex: 0 0 49.82%;
        flex: 0 0 49.6%;
        max-width: 50%;
        padding: 4rem;
    }

}