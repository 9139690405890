.spinnerDiv {
  margin-top: 30vh;
  text-align: center;
}

.inputName {
  font-size: 20px;
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
}

.inputBox {
  height: 60px;
  max-width: unset;
  padding: 1.25rem 1rem;
  margin-top: 0.5rem;
  border-radius: 3px;
  border: 1px solid #000000;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
}

.payButton {
  color: #388cef;
  background-color: #e7f1fd;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border: none;
  border-radius: 5px;
  padding: 1.3rem 3rem;
  cursor: pointer;
  outline: none;
}

.payButton:hover {
  color: #ffffff;
  background-color: #388cef;
  cursor: pointer;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.paymentConfirmationModal {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 3rem 3rem;
}

.modalHeaderText {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  align-self: center;
  font-weight: normal;
  margin-bottom: 2rem;
}

.blueButton {
  margin-top: 1rem;
  align-self: center;
}

.cardInput {
  width: 365px;
}

.inputCCName {
  font-family: sans-serif !important;
  color: #272828;
  font-weight: 400;
  font-size: 20px;
  height: 2.75em;
  line-height: 1.2em;
}

.warning {
  color: #f56666;
}

.disclaimerText {
  display: block;
  color: #272828;
  font-style: italic !important;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  margin-top: 3rem;
}
