@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "index.css";
@import "style.css";
@import "antd/dist/antd.css";
@import "semantic-ui-css/semantic.min.css";

* {
  outline: none !important;
}
