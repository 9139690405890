.footer {
  position: absolute;
  bottom: -85px;
  width: 100%;
  background-color: #222222;
}

.footer .row {
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  padding: 12px 0;
  text-align: center;
}

.left {
  padding: 0 10px;
  margin-bottom: 1rem;
}

.right {
  padding: 0 10px;
}

.footerNav {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  background-color: unset;
  border: none;
  cursor: pointer;
  padding: 0 1rem;
}

.footerNav:hover {
  color: rgba(255, 255, 255, 0.5);
}

.copyright {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.buttonLogout {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 5px;
  border: none;
  padding: 1.3rem 3rem;
  margin-left: 2rem;
}

.buttonLogout:hover {
  background: rgba(255, 255, 255, 0.1);
}

@media screen and (min-width: 1100px) {
  .footer .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    text-align: unset;
    height: 85px;
  }

  .left {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .copyright {
    margin-bottom: 1rem;
    display: block;
  }
}
