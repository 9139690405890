.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
}

.backButtonContainer {
  text-align: right;
}

.backButtonText {
  width: 50px;
  height: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  align-self: flex-end;
  border-radius: 50%;
  background-color: #ffffff;
  outline: none;
}

.backButtonTextMessage {
  height: 50px;
  width: 263px;
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  align-self: flex-end;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #c9c9c9;
  border-radius: 5px;
  background-color: #ffffff;
  outline: none;
}

.backButtonTextMessage:hover {
  color: #222222 !important;
}

.backButton {
  top: 13px;
  left: 13px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.backButtonText:hover > .backButton {
  color: #222222 !important;
}

.content,
.cardContent {
  padding: 0 4rem;
}

.headerText {
  font-size: 39px;
  line-height: 50px;
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 1rem;
}

.headerSubText {
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
}

.cardContainer {
  display: flex;
  margin-top: 4rem;
  flex-direction: column;
}

.navigationOptionsRow {
  display: flex;
}

.navigationOption,
.navigationOptionSelected {
  cursor: pointer;
  padding: 3rem 2rem;
  margin-bottom: 0;
  flex: 0.5;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
  background: white;
  color: black;
}

.navigationOption {
  color: #c9c9c9;
  border-bottom: 4px solid #eaeee8;
  background: #f7f8f6;
}

.navigationOptionSelected {
  border-bottom: 4px solid #ffffff;
}

.navigationOption:nth-child(1) {
  margin-right: 4px;
}

.navigationOption:nth-child(2) {
  margin-left: 4px;
}

.navigationOptionsRow div:nth-child(1) {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-left: 4rem;
}

.navigationOptionsRow .navigationOptionsText {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.navigationOptionsRow .navigationOption,
.navigationOptionsRow .navigationOptionSelected {
  width: 100%;
}

.messageRight {
  border-left: 1px solid #e7e7e7;
  padding-left: 1rem;
}

.messageLeft {
  padding-right: 1rem;
}

.messageCounterGroup {
  display: flex;
}

.numberOfMessages {
  font-size: 30px;
  line-height: 36px;
  font-weight: normal;
  padding-right: 0.5rem;
}

.messagesText {
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
}

.blueButton {
  margin-top: 2rem;
  align-self: center;
}

.modalContainer {
  display: flex;
  padding: 2rem 4rem 3rem;
  flex-direction: column;
}

.modalHeaderText {
  margin-top: 1rem;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
}

.cardContent {
  /* background-color: white; */
}

.messagesInfoRow {
  background-color: white;
}

@media screen and (min-width: 992px) {
  .navigationOption,
  .navigationOptionSelected {
    padding: 3rem;
    font-size: 30px;
  }

  .numberOfMessages {
    padding-right: 1rem;
  }
}

@media screen and (min-width: 1100px) {
  .content,
  .cardContent {
    padding: 0 8rem;
  }
}

@media screen and (min-width: 1257px) {
  .navigationOptionsRow *:nth-child(1) .messageCounterGroup {
    border-left: 1px solid #e7e7e7;
  }

  .messageRight {
    padding-left: 2rem;
  }

  .messageLeft {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .navigationOptionsRow .navigationOptionsText {
    -ms-flex: unset;
    flex: unset;
    max-width: unset;
    margin-bottom: unset;
    padding-right: 2rem;
  }

  .navigationOptionsRow div:nth-child(1) {
    justify-content: flex-start;
  }
}

.messagesInfoRow {
  padding-left: 4rem;

  height: 127px;
  width: 100%;
}

.inboxHeader {
  font-style: normal;
  font-size: 30px;
  font-weight: normal;
  line-height: 36px;
  color: #272828;
}

.inboxHeaderCount {
  font-style: normal;
  font-size: 30px;
  font-weight: normal;
  line-height: 36px;
  color: #272828;
}

.unreadHeaderCount {
  font-style: normal;
  font-size: 30px;
  font-weight: normal;
  line-height: 36px;
  color: #272828;
}

.unreadHeader {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #272828;
}

.messagesInfo {
  /* margin-right: 50px; */
}
