.mainContainer {
    max-width: 1366px;  
	margin-right: auto;
	margin-left: auto; 
	padding: 0 4rem;
}

.headerTextContainer h1 {
    font-size: 39px;
    line-height: 50px;
    letter-spacing: -1px;
    color: #272827;
}

.headerTextContainer text {
    font-size: 20px;
    line-height: 32px;
    color: #272828;
}

.formContainer {
	background-color: #ffffff;
}

.formRow:not(:first-child) {
	margin-top: 1.5rem;
}

.formRow > div textarea {
	height: 200px;
}

.formRow > text,
.formRow > div text {
    font-size: 20px;
    line-height: 28px;
    color: #272828;
	display: block;
}

.formRow > div text {
	margin-bottom: .3rem;
}

.formRow > div,
.formRow > div input {
    margin-top: 0;
}

.formRow > .dropdownGroup > div {
	display: flex !important;
}

.formRow > div input,
.formRow > .dropdownGroup > div {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
	color: #272828;
	height: 60px;
    padding: 0.5rem 1rem !important;
    border-radius: 3px !important;
    border: 1px solid #000000 !important;
}

.formRow.w_half > div {
	width: 49%;
}

.formRow.w_full > div {
	width: 100%;
}

.formText {
    font-size: 20px;
    line-height: 32px;
}

.uploadPlaceholder {
    border: 1px dashed #000000;
    box-sizing: border-box;
    border-radius: 3px;
    display: block;
    padding: 50px;
    margin-bottom: 2.5rem;
    text-align: center;
    cursor: pointer;
	margin-top: .3rem !important;
}

.uploadPlaceholder text {
	font-weight: 500;
    color: #979797 !important;
    background: #E7E7E7;
    border-radius: 5px;
    padding: 20px 30px;
    display: inline-block !important;
    cursor: pointer;	
}

@media screen and (min-width: 1100px) {

	.mainContainer {
		padding: 0 8rem;
	}
	
}
