.modalContainer {
  background: #ffffff;
  box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.06);
}

.headerText {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;

  color: #272828;
}

.headerSub {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  /* identical to box height, or 173% */

  text-align: center;

  color: #272828;
}

.continueButton {
  width: 147px;
  height: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #388cef;
  background: rgba(56, 140, 239, 0.1);

  border-radius: 5px;
}

.continueButton:hover {
  background: #388cef;
  color: #ffffff;
}

.closeButton {
  width: 50px;
  height: 50px;
  font-style: normal;
  font-weight: 900;
  font-size: 23px;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
  color: #f56666;
  background: rgba(245, 102, 102, 0.12);
}

.closeButton:hover {
  background: rgba(245, 102, 102, 0.2);
}
