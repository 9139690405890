.modalContainer {
  display: flex;
  padding: 4rem 8rem;
  flex-direction: column;
}

.closeButton {
  top: 0;
  right: 0;
  position: absolute;
  margin-right: 0 !important;
  color: #f56666 !important;
  border-radius: none !important;
  background-color: #feeded !important;
}

.closeButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.text {
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 3rem;
}

.buttonGroup {
  display: flex;
  align-self: center;
}

.nextTipButton {
  border: none;
  margin-left: 1rem;
  border-radius: 5px;
  padding: 1rem 3rem;
  color: #8bc334;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  background-color: #f1f8e7;
  outline: none;
}

.nextTipButton:hover {
  color: #ffffff;
  background-color: #8bc334;
}
