.mainContainer {
	max-width: 1366px; 
    margin: 0 auto 4rem auto;
}

.resourcesContainer {
    padding: 0rem 4rem;  
}

.backButtonContainer {
    text-align: right;
}

.backButtonText {
    width: 50px;
    height: 50px;
    position: relative;
    border: none;
    cursor: pointer;  
    margin-top: 1rem;
    margin-right: 1rem;
    align-self: flex-end;
    border-radius: 50%;
    background-color: #ffffff;
    outline: none;
}

.backButton {
    top: 13px;
    left: 13px;
    width: 25px;
    height: 25px;
    position: absolute;
}

.backButtonText:hover>.backButton {
    color: #222222!important;
}

.mainContainer span {
    display: block;
}

/* .backToResourcesButtonContainer { */
    /* margin-top: 3.9rem; */
    /* text-align: right; */
/* } */

.webinarsResourceHeader {
    display: flex;
    justify-content: space-between;
}

.headerTextGroup {
    display: flex;
    flex-direction: column;
}

.userDashboardText {
    font-size: 39px;
    line-height: 50px;
    font-weight: normal;
    letter-spacing: -1px;
    margin-bottom: 10px;
}

.userDashboardAltText {
    font-size: 20px;
    line-height: 32px;
    font-weight: normal;
	margin-bottom: 3rem;
}

.backToDashboardButton {
    height: 50px;
    padding: 1rem 2.8rem;
    border: none;
    cursor: pointer;
    color: #C9C9C9;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    background-color: #FFFFFF;
	outline: none;
}

.backToDashboardButton:hover {
	color: #222222;
}

.navigations {
    margin-top: 4.5rem;
    display: flex;
    flex-direction: column;
}

.navigationOptionsRow {
    display: flex;
}

.navigationOptionsRow div:last-child {
    border-left: 2px solid #eaeee8;
}

.navigationOptionsRow div:first-child {
    border-right: 2px solid #eaeee8;
}

.navigationOptionSelected,
.navigationOption {
    cursor: pointer;
    padding: 3rem 2rem;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    font-weight: normal;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;    
	display: flex;
	justify-content: center;
	align-items: center;
}

.navigationOptionSelected {
    margin-bottom: 0;
    background: #ffffff;
}

.middleNavigationOptionBorder {
	margin-right: 2px;
	margin-left: 2px;
}

.navigationOption {
    margin-bottom: 4px;
    color: #C9C9C9;
    background: #f7f8f6;
}

.navigationOption:hover {
    color: #222222;
    background: #ffffff;
}

/* .middleNavigationOptionBorder { */
    /* border-right: 4px solid #eaeee8; */
    /* border-left: 4px solid #eaeee8; */
/* } */

.contentHolder {
    padding: 3.95rem;
    background: #ffffff;
}

.knowlegebaseContent {
    display: flex;
    flex-direction: column;
}

.webinarContainer:hover > .playButton{
	color: #333333 !important;	
	background-color: #ffffff !important;	
}

.webinarContainer {
    color: #ffffff;
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 3.6rem 5rem 4.3rem 3.6rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../../static/images/webinarBox1.png');
}

.webinarTextGroup {
    display: flex;
    flex-direction: column;
}

.webinarLabel {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 8px;
}

.webinarHeaderTitle {
    font-size: 45px;
    line-height: 50px;
    font-weight: normal;
    letter-spacing: -1px;
}

.playButton {
    color: #ffffff !important;
    margin-right: 0 !important;
    font-size: 30px !important;
    padding: 33px 30px 30px 34px !important;
    background-color: rgb(255 255 255 / .5)!important;
}

.webinarDescriptionRow {
    display: flex;
    margin-top: 4.5rem;
}

.webinarDescriptionTextColumn {
    flex: 0.60;
    padding: 0 4rem 0 0;
    display: flex;
    flex-direction: column;
}

.webinarDescriptionHeader {
    font-size: 30px;
    line-height: 36px;
    font-weight: normal;
    margin-bottom: 2rem;
}

.webinarDescriptionBody {
    font-size: 15px;
    font-weight: 300;
    line-height: 26px;
}

.webinarDetailsColumn {
    flex: 0.4;
    padding: 0 0 0 2rem;
    border-left: 1px solid #E7E7E7;
    display: flex;
    flex-direction: column;
}

.webinarDetailLabel {
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
}

.webinarDetailText {
    font-size: 20px;
    line-height: 28px;
    font-weight: normal;
    margin-bottom: 1rem;
}

.nqaTags {
    font-size: 20px;
    line-height: 28px;
    padding: 10px 16px;
    margin-right: 12px;
    font-weight: normal;
}

.nqa1 {
    color: #61A447;
    background: #dfedda;
}

.nqa7 {
    color: #3A96CF;
    background: #d8eaf5;
}

.similarWebinarsGroup {
    display: flex;
	display: none;
    flex-direction: column;
    padding: 2.1rem 3.95rem 3.95rem;
    margin-top: 4px;
    background: #ffffff;
}

.similarWebinarsHeader {
    font-size: 20px;
    line-height: 28px;
    font-weight: normal;
    margin-bottom: 2.5rem;
}

.similarWebinarsRow {
    display: flex;
    flex-wrap: wrap;
}

.similarWebinarContainer:hover > .smallerPlayButton {
	color: #333333 !important;	
	background-color: #ffffff !important;	
}

.similarWebinarContainer {
    color: #ffffff;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2.5rem 2.8rem 2.5rem 2.5rem;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.similarWebinarTitle {
    font-size: 26px;
    line-height: 30px;
    font-weight: normal;
}

.similarWebinarContainer:nth-child(1) {
    margin-bottom: 1.5rem;
    margin-right: 0;
    background-image: url('../../../static/images/webinarBox1.png');
}

.similarWebinarContainer:nth-child(2) {
    background-image: url('../../../static/images/webinarBox3.png');
}

.smallerPlayButton {
    color: #ffffff !important;
    margin-right: 0 !important;
    font-size: 21px !important;
    padding: 23px 20px 20px 24px !important;
    background-color: rgb(255 255 255 / .5)!important;
}

.chooseNQA {
    display: flex;
    display: none;
    background-color: #ffffff;
    padding: 2rem 2rem 2rem 3rem;
    justify-content: space-between;
    margin-bottom: 3.6rem;
}

.havingProblemText {
    font-size: 30px;
    line-height: 36px;
    align-self: center;
    font-weight: normal;
}

.ArrowButton {
    font-size: 35px !important;
    color: #8bc334 !important;
    margin-right: 0 !important;
    background-color: #f1f8e7 !important;
}

.ArrowButton:hover {
    color: #ffffff !important;
    background-color: #8bc334 !important;
}

.nqaSelectionGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
}

.nqaStagesRow {
    display: flex;
    margin-top: -45px;
}

.nqaStageBubble {
    flex: 1;
    height: 120px;
    margin-right: 4px;
    position: relative;
    border-radius: 5px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    line-height: 36px;
    font-weight: normal;
}

.nqaStageBubble::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom: 0;
    margin-left: -20px;
    margin-bottom: -20px;
}

.nqaStageBubble:nth-child(1) {
    background: #61A447;
}

.nqaStageBubble:nth-child(1):hover {
    background: rgb(97 164 71 / .8);
}

.nqaStageBubble:nth-child(1)::after {
    border-top-color: #61A447;
}

.nqaStageBubble:nth-child(2) {
    background: #FBD539;
}

.nqaStageBubble:nth-child(2):hover {
    background: rgb(251 213 57 / .8);
}

.nqaStageBubble:nth-child(2)::after {
    border-top-color: #FBD539;
}

.nqaStageBubble:nth-child(3) {
    background: #E395A5;
}

.nqaStageBubble:nth-child(3):hover {
    background: rgb(227 149 165 / .8);
}

.nqaStageBubble:nth-child(3)::after {
    border-top-color: #E395A5;
}

.nqaStageBubble:nth-child(4) {
    background: #ACCF6B;
}

.nqaStageBubble:nth-child(4):hover {
    background: rgb(172 207 107 / .8);
}

.nqaStageBubble:nth-child(4)::after {
    border-top-color: #ACCF6B;
}

.nqaStageBubble:nth-child(5) {
    background: #F3A23E;
}

.nqaStageBubble:nth-child(5):hover {
    background: rgb(243 162 62 / .8);
}

.nqaStageBubble:nth-child(5)::after {
    border-top-color: #F3A23E;
}

.nqaStageBubble:nth-child(6) {
    background: #DB308F;
}

.nqaStageBubble:nth-child(6):hover {
    background: rgb(219 48 143 / .8);
}

.nqaStageBubble:nth-child(6)::after {
    border-top-color: #DB308F;
}

.nqaStageBubble:nth-child(7) {
    background: #3A96CF;
}

.nqaStageBubble:nth-child(7):hover {
    background: rgb(58 150 207 / .8);
}

.nqaStageBubble:nth-child(7)::after {
    border-top-color: #3A96CF;
}

.nqaLevelsRow {
    display: flex;
}

.nqaLevelBubble::before {
    content: '';
    padding-top: 100%;
    display: inline-block;
}

.nqaLevelBubble {
    flex: 1;
    margin-top: 24px;
    margin-right: 4px;
    margin-bottom: 4px;
    border-radius: 50%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    line-height: 36px;
    font-weight: normal;
}

.nqaLevelsRow:not(:nth-child(2)) {
    margin-top: -24px;
}

.nqaLevelsRow:nth-child(4) .nqaLevelBubble:not(:nth-child(1)) {
    visibility: hidden;
}

.nqaLevelBubble:nth-child(1) {
    color: #61A447;
}

.nqaLevelBubble:nth-child(2) {
    color: #FBD539;
}

.nqaLevelBubble:nth-child(3) {
    color: #E395A5;
}

.nqaLevelBubble:nth-child(4) {
    color: #ACCF6B;
}

.nqaLevelBubble:nth-child(5) {
    color: #F3A23E;
}

.nqaLevelBubble:nth-child(6) {
    color: #DB308F;
}

.nqaLevelBubble:nth-child(7) {
    color: #3A96CF;
}

.shopResourcesContainer .shopResourcesImg img {
	width: 100%;
}

.shopResourcesContainer .shopResourcesImg {
	margin-bottom: 75px;
}

.shopResourcesContainer .shopResourcesContent h2 {
	font-size: 30px;
	line-height: 36px;
	color: #272828;
	margin-bottom: 1rem;
}

.shopResourcesContainer .shopResourcesContent li,
.shopResourcesContainer .shopResourcesContent span {
	font-size: 15px;
	line-height: 26px;
	color: #272828;
}

.shopResourcesContainer .shopResourcesContent .prize { 
	font-size: 20px;
	line-height: 28px;
	color: #000000;
}

.shopResourcesContainer .shopResourcesContent ul {
    list-style: disc;
	margin-top: 40px;
	margin-bottom: 40px;
	margin-left: 20px;
}

.shopResourcesContainer .shopResourcesContent .viewButton {
	margin-top: 35px;
}

.shopResourcesContainer .shopResourcesContent .viewButton button {
	width: 255px;
}

.resourcesPagination {
	border-top: 1px solid #E7E7E7;
	padding-top: 25px;
	margin-top: 100px;
}

.resourcesPagination button {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 19px !important;
	color: #979797 !important;
	background-color: unset !important;
	margin-right: 0 !important;
	padding: unset !important;
}

@media screen and (max-width: 820px) {
    
    .headerTextGroup  {
        max-width: 380px;
    }

}

@media screen and (min-width: 992px) {
    
    .navigationOptionSelected,
    .navigationOption {
        padding: 3rem;
        font-size: 30px;
    }

}

@media screen and (min-width: 1024px) {
    
    .webinarContainer {
        height: 620px;
    }    
    
    .similarWebinarContainer {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 48.92%;
    }

    .similarWebinarContainer:nth-child(1) {
        margin-right: 2.16%;
        margin-bottom: 0;
    } 
    
    .webinarHeaderTitle {
        font-size: 59px;
        line-height: 64px;
    }
    
}

@media screen and (min-width: 1100px) {

    .resourcesContainer {
        padding: 0rem 8rem;
    }

}

@media screen and (min-width: 1200px) {
    
    .similarWebinarTitle {
        font-size: 30px;
        line-height: 34px;
    }  
    
}