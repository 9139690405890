.modalContainer {
  display: flex;
  padding: 3rem 4rem;
  flex-direction: column;
}

.closeButton {
  top: 0;
  right: 0;
  position: absolute;
  margin-right: 0 !important;
  color: #f56666 !important;
  background-color: #feeded !important;
}

.closeButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.headerText {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
  margin-bottom: 3rem;
}

.nqaGroup {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.nqaGroup > div {
  display: flex;
  margin-bottom: 20px;
}

.nqaOption,
.nqaSelectedOption {
  width: 120px;
  color: #e4e4e4;
  cursor: pointer;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
  padding: 1.5rem 0;
  border-radius: 5px;
  border: 3px solid #e4e4e4;
  background-color: white;
}

.nqaSelectedOption {
  border: none;
  color: #ffffff;
}

.nqaGroup button:not(:last-child) {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.nqaSelectedOption:first-child {
  color: #61a447;
  background-color: #dfedda;
  background-clip: padding-box;
  border: 3px solid #dfedda;
}

.nqaGroup button.nqaOption:first-child:hover {
  color: #61a447;
  background-color: #dfedda;
  background-clip: padding-box;
  border: 3px solid #dfedda;
}

.nqaSelectedOption:nth-child(2) {
  color: #fbd539;
  background-color: #fef7d7;
  background-clip: padding-box;
  border: 3px solid #fef7d7;
}

.nqaGroup button.nqaOption:nth-child(2):hover {
  color: #fbd539;
  background-color: #fef7d7;
  background-clip: padding-box;
  border: 3px solid #fef7d7;
}

.nqaSelectedOption:nth-child(3) {
  color: #e395a5;
  background-color: #e395a533;
  background-clip: padding-box;
  border: 3px solid #e395a533;
}

.nqaGroup button.nqaOption:nth-child(3):hover {
  color: #e395a5;
  background-color: #e395a533;
  background-clip: padding-box;
  border: 3px solid #e395a533;
}

.nqaSelectedOption:nth-child(4) {
  color: #accf6b;
  background-color: #eef5e1;
  background-clip: padding-box;
  border: 3px solid #eef5e1;
}

.nqaGroup button.nqaOption:nth-child(4):hover {
  color: #accf6b;
  background-color: #eef5e1;
  background-clip: padding-box;
  border: 3px solid #eef5e1;
}

.nqaSelectedOption:nth-child(5) {
  color: #f3a23e;
  background-color: #fdecd8;
  background-clip: padding-box;
  border: 3px solid #fdecd8;
}

.nqaGroup button.nqaOption:nth-child(5):hover {
  color: #f3a23e;
  background-color: #fdecd8;
  background-clip: padding-box;
  border: 3px solid #fdecd8;
}

.nqaSelectedOption:nth-child(6) {
  color: #db308f;
  background-color: #db308f33;
  background-clip: padding-box;
  border: 3px solid #db308f33;
}

.nqaGroup button.nqaOption:nth-child(6):hover {
  color: #db308f;
  background-color: #db308f33;
  background-clip: padding-box;
  border: 3px solid #db308f33;
}

.nqaSelectedOption:nth-child(7) {
  color: #3a96cf;
  background-color: #d8eaf5;
  background-clip: padding-box;
  border: 3px solid #d8eaf5;
}

.nqaGroup button.nqaOption:nth-child(7):hover {
  color: #3a96cf;
  background-color: #d8eaf5;
  background-clip: padding-box;
  border: 3px solid #d8eaf5;
}

.blueButton {
  margin-top: 2.5rem;
  align-self: center;
}

.clearSection {
  text-align: center;
  margin-top: 2.5rem;
}

.clearSectionButton {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #f56666;
  background: unset;
  border: unset;
  outline: none;
  cursor: pointer;
  /* display: none; */
}

@media screen and (min-width: 930px) {
  .nqaGroup button:not(:last-child) {
    margin-bottom: 0;
  }

  .nqaGroup button:not(:nth-last-child(-n + 3)) {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 1200px) {
  .modalContainer {
    padding: 3rem 6rem;
  }
}
