.alertContainer {
  top: 0;
  width: 100%;
  z-index: 9;
}

.alert {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #272828;
  padding: 15px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}

.alert img {
  margin-right: 10px;
}

.alert span {
  color: #f44336;
}

.closebtn {
  /* margin-left: 15px; */
  /* color: white; */
  /* font-weight: bold; */
  /* float: right; */
  /* font-size: 22px; */
  /* line-height: 20px; */
  /* cursor: pointer; */
  /* transition: 0.3s; */
}

.closebtn:hover {
  /* color: black; */
}

.warningIcon {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  color: #f56666;
  /* background: #f56666; */
}

@media screen and (min-width: 1024px) {
  .alert {
    line-height: 32px;
    max-width: unset;
    text-align: unset;
    margin: unset;
  }
}

@media screen and (max-width: 767px) {
  .alertContainer {
    display: none;
  }
}
