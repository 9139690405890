.modalContainer {
    display: flex;
    padding: 2rem 4rem;
    flex-direction: column;
}

.closeButton {
    top: 0;
    right: 0;
    position: absolute;
    margin-right: 0 !important;
    color: #F56666 !important;
    border-radius: 0!important;
    background-color: #feeded !important;
}  

.closeButton:hover {
    color: #ffffff !important;	
    background-color: #F56666 !important;	
}

.headerText {
    margin: 1rem 0 2rem;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    font-weight: normal;
}

.buttonGroup {
    display: flex;
    align-self: center;
}

.nextTipButton {
    border: none;
    margin-right: 1rem;
    border-radius: 5px;
    padding: 1rem 3rem;
    color: #8BC334;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    background-color: #f1f8e7;
	outline: none;
}

.nextTipButton:hover {
    color: #ffffff;	
    background-color: #8BC334;	
}