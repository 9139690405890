.createReportContainer {
  background: #ffffff;
}

.descriptionText {
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
}

.reportTypeRow {
  display: flex;
  padding: 4rem 0 2.5rem;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 2.5rem;
}

.reportTypeColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  -ms-flex: 0 0 32%;
  flex: 0 0 32%;
  max-width: 32%;
}
.reportTypeColumn:not(:last-child) {
  margin-right: 2%;
}

.reportTypeSelected,
.reportType {
  font-size: 50px !important;
  line-height: 57px !important;
  padding: 12px 15px !important;
  margin-right: 0 !important;
  border-radius: 5px !important;
}

.reportType {
  color: #e4e4e4 !important;
  background-color: #ffffff !important;
  border: 3px solid #e4e4e4 !important;
}

.reportType:hover {
  color: #8bc334 !important;
}

.reportTypeSelected {
  color: #8bc334 !important;
  border: 3px solid #f1f8e7 !important;
  background-clip: padding-box;
  background-color: #f1f8e7 !important;
}

.reportTypeText {
  margin-top: 1rem;
  font-size: 16px;
  line-height: 31px;
  font-weight: normal;
  display: inline-block;
}

.nqaReportFilterHeader {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  margin-bottom: 2rem;
}

.nqaReportFilterRow {
  /* display: flex; */
  /* flex-wrap: wrap; */
  margin-bottom: 4.5rem;
  /* justify-content: flex-start; */
}

.nqaReportFilterRow button {
  width: 13%;
  height: 80px;
}

/* .nqaReportFilterRow button:not(:nth-last-child(-n+3)) { */
/* margin-bottom: 1rem; */
/* } */

/* .nqaReportFilterRow button:not(:nth-child(4)) { */
/* margin-right: 2.6%; */
/* } */

.nqaReportFilterSelected,
.nqaReportFilter {
  border-radius: 5px;
  cursor: pointer;
  /* padding: 19px 15px; */
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
  outline: none;
}

.nqaReportFilter {
  color: #e4e4e4;
  background-color: #ffffff;
  border: 3px solid #e4e4e4;
}

.nqaReportFilterSelected {
  border: none;
}

/* .nqaReportFilter,  */
/* .nqaReportFilterSelected { */
/* font-size: 30px; */
/* } */

.nqaReportFilterSelected:nth-child(1) {
  color: #61a447;
  background-color: #61a44733;
  background-clip: padding-box;
  border: 3px solid #61a44733;
}

.nqaReportFilter:nth-child(1):hover {
  color: #61a447;
}

.nqaReportFilterSelected:nth-child(2) {
  color: #fbd539;
  background-color: #fbd53933;
  background-clip: padding-box;
  border: 3px solid #fbd53933;
}

.nqaReportFilter:nth-child(2):hover {
  color: #fbd539;
}

.nqaReportFilterSelected:nth-child(3) {
  color: #e395a5;
  background-color: #e395a533;
  background-clip: padding-box;
  border: 3px solid #e395a533;
}

.nqaReportFilter:nth-child(3):hover {
  color: #e395a5;
}

.nqaReportFilterSelected:nth-child(4) {
  color: #accf6b;
  background-color: #accf6b33;
  background-clip: padding-box;
  border: 3px solid #accf6b33;
}

.nqaReportFilter:nth-child(4):hover {
  color: #accf6b;
}

.nqaReportFilterSelected:nth-child(5) {
  color: #f3a23e;
  background-color: #f3a23e33;
  background-clip: padding-box;
  border: 3px solid #f3a23e33;
}

.nqaReportFilter:nth-child(5):hover {
  color: #f3a23e;
}

.nqaReportFilterSelected:nth-child(6) {
  color: #db308f;
  background-color: #db308f33;
  background-clip: padding-box;
  border: 3px solid #db308f33;
}

.nqaReportFilter:nth-child(6):hover {
  color: #db308f;
}

.nqaReportFilterSelected:nth-child(7) {
  color: #3a96cf;
  background-color: #3a96cf33;
  background-clip: padding-box;
  border: 3px solid #3a96cf33;
}

.nqaReportFilter:nth-child(7):hover {
  color: #3a96cf;
}

.searchFilterRow {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.25rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e7e7e7;
}

.searchInput {
  margin-bottom: 1.5rem;
  font-size: 30px !important;
  line-height: 36px !important;
  font-weight: normal !important;
  color: #bebebe !important;
}

.searchInput input {
  border: 1px solid #bebebe !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
}

.searchInput input:focus {
  color: #bebebe !important;
}

.searchInput::placeholder {
  color: #bebebe !important;
}

.reportName > div {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.dropdownBox {
  height: 60px;
  padding: 1.25rem 1rem !important;
  font-size: 20px !important;
  margin-top: 0.5rem !important;
  border-radius: 5px !important;
  border: 1px solid black !important;
  max-width: unset !important;
}

@media screen and (min-width: 992px) {
  .createReportContainer {
    padding: 4rem 8rem 6.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .reportTypeText {
    font-size: 22px;
  }

  .nqaReportFilterSelected,
  .nqaReportFilter {
    font-size: 28px;
  }
}

@media screen and (min-width: 1100px) {
  .nqaReportFilterSelected,
  .nqaReportFilter {
    font-size: 30px;
  }

  .reportTypeText {
    font-size: 26px;
  }
}

@media screen and (min-width: 1280px) {
  .reportTypeText {
    font-size: 26px;
  }
}

@media screen and (min-width: 1280px) {
  .nqaReportFilterRow {
    justify-content: space-between;
  }

  /* .nqaReportFilterRow { */
  /* justify-content: space-between; */
  /* } */

  /* .nqaReportFilterRow button { */
  /* width: unset; */
  /* height: unset; */
  /* } */

  /* .nqaReportFilterRow button:not(:nth-last-child(-n+3)) { */
  /* margin-bottom: unset; */
  /* } */

  /* .nqaReportFilterRow button:not(:nth-child(4)) { */
  /* margin-right: unset; */
  /* } */
}
