.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 4rem;
}

.backButtonContainer {
  text-align: right;
}

.backButtonText {
  width: 50px;
  height: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  align-self: flex-end;
  border-radius: 50%;
  background-color: #ffffff;
}

.backButton {
  top: 13px;
  left: 13px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.backButtonText:hover > .backButton {
  color: #222222 !important;
}

.content {
  padding: 0 4rem;
  margin-bottom: 2rem;
}

.headerText {
  font-size: 39px;
  line-height: 46px;
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 1rem;
}

.headerSubText {
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
  margin-bottom: 4rem;
  display: block;
}

.servicesRow {
  display: flex;
  cursor: pointer;
  padding: 1rem 2rem;
  margin-bottom: 4px;
  justify-content: space-between;
  background-color: #f7f8f6;
  font-size: 18px;
  line-height: 32px;
  font-weight: normal;
  border-radius: 6px;
  display: none;
}

.activeServiceRow {
  background-color: #ffffff;
}

.servicesRow text {
  width: 22%;
}

.servicesRow > *:nth-child(2) {
  width: 60%;
  margin: 0 2%;
}

.servicesRow > *:nth-child(3) {
  width: 16%;
  text-align: right;
}

.inProgress {
  color: #388cef;
  margin-right: 8px;
}

.completed {
  color: #8bc334;
  margin: 0 8px;
}

.notStarted {
  color: #f56666;
  margin: 0 8px;
}

.serviceButton {
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  text-decoration-line: underline;
}

.activeServiceButton {
  color: #8bc334;
}

.serviceButtonIcon {
  font-size: 12px !important;
  margin-left: 1rem !important;
  margin-right: 0 !important;
}

.activeServiceButtonIcon {
  color: #ffffff !important;
  background-color: #8bc334 !important;
}

.serviceHeaderText {
  font-size: 39px;
  line-height: 46px;
  font-weight: normal;
  letter-spacing: -1px;
  margin: 3.5rem 0 4rem;
  display: none;
}

.searchFilterContainer {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  background-color: #ffffff;
}

.searchFilterRow,
.searchFilterButtonGroup {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.searchFilterRow > div > * {
  cursor: pointer;
}

.searchFilterRow > div:not(:last-child) {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.searchFilterButtonGroup button:nth-child(3),
.searchFilterButtonGroup button:nth-child(4) {
  display: none;
}

.searchFilterButtonGroup:nth-child(2) {
  margin-top: 2rem;
}

.searchFilterButtonGroup button:nth-child(odd) {
  margin-right: 1%;
}

/* .searchFilterButtonGroup button:nth-child(1), */
/* .searchFilterButtonGroup button:nth-child(2) { */
/* margin-bottom: .5rem; */
/* } */

.searchFilterDropdownText {
  color: #bebebe;
  font-size: 24px;
  line-height: 36px;
  font-weight: normal;
  margin-right: 8px;
}

.caretDownIcon {
  color: #bebebe !important;
  font-size: 24px !important;
}

.searchBox {
  height: 80px;
  border-radius: 4px;
  font-size: 24px !important;
  line-height: 36px !important;
  font-weight: normal !important;
  color: #bebebe !important;
  margin-top: 1rem;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.searchBox input {
  border: 1px solid #bebebe !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
}

.searchBox input:focus {
  color: #bebebe !important;
}

.searchBox::placeholder {
  color: #bebebe !important;
}

.taskFilterButton,
.selectedTaskFilterButton {
  height: 80px;
  border: none;
  border-radius: 5px;
  /* padding: 1rem 3rem; */
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
  outline: none;
  -ms-flex: 0 0 49.5%;
  flex: 0 0 49.5%;
  max-width: 49.5%;
}

.taskFilterButton {
  color: #222222;
  background-color: rgb(228 228 228 / 0.5);
}

.selectedTaskFilterButton {
  color: #ffffff;
  background-color: #8bc334;
}

.taskFilterButton:hover {
  color: #8bc334;
  background-color: #f1f8e7;
}

.cardContainer {
  display: flex;
  margin-top: 12px;
  padding: 4rem 2.5rem;
  flex-direction: column;
  background-color: #ffffff;
}

.taskHeadersRow {
  display: flex;
  font-size: 18px !important;
  font-weight: 500;
  line-height: 16px;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e7e7e7;
}

.taskHeadersRow > *:first-child {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 2rem;
}

.taskHeadersRow > *:nth-child(2) {
  -ms-flex: 0 0 12%;
  flex: 0 0 12%;
  max-width: 12%;
}

.taskHeadersRow > *:nth-child(3) {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.taskHeadersRow > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.taskHeadersRow > *:last-child {
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  max-width: 15%;
}

.taskHeadersRow div * {
  cursor: pointer;
}

.taskDataRow {
  display: flex;
  padding: 1rem 0;
  font-size: 15px;
  line-height: 24px;
  align-items: center;
  font-weight: normal;
  border-bottom: 1px solid #e7e7e7;
}

.taskDataRow > *:first-child {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 2rem;
}

.taskDataRow > *:nth-child(2) {
  -ms-flex: 0 0 12%;
  flex: 0 0 12%;
  max-width: 12%;
  padding-right: 1rem;
}

.taskDataRow > *:nth-child(3) {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 1rem;
}

.taskDataRow > *:nth-child(4) {
  padding-right: 1rem;
}

.taskDataRow > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.taskDataRow > *:last-child {
  -ms-flex: 0 0 16%;
  flex: 0 0 16%;
  max-width: 16%;
  padding-right: 1rem;
}

.statusInProgress {
  color: #388cef;
}

.statusCompleted {
  color: #8bc334;
}

.statusNotStarted {
  color: #f56666;
}

.taskOpenButton {
  border: none;
  border-radius: 5px;
  padding: 1.3rem 1.5rem;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  background-color: rgb(228 228 228 / 0.5);
  outline: none;
  margin-top: 8px;
}

.taskOpenButton:hover {
  background-color: #e5e5e5;
}

.pageButtonGroup {
  display: flex;
  margin-top: 4rem;
  justify-content: center;
}

.pageButtonStartEnd {
  font-size: 20px !important;
  margin-right: 10px !important;
  background-color: #f7f8f6 !important;
}

.pageButtonStartEnd:nth-last-child(1) {
  margin-right: 0 !important;
}

.pageButtonSelected,
.pageButton {
  padding: 18px 26px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
}

.pageButton {
  color: #222222;
  background: #f7f8f6;
}

.pageButton:hover {
  background: #e5e5e5;
}

.pageButtonSelected {
  color: #388cef;
  background: #e7f1fd;
}

@media screen and (min-width: 992px) {
  .servicesRow {
    align-items: center;
  }

  .taskHeadersRow {
    font-size: 20px;
  }

  .taskDataRow {
    font-size: 14px;
  }

  .taskOpenButton {
    padding: 1rem 1.5rem;
    font-size: 18px;
  }
}

@media screen and (min-width: 1024px) {
  .searchBox {
    margin-top: 0;
    -ms-flex: 0 0 36%;
    flex: 0 0 36%;
    max-width: 36%;
  }

  .searchFilterRow > div:not(:last-child) {
    -ms-flex: 0 0 32%;
    flex: 0 0 32%;
    max-width: 32%;
  }

  .taskDataRow {
    font-size: 16px;
  }

  .taskFilterButton,
  .selectedTaskFilterButton {
    -ms-flex: 0 0 24.5%;
    flex: 0 0 24.5%;
    max-width: 24.5%;
  }
}

@media screen and (min-width: 1100px) {
  .content {
    padding: 0 8rem;
    margin-bottom: 2rem;
  }

  .servicesRow {
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .searchFilterButtonGroup button:nth-child(1),
  .searchFilterButtonGroup button:nth-child(2) {
    margin-bottom: 0;
  }

  .searchFilterButtonGroup button:nth-child(odd) {
    margin-right: 0;
  }

  .searchFilterButtonGroup button:not(:last-child) {
    margin-right: 0.66%;
  }

  .taskHeadersRow > *:first-child {
    padding-right: 3rem;
  }

  .taskHeadersRow > *:nth-child(2) {
    -ms-flex: 0 0 12%;
    flex: 0 0 12%;
    max-width: 12%;
  }

  .taskHeadersRow > *:nth-child(3) {
    -ms-flex: 0 0 16%;
    flex: 0 0 16%;
    max-width: 16%;
  }

  .taskHeadersRow > * {
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
  }

  .taskHeadersRow > *:last-child {
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }

  .statusCompleted,
  .statusNotStarted,
  .statusInProgress {
    margin-right: 8px;
  }

  .taskOpenButton {
    margin-top: 0;
  }

  .taskDataRow > *:first-child {
    padding-right: 3rem;
  }

  .taskDataRow > *:nth-child(2) {
    -ms-flex: 0 0 12%;
    flex: 0 0 12%;
    max-width: 12%;
  }

  .taskDataRow > *:nth-child(3) {
    -ms-flex: 0 0 16%;
    flex: 0 0 16%;
    max-width: 16%;
  }

  .taskDataRow > *:nth-child(4) {
    padding-right: 1rem;
  }

  .taskDataRow > * {
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
  }

  .taskDataRow > *:last-child {
    -ms-flex: 1;
    flex: 1;
    max-width: 30%;
  }

  .taskDataRow > *:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1250px) {
  .searchBox {
    font-size: 24px !important;
  }

  .searchFilterDropdownText {
    font-size: 24px;
  }
}

@media screen and (min-width: 1300px) {
  .searchBox {
    max-width: 355px;
  }
}
