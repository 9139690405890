.headerContainer {
  background-color: #222222;
  top: 0;
  width: 100%;
  z-index: 9;
  position: sticky;
}

.headerRow {
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*********************/
.mobileAdminHeader {
  display: none !important;
}
/*********************/

/*----------Admin----------*/

.adminHeader .headerRow {
  max-width: 1366px;
}

.adminHeader .navigationIconContainer {
  color: #7a7a7a;
  border-left: 1px solid #3d3d3d;
}

.adminHeader .navigationIconContainer:last-child {
  border-right: 1px solid #3d3d3d;
}

.adminHeader .navigationIconContainer.active {
  color: #ffffff;
  background-color: #3a3a3a;
}

.adminHeader .helpContainer .helpButton {
  background: #3a3a3a;
}
.adminHeader .helpContainer {
  border-left: unset;
}
.adminHeader .headerCenter {
  border-right: unset;
}

/*----------End----------*/

.logoContainer {
  padding: 10px 1rem;
  border: none;
  cursor: pointer;
  background: #222222;
  outline: none !important;
  border-left: 1px solid #4e4e4e;
}

.headerCenterEmpty {
  outline: none !important;
}

.headerCenter,
.headerCenterEmpty {
  flex-grow: 1;
  border-right: 1px solid #4e4e4e;
}

.headerCenter {
  display: flex;
}

.navigationIconContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 16px 1rem;
  border: none;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  border-left: 1px solid #4e4e4e;
  outline: none !important;
}

.navigationIconContainer:hover {
  color: #ffffff;
  background-color: #434343;
}

/* Messages */
.desktopHeader .navigationIconContainer:nth-child(5),
.mobileHeader .navigationIconContainer:nth-child(5) {
  /* display: none; */
}

.navigationIconContainer.active {
  background-color: #434343;
}

.navigationIconText {
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  margin-top: 8px;
}

.mobileHeader .serviceNavigationIconContainer {
  padding: 0.4rem 1.5rem;
  border-left: unset;
}

.serviceNavigationIconContainer {
  display: flex;
  align-items: center;
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 0 1.5rem;
  background: #222222;
  border-left: 1px solid #4e4e4e;
  outline: none !important;
  flex-grow: 1;
}

.serviceNavigationIconContainer:hover {
  color: #ffffff;
  background-color: #434343;
}

.serviceNavigationText {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 12px;
  text-align: left;
  text-transform: capitalize;
}

.helpContainer {
  display: flex;
  padding: 15px 10px;
  align-items: center;
  border-left: 1px solid #4e4e4e;
  border-right: 1px solid #4e4e4e;
}

.helpButton {
  width: 110px;
  height: 60px;
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  border-radius: 5px;
  background-color: #8bc334;
}

.helpButton:hover {
  background-color: #97d439;
}

.detailsFinishedWrapper {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.serviceDetailsWrapper {
  flex-grow: 1;
  max-width: 206px;
  padding: 1rem;
  border-left: 1px solid #4e4e4e;
  display: none;
}

.serviceDetailsWrapper .detailsFinishedText {
  width: unset;
}

.detailsFinishedContent {
  display: flex;
  margin: 0 0 8px;
}

.detailsFinishedPercentageContainer {
  display: flex;
}

.detailsFinishedPercentage {
  color: #ffffff;
  font-size: 35px;
  line-height: 41px;
  font-weight: 300;
  text-transform: capitalize;
  position: relative;
}

.detailsFinishedPercentSymbol {
  color: #ffffff;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  text-transform: capitalize;
}

.detailsFinishedText {
  width: 60px;
  margin-left: 6px;
  align-self: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  text-transform: capitalize;
}

.progressBar {
  width: 100%;
  height: 15px;
  overflow: hidden;
  background-color: #535353;
}

.progressBarInside {
  height: 100%;
  float: left;
  position: relative;
  background-color: #388cef;
}

.logoutContainer {
  height: 100%;
  display: flex;
  align-items: center !important;
  padding: 0 0.5rem;
  cursor: pointer;
  border-right: 1px solid #4e4e4e;
}

.logoutContainer:hover {
  background-color: #434343 !important;
}

.logoutIcon {
  padding-bottom: 0 4px 0 4px !important;
  margin-top: -10px !important;
  align-self: center;
  font-size: 28px !important;
  color: #7a7a7a !important;
}

@media only screen and (max-width: 1365px) {
  /*----------Admin----------*/

  .desktopAdminHeader {
    display: none;
  }

  .adminHeader .headerBottom {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  .adminHeader .logoContainer {
    padding: 5px;
  }

  .adminHeader .logoutContainer {
    padding: 0 12px;
  }

  .adminHeader .helpContainer {
    padding: 5px 12px 5px 0;
  }

  .adminHeader .logoutContainer i {
    font-size: 28px !important;
  }

  .adminHeader .navigationIconContainer:first-child,
  .adminHeader .logoContainer {
    border-left: unset;
  }

  .adminHeader .navigationIconContainer:last-child,
  .adminHeader .logoutContainer {
    border-right: unset;
  }

  /*----------End----------*/

  .desktopHeader {
    display: none;
  }
  .mobileHeader .headerRow {
    flex-wrap: wrap;
  }
  .mobileHeader .headerTop {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #4e4e4e;
  }
  .mobileHeader .headerBottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mobileHeader .headerRight {
    display: flex;
  }
  .mobileHeader .headerLeft {
  }
  .mobileHeader .detailsFinishedContent {
    margin: unset;
  }
  .mobileHeader .helpButton {
    height: 30px;
    width: 55px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 2px;
  }
  .mobileHeader .progressBar {
    height: 10px;
    margin: unset;
  }
  .mobileHeader .detailsFinishedText {
    width: unset;
    margin-bottom: 5px;
    display: block;
  }
  .mobileHeader .headerLeft .headerCenter {
    border-right: unset;
  }
  .mobileHeader .detailsFinishedTextContainer {
    margin-top: 4px;
  }
  .mobileHeader .headerBottom .headerCenter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .detailsFinishedWrapper {
    border-left: 1px solid #4e4e4e;
  }

  .serviceDetailsWrapper {
    padding: 1.35rem;
  }
}

@media only screen and (max-width: 1023px) {
  /*----------Admin----------*/
  .adminHeader .navigationIconText {
    font-size: 14px;
    line-height: 16px;
  }

  .adminHeader .logoContainer img {
    width: 180px;
  }

  .adminHeader .helpContainer {
    padding: 8px 12px 8px 0;
  }

  .adminHeader .helpContainer {
    padding: 8px 12px 8px 0;
  }

  .adminHeader .helpButton {
    font-size: 14px;
    line-height: 17px;
    width: 77px;
    height: 38px;
  }

  .adminHeader .logoutContainer i {
    font-size: 24px !important;
  }
}

@media only screen and (max-width: 767px) {
  .mobileAdminHeader .headerBottom,
  .mobileAdminHeader .helpContainer,
  .mobileHeader .headerTop,
  .mobileHeader .headerBottom .headerCenter {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .mobileHeader .serviceNavigationIconContainer svg {
    width: 40px;
  }
  .mobileHeader .helpContainer {
    padding: 0px 8px;
  }

  .noticePopup.headerContainer {
    top: 68px;
  }
}

@media only screen and (min-width: 840px) {
  .mobileHeader .logoContainer img {
    max-width: unset;
  }
}

@media only screen and (min-width: 1024px) {
  .mobileHeader .helpContainer {
    padding: 0px 10px;
  }

  .noticePopup.headerContainer {
    top: 62px;
  }
}

@media only screen and (min-width: 1366px) {
  .desktopAdminHeader {
    display: none;
  }
  .mobileAdminHeader {
    display: none;
  }
  .mobileHeader {
    display: none;
  }
  .logoutContainer {
    padding: 36px 0.5rem;
  }
  .detailsFinishedWrapper {
    display: unset;
    align-items: unset;
    flex-grow: 1;
    max-width: 160px;
  }
}

.disableNavbar {
  pointer-events: none;
}
