.mainContainer {
  background: #ffffff;
}

.header {
  font-style: normal;
  font-weight: normal;
  font-size: 39px;
  line-height: 50px;
  /* identical to box height, or 128% */

  text-align: center;
  letter-spacing: -1px;

  color: #272827;
}

.subHeader {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  text-align: center;

  color: #272828;
}

.buttonRedirect {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
  background: #8bc334;
  border-radius: 5px;
}
