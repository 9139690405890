.modalContainer {
  display: flex;
  padding: 2rem 8rem 4rem;
  flex-direction: column;
}

.closeButton {
  top: 0;
  right: 0;
  position: absolute;
  margin-right: 0 !important;
  color: #f56666 !important;
  border-radius: 0 !important;
  background-color: #feeded !important;
}

.closeButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.headerText {
  margin-top: 1rem;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
  margin-bottom: 3rem;
}

.dropdownRow {
  display: flex;
}

.dropdownRow > div:first-child {
  margin-right: 20px;
}

.dropdownGroup {
  flex: 0.5;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

.dropdownText {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
}

.inputText {
  font-size: 20px;
  font-weight: 400;
}

.dropdownBox,
.dropdownBoxStatus {
  height: 60px;
  font-size: 20px !important;
  margin-top: 0.5rem !important;
  border-radius: 5px !important;
  padding: 1.25rem 1.25rem !important;
  border: 1px solid black !important;
}

.datePickerGroup {
  position: relative;
}

.datePickerBox {
  height: 60px;
  width: 100%;
  padding: 1rem;
  font-size: 20px;
  margin-top: 0.5rem;
  border-radius: 3px;
  border: 1px solid black;
}

.datePickerIcon {
  top: 24px;
  right: 0.5rem;
  position: absolute;
  background-color: white;
  font-size: 20px !important;
}

.summaryTextBox {
  height: 200px;
}

.statusSelect1,
.statusSelect2,
.statusSelect3 {
  height: 60px;
  cursor: pointer;
  padding: 1rem 2rem;
  margin-top: 0.5rem;
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  border-radius: 3px;
  text-align: center;
  color: white;
}

.statusSelect1 > text {
  margin-left: 2rem;
}

.statusSelect1 > *:last-child {
  float: right;
  font-size: 24px !important;
}

.statusSelect1 {
  background-color: #f56666;
}

.statusSelect2 {
  background-color: #388cef;
}

.statusSelect3 {
  background-color: #8bc334;
}

.blueButton {
  align-self: center;
}

.removeTask {
  text-align: center;
  margin-top: 1.8rem;
}

.removeTaskButton {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #f56666;
  background: unset;
  border: unset;
  outline: none;
  cursor: pointer;
  /* display: none; */
}

.actionButtonGroup {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

.questionButton {
  color: #8bc334;
  background-color: #f1f8e7;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border: none;
  border-radius: 5px;
  padding: 1.3rem 3rem;
  cursor: pointer;
  outline: none;
  margin-left: 1rem;
  /* display: none; */
}

.questionButton:hover {
  color: #f1f8e7;
  background-color: #8bc334;
}

.nqaArea {
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 3rem;
  padding: 1.5rem 0;
  /* display: none; */
}

.nqaAreaRow {
  display: flex;
}

.nqaText {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-right: 2rem;
}

.nqaText.nqa1 {
  color: #61a447;
}
.nqaText.nqa2 {
  color: #fbd539;
}
.nqaText.nqa3 {
  color: #e395a5;
}
.nqaText.nqa4 {
  color: #accf6b;
}
.nqaText.nqa5 {
  color: #f3a23e;
}
.nqaText.nqa6 {
  color: #db308f;
}
.nqaText.nqa7 {
  color: #3a96cf;
}

.nqaAnswer strong {
  font-weight: 500;
}

.nqaAnswer {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #272828;
}
