.mainContainer {
  /* border-bottom: 1px solid #e4e4e4 !important; */
}

.headerNQAText {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  /* identical to box height */
  text-align: center;

  color: #272827;
}

.nqaContainer {
  border-bottom: 1px solid #e4e4e4 !important;
}

.resourceFilter,
.resourceFilterSelected {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  /* max-width: 135px; */
  /* width: 135px; */
  width: 14.28%;
  height: 7rem;
}

.resourceFilterRow .resourceFilter:first-child,
.resourceFilterRow .resourceFilterSelected:first-child {
  margin-right: 0.25rem;
}

.resourceFilterRow .resourceFilter:last-child,
.resourceFilterRow .resourceFilterSelected:last-child {
  margin-left: 0.25rem;
}

.resourceFilterRow .resourceFilter:not(:first-child):not(:last-child),
.resourceFilterRow .resourceFilterSelected:not(:first-child):not(:last-child) {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.subResourcesFilterRow {
  margin-top: 0.5rem;
}

@media screen and (min-width: 1100px) {
  .resourceFilter,
  .resourceFilterSelected {
    height: 8rem;
  }
}

.resourceFilter1,
.resourceFilterSelected1 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #222222;
  cursor: pointer;
  border-radius: 6px;
  width: 5.7rem;
  height: 4.67rem;
}

.resourceFilter1,
.resourceFilter {
  color: #222222;
  background-color: #f2f2f2;
}

.resourceFilterSelected1,
.resourceFilterSelected {
  color: #ffffff;
}

.resourceFilter1:hover,
.resourceFilter:hover {
  background-color: #e5e5e5;
}

/* .resourceFilterRow > div:not(:last-child) {
  margin-right: 1.056%;
} */

/* .resourceFilterRow {
  padding-left: 1.82rem;
  padding-right: 1.82rem;
} */

.resourceFilterRow .resourceFilterSelected:nth-child(1),
.resourceFilterRow .resourceFilterSelected1:nth-child(1) {
  background-color: #61a447;
}

.resourceFilterRow .resourceFilterSelected:nth-child(2),
.resourceFilterRow .resourceFilterSelected1:nth-child(2) {
  background-color: #fbd539;
}

.resourceFilterRow .resourceFilterSelected:nth-child(3),
.resourceFilterRow .resourceFilterSelected1:nth-child(3) {
  background-color: #e395a5;
}

.resourceFilterRow .resourceFilterSelected:nth-child(4),
.resourceFilterRow .resourceFilterSelected1:nth-child(4) {
  background-color: #accf6b;
}

.resourceFilterRow .resourceFilterSelected:nth-child(5),
.resourceFilterRow .resourceFilterSelected1:nth-child(5) {
  background-color: #f3a23e;
}

.resourceFilterRow .resourceFilterSelected:nth-child(6),
.resourceFilterRow .resourceFilterSelected1:nth-child(6) {
  background-color: #db308f;
}

.resourceFilterRow .resourceFilterSelected:nth-child(7),
.resourceFilterRow .resourceFilterSelected1:nth-child(7) {
  background-color: #3a96cf;
}

/* .resourceFilterRow .resourceFilter1:nth-child(1):hover {
  color: #ffffff;
  background-color: #61a447;
}

.resourceFilterRow .resourceFilter1:nth-child(2):hover {
  color: #ffffff;
  background-color: #fbd539;
}

.resourceFilterRow .resourceFilter1:nth-child(3):hover {
  color: #ffffff;
  background-color: #e395a5;
}

.resourceFilterRow .resourceFilter1:nth-child(4):hover {
  color: #ffffff;
  background-color: #accf6b;
}

.resourceFilterRow .resourceFilter1:nth-child(5):hover {
  color: #ffffff;
  background-color: #f3a23e;
}

.resourceFilterRow .resourceFilter1:nth-child(6):hover {
  color: #ffffff;
  background-color: #db308f;
}

.resourceFilterRow .resourceFilter1:nth-child(7):hover {
  color: #ffffff;
  background-color: #3a96cf;
} */

.resourceFilterRow .resourceFilter1_nqa1 {
  color: #ffffff;
  background-color: #61a447;
}

.resourceFilterRow .resourceFilter1_nqa2 {
  color: #ffffff;
  background-color: #fbd539;
}

.resourceFilterRow .resourceFilter1_nqa3 {
  color: #ffffff;
  background-color: #e395a5;
}

.resourceFilterRow .resourceFilter1_nqa4 {
  color: #ffffff;
  background-color: #accf6b;
}

.resourceFilterRow .resourceFilter1_nqa5 {
  color: #ffffff;
  background-color: #f3a23e;
}

.resourceFilterRow .resourceFilter1_nqa6 {
  color: #ffffff;
  background-color: #db308f;
}

.resourceFilterRow .resourceFilter1_nqa7 {
  color: #ffffff;
  background-color: #3a96cf;
}

.subResourcesFilterRow.nqa_1 .subResourcesFilter,
.subResourcesFilterRow.nqa_1 .subResourcesFilterSelector {
  background-color: #61a447;
  color: #ffffff;
  /* background-color: #dfedda; */
}

.subResourcesFilterRow.nqa_2 .subResourcesFilter,
.subResourcesFilterRow.nqa_2 .subResourcesFilterSelector {
  background-color: #fbd539;
  color: #ffffff;
  /* background-color: #fef7d7; */
}

.subResourcesFilterRow.nqa_3 .subResourcesFilter,
.subResourcesFilterRow.nqa_3 .subResourcesFilterSelector {
  background-color: #e395a5;
  color: #ffffff;
  /* background-color: #f9eaed; */
}

.subResourcesFilterRow.nqa_4 .subResourcesFilter,
.subResourcesFilterRow.nqa_4 .subResourcesFilterSelector {
  background-color: #accf6b;
  color: #ffffff;
  /* background-color: #eef5e1; */
}

.subResourcesFilterRow.nqa_5 .subResourcesFilter,
.subResourcesFilterRow.nqa_5 .subResourcesFilterSelector {
  background-color: #f3a23e;
  color: #ffffff;
  /* background-color: #fdecd8; */
}

.subResourcesFilterRow.nqa_6 .subResourcesFilter,
.subResourcesFilterRow.nqa_6 .subResourcesFilterSelector {
  background-color: #db308f;
  color: #ffffff;
  /* background-color: #f8d6e9; */
}

.subResourcesFilterRow.nqa_7 .subResourcesFilter,
.subResourcesFilterRow.nqa_7 .subResourcesFilterSelector {
  background-color: #3a96cf;
  color: #ffffff;
  /* background-color: #d8eaf5; */
}

.subResourcesFilterRow.nqa_1 .subResourcesFilter:hover,
.subResourcesFilterRow.nqa_1 .subResourcesFilterSelected {
  /* color: #ffffff; */
  /* background-color: #61a447; */
  opacity: 0.8;
}

.subResourcesFilterRow.nqa_2 .subResourcesFilter:hover,
.subResourcesFilterRow.nqa_2 .subResourcesFilterSelected {
  /* color: #ffffff;
  background-color: #fbd539; */
  opacity: 0.8;
}

.subResourcesFilterRow.nqa_3 .subResourcesFilter:hover,
.subResourcesFilterRow.nqa_3 .subResourcesFilterSelected {
  /* color: #ffffff;
  background-color: #e395a5; */
  opacity: 0.8;
}

.subResourcesFilterRow.nqa_4 .subResourcesFilter:hover,
.subResourcesFilterRow.nqa_4 .subResourcesFilterSelected {
  /* color: #ffffff;
  background-color: #accf6b; */
  opacity: 0.8;
}

.subResourcesFilterRow.nqa_5 .subResourcesFilter:hover,
.subResourcesFilterRow.nqa_5 .subResourcesFilterSelected {
  /* color: #ffffff;
  background-color: #f3a23e; */
  opacity: 0.8;
}

.subResourcesFilterRow.nqa_6 .subResourcesFilter:hover,
.subResourcesFilterRow.nqa_6 .subResourcesFilterSelected {
  /* color: #ffffff;
  background-color: #db308f; */
  opacity: 0.8;
}

.subResourcesFilterRow.nqa_7 .subResourcesFilter:hover,
.subResourcesFilterRow.nqa_7 .subResourcesFilterSelected {
  /* color: #ffffff;
  background-color: #3a96cf; */
  opacity: 0.8;
}

/* .subResourcesFilterRow {
  border-bottom: 1px solid #e4e4e4;
  padding-left: 1.82rem;
  padding-right: 1.82rem;
} */

.subResourcesFilterSelected,
.subResourcesFilter {
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.subResourcesFilterSelected,
.subResourcesFilter {
  font-size: 16px;
  height: 50px;
}

.subResourcesFilterRow div:only-child {
  border-radius: 5px 5px 5px 5px !important;
}

.subResourcesFilterRow div:last-child {
  border-radius: 0px 0px 5px 5px;
}

.subResourcesFilterRow div:first-child {
  border-radius: 5px 5px 0px 0px;
}

.subResourcesFilterRow div:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.subResourcesFilterSelector {
  font-size: 16px;
  height: 50px;
  font-weight: 400;
  text-align: center;
  width: 100%;
}

.subDropdown {
  width: 35px;
  height: 35px;
  color: #ffffff;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 23px;
  cursor: pointer;
  border-radius: 9999px !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.subDropdown:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.noQuestionsAdded {
  padding-left: 1.82rem;
  padding-right: 1.82rem;
}

.noQuestionsAddedIcon {
  width: 60px;
  height: 60px;
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 69px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -1px;

  color: #388cef;
}

.noQuestionsHeader {
  font-style: normal;
  font-weight: normal;
  font-size: 39px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -1px;

  color: #272827;
}

.noQuestionsText {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  text-align: center;

  color: #272828;
}

.footerHow {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  /* or 173% */

  text-align: center;

  color: #272828;
  padding-left: 1.82rem;
  padding-right: 1.82rem;
}

.headerHow,
.headerText {
  width: 700px;
  display: block;
}

.nqaSubSatHeader {
  font-style: normal;
  font-weight: normal;
  font-size: 39px;
  line-height: 46px;
  letter-spacing: -1px;
  color: #272827;
}

.nqaSatSub {
  font-style: normal;
  font-weight: normal;
  font-size: 39px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -1px;
  color: #272828;
}

.nqaSatText {
  width: 80%;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;

  color: #272828;
}

.nqaSatQuestionsHeader {
  padding-top: 54px;
  padding-bottom: 54px;
  padding-left: 1.82rem;
  padding-right: 1.82rem;
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
}

.gotoReportButton {
  width: 164px;
  height: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #ffffff;

  background: #388cef;
  border-radius: 5px;
}
.gotoReportButton:hover {
  background: rgba(56, 140, 239, 0.9);
}

.satQuestionNumber {
  width: 48px !important;
  height: 48px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  /* or 130% */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: #ffffff;

  /* background: #388cef; */
  border-radius: 3px;
}

.satQuestionDesc {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;

  color: #272828;
}

.satQuestionDescription {
  width: 100%;
  height: 200px;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  padding: 1rem;
  /* color: #272828; */
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  resize: none;
}

.satCharCount {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;

  color: #272828;
}

.satCharText {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;

  color: #272828;
}

.satCopyButton {
  background: #f1f8e7;
  font-style: normal;
  font-weight: 500;
  text-align: center;

  color: #8bc334;
  border-radius: 5px;
}

.satStrengthButton {
  background: rgba(56, 140, 239, 0.12);
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  text-align: center;

  color: #388cef;
}

.satRemoveButton {
  background: rgba(245, 102, 102, 0.12);
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;

  text-align: center;

  color: #f56666;
}

@media screen and (min-width: 940px) {
  .satCopyButton {
    width: 247px;
    height: 60px;

    font-size: 20px;
    line-height: 24px;
  }
  .satStrengthButton {
    width: 210px;
    height: 60px;

    font-size: 20px;
    line-height: 24px;
  }
  .satRemoveButton {
    width: 138px;
    height: 60px;
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 940px) {
  .satCopyButton {
    width: 205px;
    height: 60px;
    font-size: 18px;
    line-height: 20px;
  }
  .satStrengthButton {
    width: 195px;
    height: 60px;

    font-size: 18px;
    line-height: 20px;
  }
  .satRemoveButton {
    width: 115px;
    height: 60px;
    font-size: 18px;
    line-height: 20px;
  }
}

.satCopyButton:hover {
  background: #8bc334;
  color: #ffffff;
}

.satStrengthButton:hover {
  background: #388cef;
  color: #ffffff;
}

.satRemoveButton:hover {
  background: #f56666;
  color: #ffffff;
}

.satQuestionsContainer .satQuestionContainer:not(:last-child) {
  /* padding-left: 4rem;
  padding-right: 4rem; */
  border-bottom: 1px solid #e4e4e4;
}

.wordLimitText {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
}

.wordLimitWarningText {
  color: #f56666;
}

.saveSatButton {
  width: 290px;
  height: 60px;
  background: #8bc334;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
}

.saveSatButton:hover {
  background: rgba(139, 195, 52, 0.9);
}

.goReportButton {
  width: 290px;
  height: 60px;
  background: #388cef;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #ffffff;
}

.goReportButton:hover {
  background: rgba(56, 140, 239, 0.9);
}

.wordLimitContainer {
  padding-left: 1.82rem;
  padding-right: 1.82rem;
  border-bottom: 1px solid #e4e4e4 !important;
}

.satCharTextOverflow {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  /* or 173% */

  display: flex;
  align-items: center;

  color: #f56666;
}

.iconLimit {
  font-weight: 900;
  font-size: 15px;
  line-height: 32px;
  /* or 213% */

  display: flex;
  align-items: center;

  color: #f56666;
}

.overLimitCount {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  /* or 160% */

  display: flex;
  align-items: center;

  color: #f56666;
}

.nqaSatCount {
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  /* or 173% */

  display: flex;
  align-items: center;
  text-align: center;
}

.satCheck {
  width: 15px;
  height: 15px;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  /* or 213% */

  display: flex;
  align-items: center;
  text-align: center;
}

.satAddedNotif {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  width: 185px;
  color: #ffffff;
  padding: 0.5rem 0.5rem 0.8rem 0.8rem;
  background: #222222;
  border-radius: 3px;
}

.toolTip {
  width: 0;
  height: 0;
  bottom: -10px;
  margin-right: 22px;
  border: 5px solid transparent;
  border-bottom: 0;
  border-top: 10px solid #222222;
}

.changesSavedNotif {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  width: 164px;
  color: #ffffff;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: #388cef;
  border-radius: 3px;
}

.toolTipSavedNotif {
  width: 0;
  height: 0;
  bottom: -10px;
  margin-left: 20px;
  border: 5px solid transparent;
  border-bottom: 0;
  border-top: 10px solid #388cef;
}

.larContainer {
  border-bottom: 1px solid #e4e4e4 !important;
}

.larHeaderText {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;

  color: #222222;
}

.lawStatusNotCompleted {
  width: 121px;
  height: 31px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;

  color: #f56666;

  background: #fde0e0;
  border-radius: 3px;
}

.lawStatusCompleted {
  width: 102px;
  height: 31px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;

  color: #ffffff;

  background: #8bc334;
  border-radius: 3px;
}

.larDescription {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height, or 153% */

  color: #272828;
}

.larButton {
  width: 353px;
  height: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #388cef;
  background: rgba(56, 140, 239, 0.12);
  border-radius: 5px;
}

.larButton:hover {
  color: #ffffff;
  background: #388cef;
}

.satQuestionStatusContainer {
  border-bottom: 1px solid #e4e4e4 !important;
}

.satQuestionStatus {
  font-style: normal;
  font-weight: normal;
  font-size: 39px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -1px;

  color: #272827;
}

.satDescription {
  width: 800px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  text-align: center;

  color: #272828;
}

.satQuestionsContainer {
  /* border-bottom: 1px solid #e4e4e4 !important; */
}

.resourceFilterRow .resourceFilter1:first-child {
  margin-right: 0.25rem;
}

.resourceFilterRow .resourceFilter1:last-child {
  margin-left: 0.25rem;
}

.resourceFilterRow .resourceFilter1:not(:first-child):not(:last-child) {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}
