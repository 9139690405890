.modalContainer {
  display: flex;
  padding: 2.5rem 5rem 4rem 5rem;
  flex-direction: column;
}

.closeButton {
  top: 0;
  right: 0;
  position: absolute;
  margin-right: 0 !important;
  color: #f56666 !important;
  border-radius: 0 !important;
  background-color: #feeded !important;
}

.closeButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.selectedServiceGroup {
  display: flex;
  align-items: center;
}

.headerText {
  margin-top: 1rem;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
  margin-bottom: 3rem;
}

.statusContainer {
  padding: 1.3rem 0;
  border: none;
  cursor: pointer;
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  border-radius: 3px;
  text-align: center;
  outline: none;
}

.statusContainer:not(:last-child) {
  margin-bottom: 10px;
}

.statusContainer:nth-child(3) {
  color: #f56666;
  background-color: #feeded;
}

.statusContainer:nth-child(4) {
  color: #ffffff;
  background-color: #388cef;
}

.statusContainer:nth-child(5) {
  color: #8bc334;
  background-color: #f1f8e7;
}

.statusContainer:nth-child(3):hover {
  color: #ffffff;
  background-color: #f56666;
}

.statusContainer:nth-child(4):hover {
  color: #388cef;
  background-color: #e7f1fd;
}

.statusContainer:nth-child(5):hover {
  color: #ffffff;
  background-color: #8bc334;
}

.clearSection {
  text-align: center;
  margin-top: 2.5rem;
}

.clearSectionButton {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #f56666;
  background: unset;
  border: unset;
  outline: none;
  cursor: pointer;
  /* display: none; */
}
