.mainContainer {
  border-bottom: 1px solid #e4e4e4 !important;
}

.headerText {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;

  color: #222222;
}

.description {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  /* or 153% */

  color: #272828;
}

.textArea {
  height: 200px;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  /* identical to box height, or 173% */

  color: #272828;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  resize: none;
}

.saveButton {
  width: 255px;
  height: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
  background: rgba(139, 195, 52, 1);
  border-radius: 5px;
}

.saveButton:hover {
  background: rgba(139, 195, 52, 0.9);
}
