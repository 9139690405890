.mainContainer {
	max-width: 1366px;
	margin-right: auto;
	margin-left: auto;
}  

.backButtonContainer {
	text-align: right;
}

.backButtonText {
    width: 50px;
    height: 50px;
    position: relative;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    align-self: flex-end;
    border-radius: 50%;
    background-color: #ffffff;
	outline: none;
}

.backButton {
    top: 13px;
    left: 13px;
    width: 25px;
    height: 25px;
    position: absolute;
}

.backButtonText:hover > .backButton{
	color: #222222!important;
}

.content {
    display: flex;
    margin-bottom: 4rem;
    padding: 0 4rem;
}

.leftCardColumn {
    flex: 0.55;
    margin-right: 4px;
    background-color: white;
}

.leftCardColumnContent {
    display: flex;
    flex-direction: column;
}

.leftCardHeader {
    font-size: 34px;
    line-height: 50px;
    font-weight: normal;
    letter-spacing: -1px;
    margin-top: 6.8rem;
    margin-bottom: 1.5rem;
}

.descriptionText {
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    margin-bottom: 2.5rem;
}

.leftCardColumnContent {
    padding: 0 4rem 0 3rem;
}

.checkCircle {
    color: #388CEF !important;
}

.benefitEntry {
    display: flex;
    line-height: 16px;
    font-weight: normal;
    font-size: 14px !important;
    margin-bottom: 1.5rem;
}

.rightCardColumn {
    flex: 0.45;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cardTabRow {
    display: flex;
}

.cardTab, .cardTabSelected {
    flex: 0.5;
    cursor: pointer;
    padding: 2rem 4rem;
    color: #C9C9C9;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    font-weight: normal;
    background-color: white;
}

.cardTabSelected {
    color: black;
}

.cardTab:nth-child(1) {
    margin-right: 4px;
    margin-bottom: 4px;
}

.cardTab:nth-child(2) {
    margin-left: 4px;
    margin-bottom: 4px;
}

.subscriptionContent {
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: white;
}

.subscriptionContent {
    padding: 0 2rem 5rem 3rem;
}

.subscriptionPriceRow {
    display: flex;
    align-items: flex-end;
    margin-top: 6rem;
    margin-bottom: 2.5rem;
}

.subscriptionPriceRow>text {
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
}

.subscriptionPriceRow>text:nth-child(1) {
    align-self: flex-start;
}

.subscriptionPriceRow>text:nth-child(2) {
    font-size: 90px;
    margin-right: -4px;
    line-height: 78px;
    font-weight: normal;
    letter-spacing: -1px;
}

.subscriptionHeader {
    font-size: 39px;
    line-height: 50px;
    font-weight: normal;
    letter-spacing: -1px;
    margin-bottom: 1.5rem;
}

.subscriptionBenefitEntry {
    display: flex;
    line-height: 19px;
    font-weight: normal;
    font-size: 16px !important;
    margin-bottom: 1.5rem;
}

.blueButton {
    margin-top: 1.5rem;
}

@media screen and (min-width: 992px) {
    
    .leftCardHeader {
        font-size: 39px;
    }   
    
    .subscriptionPriceRow>text:nth-child(2) {
        font-size: 100px;
    }    
    
    .subscriptionContent {
        padding: 0 3rem 5rem 4rem;
    }
      
    .leftCardColumnContent {
        padding: 0 5rem 0 4rem;
    }

}

@media screen and (min-width: 1024px) {

    .content {
        max-width: 900px;
        margin-right: auto;
        margin-left: auto;
        padding: 0;
    }

}