.mainContainer {
	max-width: 1366px;
	margin-right: auto;
	margin-left: auto;  
}

.backButtonContainer {
    text-align: right;
}

.backButtonText {
    width: 50px;
    height: 50px;
    position: relative;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    align-self: flex-end;
    border-radius: 50%;
    background-color: #ffffff;
    outline: none;
}

.backButton {
    top: 13px;
    left: 13px;
    width: 25px;
    height: 25px;
    position: absolute;
}

.backButtonText:hover>.backButton {
    color: #222222!important;
}

.content {
    padding: 0 4rem;
    margin-bottom: 5rem;
}

.headerTextGroup {
    
}

.headerContainer {
    display: flex;
    justify-content: space-between;
}

.headerText {
    font-size: 39px;
    line-height: 50px;
    font-weight: normal;
    letter-spacing: -1px;
    margin-bottom: 1rem;
}

.headerSubText {
    font-size: 20px;
    line-height: 32px;
    font-weight: normal;
}

.backToDashboardButton {
    height: 50px;
    padding: 0 3rem;
    border: none;
    cursor: pointer;
    color: #C9C9C9;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
    border-radius: 5px;
    text-decoration: none;
    background-color: #ffffff;
    outline: none;
}

.backToDashboardButton:hover {
    color: #222222;
}

.cardContainer {
    display: flex;
    margin-top: 4rem;
    flex-direction: column;
}

.searchFilterRow {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem;
    margin-bottom: 4px;
    background-color: #ffffff;
    /* justify-content: space-between; */
    justify-content: flex-end;
}

.searchFilterContainer {
    display: none;
}

.searchInput {
	font-size: 26px!important;
	font-weight: normal !important;
    line-height: 36px !important;
    color: #BEBEBE !important;       
	max-width: 280px;
}

.searchInput input {
    border: 1px solid #BEBEBE !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;    
}

.searchInput input:focus {
    color: #BEBEBE !important;        
}

.searchInput::placeholder {
    color: #BEBEBE !important;    
}

.filterDropdown {
    margin-left: 3rem;
}

.filterDropdown, .filterDropdown>div {
    color: #BEBEBE !important;
    font-size: 26px;
    line-height: 36px;
    align-self: center;
    font-weight: normal !important;
}

.newUserButton {
    height: 80px;
    width: 205px;
    padding: 1rem 3rem;
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
    border-radius: 5px;
    text-decoration: none;
    background-color: #8BC334;
    outline: none;
    margin-top: 1rem;
}

.newUserButton:hover {
    background-color: #97d439;
}

.searchFilterContainer {
    display: none;
}

@media screen and (max-width: 820px) {
    
    .headerTextGroup  {
        max-width: 380px;
    }

}


@media screen and (min-width: 1024px) {
    
    .newUserButton {
        margin-top: 0;
    } 
    
}

@media screen and (min-width: 1100px) {
    
    .content {
        padding: 0 8rem;
    }
    
    /* .filterDropdown { */
        /* margin-left: 3rem; */
    /* } */
    
    .filterDropdown, .filterDropdown>div {
        font-size: 30px;
    }
    
    .searchInput {
        font-size: 30px!important;
    }
    
}

@media screen and (min-width: 1200px) {
  
    .searchInput {
        max-width: 350px;
    }  
    
}