.modalContainer {
  display: flex;
  padding: 2rem 4rem 4rem;
  flex-direction: column;
}

.closeButton {
  top: 0;
  right: 0;
  position: absolute;
  margin-right: 0 !important;
  color: #f56666 !important;
  background-color: #feeded !important;
}

.closeButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.headerText {
  margin-top: 1rem;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
  margin-bottom: 3rem;
}

.dateRow {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.dateRow > div {
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

.dateHeader {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  margin-bottom: 0.5rem;
}

.datePickerGroup {
  position: relative;
}

.datePickerBox {
  height: 60px;
  width: 100%;
  padding: 1rem;
  font-size: 20px;
  margin-top: 0.5rem;
  border-radius: 3px;
  border: 1px solid black;
}

.errorBorder {
  border: 1px solid red;
}

.datePickerIcon {
  top: 24px;
  right: 0.5rem;
  position: absolute;
  background-color: white;
  font-size: 20px !important;
}

.blueButton {
  margin-top: 2.5rem;
  align-self: center;
}

.clearSection {
  text-align: center;
  margin-top: 2.5rem;
}

.clearSectionButton {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #f56666;
  background: unset;
  border: unset;
  outline: none;
  cursor: pointer;
  /* display: none; */
}
