.modalContainer {
  height: 352px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.modalMessage {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #272828;
}

.modalButton {
}
