.mainContainer {
	max-width: 1366px;
 	margin-right: auto;
	margin-left: auto;
	margin-bottom: 5rem;
}

.backButtonContainer {
	text-align: right;
}

.backButtonText {
    width: 50px;
    height: 50px;
    position: relative;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    align-self: flex-end;
    border-radius: 50%;
    background-color: #ffffff;
	outline: none;
}

.backButton {
    top: 13px;
    left: 13px;
    width: 25px;
    height: 25px;
    position: absolute;
}

.backButtonText:hover > .backButton{
	color: #222222!important;
}

.mainCard {
    padding: 0 4rem;
    margin-top: 4rem;
}
  
.cardContent {
    padding: 6rem 5rem;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.infoCircleContainer {
    width: 85px;
    height: 85px;
    border: none;
    position: relative;
    border-radius: 50%;
    background-color: #feeded;
    margin-bottom: 40px;
	margin-right: auto;
	margin-left: auto;
}

.infoCircle {
    top: 17px;
    left: 19px;
    width: 50px;
    height: 50px;
    position: absolute;
}

.headerText {
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -1px;
    margin-bottom: 20px;
}

.descriptionText {
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    font-style: normal;
    text-align: center;
	display: block;
}

.blueButton {
    margin-top: 2rem;
    /* margin-bottom: 2.5rem; */
	display: block;
    text-align: center;
}

.skipLaterStepGroup {
    color: #F56666;
    display: flex;
    cursor: pointer;
    margin-top: 1rem;
    align-items: center;
	justify-content: center;
}
.skipLaterStepGroup:hover {
	opacity: .7;
}
.skipLaterStepText {
    color: #F56666;
    margin-left: 5px;
    text-decoration: underline;
}
.skipLaterStepText:hover {
	color: rgb(245 102 102 / .8);
	text-decoration: underline;
}

.stepsColumn {
    width: 175px;
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.stepItem {
    text-align: center;
    background: #8BC334;
    border-radius: 222px;
    color: #ffffff;
    padding: 0.5rem 1rem;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 10px;
}

.stepItemContainer {
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 50%;
    margin-bottom: 10px;
    position: relative;
    background-color: #ffffff;
}

.stepItemFill {
    width: 7px;
    height: 7px;
    top: 14px;
    left: 14px;
    border: none;
    border-radius: 50%;
    position: absolute;
    background-color: #C4C4C4;  
}

@media screen and (min-width: 992px) {

    .mainCard {
        max-width: 720px;
        margin-right: auto;
        margin-left: auto;
        padding: 0;
    }

}

@media screen and (min-width: 1100px) {
    
    .headerText {
        font-size: 39px;
    }    
  
}