.satReportContainer {
  background: #ffffff;
}

.footerHow {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* or 173% */

  text-align: left;

  color: #272828;
  /* padding-left: 1.82rem;
  padding-right: 1.82rem; */
  border-bottom: 1px solid #e4e4e4;
}

.headerHow,
.headerText {
  width: 90%;
  display: block;
}
