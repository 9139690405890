.container {
  position: relative;
  background: #eaeee8;
  height: auto;
}

.mainContent {
  position: relative;
  background: #ffffff;
  width: 734px;
  height: 686px;
  min-height: 686px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.06);
}

.welcomeText {
  position: absolute;
  top: 245px;
  left: 150px;
  width: 434px;
  height: 100px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 39px;
  line-height: 50px;
  /* or 128% */

  text-align: center;
  letter-spacing: -1px;

  color: #272827;
}

.subText {
  position: absolute;
  top: 361px;
  left: 113.5px;
  width: 507px;
  height: 25px;

  /* Paragraph 16px */

  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  /* identical to box height, or 156% */

  text-align: center;

  color: #272828;
}

.buttonGroup {
  position: absolute;
  width: 674px;
  height: 204px;
  left: 30px;
  top: 419px;

  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 5px;
}

.buttonDivider {
  border-right: 1px solid #dbdbdb;
}

.headerClip {
  position: absolute;
  top: 64px;
  left: 223px;
}

.serviceButton {
  width: 122px;
  height: 122px;
  background-color: #e7f1fd;
}
.serviceButton:hover {
  color: #ffffff;
  background-color: #388cef;
}

.serviceButton:hover svg {
  fill: white;
}

.blueButton {
  width: 122px;
  height: 122px;
}

.serviceButton svg {
  fill: #388cef;
}

.serviceButton svg:hover {
  fill: white;
}

.serviceLabel {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #272828;
}

.nextButton {
  position: absolute;
  top: 501px;
  width: 275px;
  height: 65px;
  margin-left: 229.5px;
  padding: 1.3rem 4rem;
  background-color: #f1f8e7;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: #8bc334;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  outline: none;
}

.nextButton:hover {
  color: #ffffff;
  background-color: #8bc334;
}

.serviceNameInput {
  position: absolute;
  width: 450px;
  height: 56px;
  left: 142px;
  top: 415px;

  background: #ffffff;
  border: 1px solid #222222;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 23px;
  /* identical to box height */

  color: #222222;
}

.backStep {
  position: absolute;
  width: 129.84px;
  height: 23px;
  top: 608px;
  left: 302px;
}

.backText {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #979797;
}

.backArrow {
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  color: #979797;
}
