.modalContainer {
  position: relative;
  background: #ffffff;
}

@media screen and (min-width: 900px) {
  .modalContainer {
    /* width: 1126px; */
    width: auto;
    padding: 45px 100px 100px 100px;
  }
}

@media screen and (max-width: 900px) {
  .modalContainer {
    width: auto;
    padding: 30px 50px 50px 50px;
  }
}

.closeButton {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50px;
  height: 50px;
  font-style: normal;
  font-weight: 900;
  font-size: 23px;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
  color: #f56666;
  background: rgba(245, 102, 102, 0.12);
}

.closeButton:hover {
  background: rgba(245, 102, 102, 0.18);
}

.headerText {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 32px;
  /* or 107% */

  text-align: center;

  color: #272827;
}

.headerSub {
  max-width: 615px;
  width: 90%;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  text-align: center;

  color: #272828;
}

.alertLeft {
  /* max-width: 451px; */
  min-height: 125px;

  background: #f0f7ff;
  border: 1px solid rgba(56, 140, 239, 0.2);
  box-sizing: border-box;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  /* or 173% */

  text-align: center;

  color: #388cef;
}

.alertRight {
  /* max-width: 451px; */
  min-height: 125px;

  background: rgba(139, 195, 52, 0.1);
  border: 1px solid rgba(139, 195, 52, 0.3);
  box-sizing: border-box;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  /* or 173% */

  text-align: center;

  color: #8bc334;
}

@media screen and (min-width: 840px) and (max-width: 860px) {
  .alertLeft,
  .alertRight {
    height: 14rem;
  }
}

.tableHead {
  color: #ffffff;
  /* height: 50px; */
  background: #8bc334;
  border-radius: 5px 5px 0px 0px;
}
.tableHead > tr > th {
  font-style: normal;
  font-weight: normal;

  padding: 1rem 1rem 1rem 1rem;
}

@media screen and (min-width: 860px) {
  .tableHead > tr > th,
  .tableBody > tr > td {
    font-size: 15px;
    line-height: 25px;
  }
}

@media screen and (max-width: 860px) {
  .tableHead > tr > th,
  .tableBody > tr > td {
    font-size: 13px;
    line-height: 24px;
  }
}

.tableBody > tr > td {
  padding: 1rem 1rem 1rem 1rem;
  font-style: normal;
  font-weight: 300;

  vertical-align: text-top;
  /* or 167% */

  color: #272828;
}

.tableHead > tr > th:nth-child(1) {
  width: 20%;
}

.tableHead > tr > th:nth-child(2) {
  width: 8%;
}

.tableHead > tr > th:nth-child(3) {
  width: 48%;
}

.tableHead > tr > th:nth-child(4) {
  width: 28%;
}

.tableBody {
  background: #fcfcfc;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
}

.tableBody > tr > td:first-child,
.tableBody > tr > td:last-child {
  font-weight: normal;
}

.tableBody > tr:not(:last-child) {
  border-bottom: 1px solid #e7e7e7;
}

.tableBody > tr > td:not(:last-child) {
  border-right: 1px solid #e4e4e4;
}

.tableBody > tr > td > ul {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 2rem;
  list-style-position: outside;
  list-style-type: disc;
}

.tableBody > tr > td > ul > li:not(:last-child) {
  margin-bottom: 0.25rem;
}

.noticeCompliance {
  max-width: 800px;
  width: 95%;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  text-align: center;

  color: #272828;
}

.actionArea {
  width: 100%;
  height: 200px;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  /* or 173% */

  color: #272828;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  resize: none;
}

.saveButton {
  width: 200px;
  height: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
  background: #8bc334;
  border-radius: 5px;
}

.saveButton:hover {
  background: rgba(139, 195, 52, 0.9);
}
