.mainContainer {
	max-width: 1366px;
	margin-right: auto;
	margin-left: auto;
}  

.backButtonContainer {
	text-align: right;
}

.backButtonText {
    width: 50px;
    height: 50px;
    position: relative;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    align-self: flex-end;
    border-radius: 50%;
    background-color: #ffffff;
	outline: none;
}

.backButton {
    top: 13px;
    left: 13px;
    width: 25px;
    height: 25px;
    position: absolute;
}

.backButtonText:hover > .backButton{
	color: #222222!important;
}

.content {
    margin-bottom: 4rem;
    padding: 0 4rem;
}

.leftCardColumn {
    flex: 0.55;
    margin-right: 4px;
    background-color: white;
}

.leftCardHeader {
	font-weight: 400;
    font-size: 34px;
    line-height: 45px;
    letter-spacing: -1px;
}

.btnUpgrade {
    font-size: 70px !important;
    color: #ffffff !important;
    background-color: #388CEF !important;
	border-radius: 50% !important;
	padding: unset !important;
    width: 138px;
    height: 138px;
}

.descriptionText {
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
}

.descriptionText svg {
    color: #F56666;
    height: fit-content;
    margin-top: .4rem;
}

.checkCircle {
    color: #388CEF !important;
}

.rightCardColumn {
    flex: 0.45;
    height: 100%;
}

.cardTabSelected {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    color: #272828;
}

.cardTab:nth-child(1) {
    margin-right: 4px;
    margin-bottom: 4px;
}

.cardTab:nth-child(2) {
    margin-left: 4px;
    margin-bottom: 4px;
}

.subscriptionContent {
    height: 100%;
    background-color: #ffffff;
}

.leftCardColumnContent,
.subscriptionContent {
    padding: 30px 30px  30px  40px;
}

.subscriptionPriceRow>text {
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
}

.subscriptionPriceRow>text:nth-child(2) {
	font-weight: 400;
    font-size: 70px;
    line-height: 83px;
    letter-spacing: -1px;
}

.subscriptionHeader {
	font-weight: 400;
    font-size: 27px;
    line-height: 50px;
    letter-spacing: -1px;
}

.subscriptionBenefitEntry {
    font-weight: normal;
    font-size: 16px !important;
	line-height: 19px;
}

.subscriptionBenefitEntry i {
	color: #8BC334!important;
}

@media screen and (min-width: 992px) {
    
    .leftCardHeader {
        font-size: 39px;
		line-height: 50px;
    }       
    
	.leftCardColumnContent,
    .subscriptionContent {
        padding: 40px 40px  40px  50px ;
    }

}

@media screen and (min-width: 1024px) {

    .content {
        max-width: 900px;
        margin-right: auto;
        margin-left: auto;
        padding: 0;
    }

}