.modalContainer {
  display: flex;
  flex-direction: column;
}

.closeButton {
  top: 0;
  right: 0;
  width: 55px;
  height: 55px;
  position: absolute;
  border-radius: 0 !important;
  margin-right: 0 !important;
  color: #f56666 !important;
  background-color: #feeded !important;
}

.closeButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.spinnerDiv {
  margin: 20vh;
  text-align: center;
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.divider {
  border: 1px solid #e7e7e7;
}

.modalHeaderRow {
  display: flex;
  padding: 4rem 12.5rem;
  flex-direction: column;
}

/* .modalHeaderRow .subText { */
.subText {
  margin-top: 1.8rem;
}

/* .headerText { */
/* margin-top: 4rem; */
/* margin-bottom: 2rem; */
/* } */

.headerText,
.tasksHeaderText {
  font-size: 39px;
  line-height: 46px;
  text-align: center;
  font-weight: normal;
  letter-spacing: -1px;
  align-self: center;
  display: block;
}

.tasksHeaderText {
  margin-top: 3rem;
}

.subText {
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  font-weight: normal;
  align-self: center;
}

.evidenceRow {
  display: flex;
  padding: 4rem 12.5rem;
  flex-direction: column;
}

.evidenceStatusRow {
  display: flex;
  margin-top: 3rem;
  justify-content: center;
}

.evidenceStatusRow > div {
  display: flex;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  flex-direction: column;
}

.evidenceStatusRow > div:first-child {
  margin-right: 2.5rem;
}

.evidenceStatusIcon,
.evidenceStatusYes,
.evidenceStatusNo {
  padding: 13px 12px !important;
  font-size: 50px !important;
  line-height: 57px !important;
  margin-right: 0 !important;
  pointer-events: none !important;
  margin-bottom: 1rem !important;
  border-radius: 5px !important;
}

.evidenceStatusIcon {
  color: #e4e4e4 !important;
  background-color: #ffffff !important;
  border: 3px solid #e4e4e4 !important;
}

.evidenceStatusYes {
  color: #8bc334 !important;
  border: 3px solid #f1f8e7 !important;
  background-color: #f1f8e7 !important;
}

.evidenceStatusNo {
  color: #f56666 !important;
  border: 3px solid #feeded !important;
  background-color: #feeded !important;
}

.evidenceDocumentGroup {
  display: flex;
  padding: 4rem 12.5rem;
  flex-direction: column;
}

.evidenceDocumentRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  border-top: 1px solid #e7e7e7;
}

.evidenceDocumentRow:nth-last-child(1) {
  border-bottom: 1px solid #e7e7e7;
}

.evidenceDocumentDisplay {
  display: flex;
}

.evidenceImageContainer {
  width: 75px;
  height: 75px;
  position: relative;
}

.evidenceImage {
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  margin: auto;
  position: absolute;
}

.evidenceDocumentDetails {
  display: flex;
  margin-left: 1rem;
  flex-direction: column;
}

.evidenceDocumentFileName {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  text-transform: capitalize;
}

.evidenceDocumentUploadDate {
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
}

.evidenceDocumentActionGroup {
  display: flex;
}

.documentButtonGroup {
  display: flex;
  flex-direction: column;
}

.documentButtonGroup:not(:last-child) {
  margin-right: 2.5rem;
}
.fileUploader {
  padding-top: 8px;
}
.fileUploader::-webkit-file-upload-button {
  background-color: #8cc336;
  border: none;
  padding: 10px 20px;
  color: #fff;
  font-size: 16px;
}

.removeButton,
.downloadButton,
.editButton {
  font-size: 40px !important;
  line-height: 46px !important;
  margin-right: 0 !important;
  padding: 20px 20px !important;
  border-radius: 5px !important;
}

.anchorButton {
  font-size: 47px !important;
  line-height: 46px !important;
  margin-right: 0 !important;
  padding: 20px 20px !important;
  border-radius: 5px !important;
}

.removeButton {
  color: #f56666 !important;
  background-color: #feeded !important;
}

.removeButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.downloadButton {
  color: #8bc334 !important;
  background-color: #f1f8e7 !important;
}

.downloadButton:hover {
  color: #ffffff !important;
  background-color: #8bc334 !important;
}

.editButton {
  color: #388cef !important;
  background-color: #e7f1fd !important;
}

.editButton:hover {
  color: #ffffff !important;
  background-color: #388cef !important;
}

.anchorButton {
  color: #8bc334 !important;
  background-color: #f1f8e7 !important;
}

.anchorButton:hover {
  color: #ffffff !important;
  background-color: #8bc334 !important;
}

.documentButtonText {
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.uploadEvidenceRow {
  padding: 0 5rem 4rem;
}

.linkEvidenceRow {
  padding: 0 5rem 4rem;
}

.commentEvidenceRow {
  padding: 0 5rem 4rem;
}

.uploadEvidencePlaceholder {
  cursor: pointer;
  text-align: center;
  border: 1px dashed #c9c9c9;
}

.uploadEvidencePlaceholder div {
  padding: 50px 0;
}

.uploadEvidenceIcon {
  font-size: 79px !important;
  color: #e4e4e4 !important;
  line-height: 91px !important;
  margin-bottom: 1rem !important;
  margin-right: 0 !important;
}

.uploadEvidenceText {
  color: #c9c9c9;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  font-weight: normal;
  display: block;
}

.uploadEvidencePlaceholder div:hover > .uploadEvidenceIcon,
.uploadEvidencePlaceholder div:hover > .uploadEvidenceText {
  color: rgb(34 34 34 / 0.25) !important;
}

.tasksCountRow {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 12.5rem;
  padding-right: 12.5rem;
}

.tasksCountRow > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tasksCountRow > div > div {
  font-size: 39px;
  line-height: 46px;
  text-align: center;
  font-weight: normal;
  letter-spacing: -1px;
}

.tasksCountRow > div > div > text:first-child {
  color: #388cef;
  margin-right: 10px;
}

.evidenceCount {
  color: #388cef;
}

.evidenceCountText {
  font-size: 39px;
  line-height: 46px;
  text-align: center;
  font-weight: normal;
  letter-spacing: -1px;
}

.showTasksButton,
.addTaskButton {
  height: 60px;
  padding: 1rem 3rem;
  align-self: center;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  color: black;
  background-color: #e4e4e4;
}

.showTasksButton:hover,
.addTaskButton:hover {
  background-color: #f2f2f2;
}

.tasksGroup {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-right: 12.5rem;
  padding-left: 12.5rem;
}

.taskItemContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #c9c9c9;
  justify-content: space-between;
}

.taskContentRow {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

.taskDescriptionColumn {
  display: flex;
  flex-direction: column;
  padding: 2rem 0 2rem 2.5rem;
}

.taskDescriptionHeader {
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  margin-bottom: 8px;
}

.taskDescriptionSubtext {
  display: flex;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
}

.taskDescriptionSubtext > text:not(:last-child) {
  margin-right: 12px;
}

.taskDetailNotStarted {
  color: #f56666;
}

.taskDetailInProgress {
  color: #388cef;
}

.taskDetailCompleted {
  color: #8bc334;
}

.taskCollapseButton,
.taskCollapseButtonSelected {
  font-size: 40px !important;
  margin-right: 0 !important;
  color: #e4e4e4 !important;
  line-height: 46px !important;
  text-align: center !important;
  font-weight: normal !important;
  background-color: transparent !important;
}

.taskCollapseButtonSelected {
  color: #388cef !important;
}

.taskDetails {
  display: flex;
  flex-direction: column;
  padding: 2rem 2.5rem 4rem;
}

.dropdownRow {
  display: flex;
}

.dropdownRow > div:first-child {
  margin-right: 20px;
}

.dropdownGroup {
  flex: 0.5;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-direction: column;
}

.dropdownText {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
}

.dropdownBox,
.dropdownBoxStatus {
  height: 60px;
  font-size: 20px !important;
  margin-top: 0.5rem !important;
  border-radius: 5px !important;
  padding: 1.25rem 1.25rem !important;
  border: 1px solid black !important;
}

.datePickerGroup {
  position: relative;
}

.datePickerBox {
  height: 60px;
  width: 100%;
  padding: 1rem;
  font-size: 20px;
  margin-top: 0.5rem;
  border-radius: 3px;
  border: 1px solid black;
}

.datePickerIcon {
  top: 24px;
  right: 0.5rem;
  position: absolute;
  background-color: white;
  font-size: 20px !important;
}

.summaryTextBox {
  height: 200px;
  margin-bottom: 1rem;
}

.statusSelect1,
.statusSelect2,
.statusSelect3 {
  height: 60px;
  cursor: pointer;
  padding: 1rem 2rem;
  margin-top: 0.5rem;
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  border-radius: 3px;
  text-align: center;
  color: white;
}

.statusSelect1 {
  background-color: #f56666;
}

.statusSelect1:hover {
  background-color: #f78585;
}

.statusSelect2 {
  background-color: #388cef;
}

.statusSelect3 {
  background-color: #8bc334;
}

.statusSelect > text {
  margin-left: 2rem;
}

.statusSelect > *:last-child {
  float: right;
  font-size: 24px !important;
}

.blueButton {
  margin-top: 1rem;
  text-align: center;
}

.redButton {
  margin-top: 1rem;
  text-align: center;
}

.evidenceTaskRow {
  display: flex;
  padding: 4rem 12.5rem;
  flex-direction: column;
}

.evidenceTaskForm {
  display: flex;
  flex-direction: column;
  /* margin-top: 2.5rem; */
}

.addTaskButton {
  margin: 1.5rem 0;
}

.generalCommentsRow {
  display: flex;
  padding: 4rem 12.5rem;
  flex-direction: column;
}

.generalCommentsRow .headerText {
  margin-bottom: 2rem;
}

.taskConfirmationModal {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 3rem 3rem;
}

.generalCommentsRow,
.evidenceTaskRow,
.modalHeaderRow,
.evidenceDocumentGroup,
.evidenceRow {
  display: flex;
  padding: 4rem 6rem;
  flex-direction: column;
}

.evidenceDocumentGroup {
  width: 100%;
  padding: 4rem 2rem;
}

.evidenceTypeButton:first-child {
  border-radius: 5px 0 0 5px;
  border-right: solid rgb(0 0 0 /10%) 1px;
}

.evidenceTypeButton:last-child {
  border-radius: 0 5px 5px 0;
  border-left: solid rgb(0 0 0 / 10%) 1px;
}

.evidenceTypeButtonactive {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 248, 246, var(--tw-bg-opacity));
}

.evidenceTypeSelectedArrow {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom: 0;
  margin-left: -20px;
  margin-bottom: -20px;
}

.titleText {
  font-size: 16px;
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
}

@media screen and (min-width: 1024px) {
  .generalCommentsRow,
  .evidenceTaskRow,
  .modalHeaderRow,
  .evidenceDocumentGroup,
  .evidenceRow {
    padding: 4rem 10rem;
  }
  .uploadEvidenceRow {
    padding: 0 10rem 4rem;
  }
  .linkEvidenceRow {
    padding: 0 10rem 4rem;
  }
  .commentEvidenceRow {
    padding: 0 10rem 4rem;
  }
  .titleText {
    font-size: 20px;
  }
}

@media screen and (min-width: 1100px) {
  .generalCommentsRow,
  .evidenceTaskRow,
  .modalHeaderRow,
  .evidenceDocumentGroup,
  .evidenceRow {
    padding: 4rem 12.5rem;
  }
  .uploadEvidenceRow {
    padding: 0 12.5rem 4rem;
  }
  .linkEvidenceRow {
    padding: 0 12.5rem 4rem;
  }
  .commentEvidenceRow {
    padding: 0 12.5rem 4rem;
  }
}
.multiple_img {
  width: calc(20% - 80px);
}
.ckeditorStyles ul {
  list-style: disc;
  padding-left: 24px;
}
.ckeditorStyles ol {
  list-style: decimal;
  padding-left: 24px;
}
.ckeditorStyles table,
.ckeditorStyles th,
.ckeditorStyles td {
  border: 1px solid;
  border-collapse: collapse;
}
.firstChildDisplay > a {
  display: none;
}
.firstChildDisplay > a:first-of-type {
  display: block;
}
