.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 6rem;
  margin-bottom: 4rem;
}

.backButtonContainer {
  text-align: right;
  display: none;
}

.backButtonText {
  width: 50px;
  height: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  align-self: flex-end;
  border-radius: 50%;
  background-color: #ffffff;
  outline: none;
}

.backButton {
  top: 13px;
  left: 13px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.backButtonText:hover > .backButton {
  color: #222222 !important;
}

.spinnerDiv {
  margin-top: 30vh;
  text-align: center;
}

.mainCard {
  padding: 4rem 1rem;
}

.cardContent {
  display: flex;
  padding: 4rem 1.5rem;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 490px;
  margin: 0 auto 0 auto;
}

.headerText {
  font-size: 39px;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 8px;
}

.descriptionText {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  margin-bottom: 1rem;
}

.inputColumn {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.inputColumn > div {
  flex: 100%;
  display: flex;
  flex-direction: column;
}

.inputBox {
  font-size: 20px;
  padding: 16px 8px;
  border: 1px solid black;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.dropdownGroup {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

.dropdownText {
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
}

.dropdownBox {
  height: 60px;
  padding: 1.25rem 1rem !important;
  font-size: 20px !important;
  margin-top: 0.5rem !important;
  border-radius: 5px !important;
  border: 1px solid black !important;
}

.required {
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #f56666;
}

.serviceNumberInput::-webkit-outer-spin-button,
.serviceNumberInput::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.serviceNumberInput[type="number"] {
  -moz-appearance: textfield;
}

.blueButton {
  margin-top: 2rem;
  align-self: flex-start;
}

@media screen and (min-width: 500px) {
  .cardContent {
    padding: 4rem;
  }
  .mainCard {
    padding: 0 4rem;
  }
}

@media screen and (min-width: 768px) {
  .mainContainer {
    margin-top: 0;
  }
  .backButtonContainer {
    display: block;
  }
  .cardContent {
    max-width: unset;
    margin: unset;
  }
  .inputColumn > div:first-child {
    margin-right: 2rem;
  }
  .inputColumn > div {
    flex: 0.5;
  }
}

@media screen and (min-width: 992px) {
  .cardContent {
    padding: 4rem 6rem;
  }
}

@media screen and (min-width: 1100px) {
  .mainCard {
    padding: 0 10rem;
  }
}

.walkThroughHeader {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 50px;
  /* identical to box height, or 167% */

  letter-spacing: -1px;

  color: #272827;
}
.walkThroughSub {
  display: block;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */

  color: #222222;
}
