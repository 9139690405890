.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 4rem;
  margin-bottom: 5rem;
}

.mainCard {
  display: flex;
  padding: 0 2rem;
}

.cardContent {
  /* width: 490px; */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 4rem 2.5rem;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.headerText {
  font-size: 36px;
  line-height: 46px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 10px;
}

.headerTextChild {
  font-size: 20px;
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
}

.pleaseComplete {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  font-style: normal;
  margin-bottom: 1rem;
}

.additionalInfo {
  height: 300px;
  border-radius: 3px;
  margin-bottom: 4rem;
  border: 1px solid black;
}

.descriptionText {
  margin-top: 10px;
  font-size: 20px;
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 1rem;
}

.roomsInput {
  width: 80px;
  height: 60px;
  padding: 0.5rem 1rem;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  border-radius: 3px;
  border: 1px solid black;
  margin-bottom: 1.5rem;
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
}

.roomsInput::-webkit-outer-spin-button,
.roomsInput::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.roomsInput[type="number"] {
  -moz-appearance: textfield;
}

.roomEntryGroup {
  border-top: 1px solid #e7e7e7;
}

.roomEntry {
  display: flex;
  padding: 1rem 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
}

.ageGroup {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ageInput {
  width: 70px;
  height: 60px;
  padding: 1rem;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  border-radius: 3px;
  border: 1px solid black;
}

.blueButton {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}

.buttonStep {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.buttonStep button {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #979797;
  cursor: pointer;
  background-color: unset;
  border: unset;
  padding: 0;
}

.buttonStep button:hover {
  color: #222222;
}

.buttonStep button i {
  width: unset;
}

.buttonStep button.buttonStepBack i {
  margin-right: 0.5rem;
}

.buttonStep button.buttonFinishLater i {
  margin-left: 0.5rem;
  margin-right: 0;
}

.buttonStep i {
  background-color: unset !important;
}

.buttonNextStep {
  padding: 1.3rem 3rem;
  background-color: rgb(139 195 52 / 0.12);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #8bc334;
}

.buttonNextStep:hover {
  color: #ffffff;
  background-color: #8bc334;
}

.stepsColumn {
  width: 150px !important;
  display: flex;
  margin-left: 1rem;
  flex-direction: column;
}

.stepItem {
  background: #8bc334;
  border-radius: 222px;
  color: #ffffff;
  padding: 0.5rem 1rem;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 10px;
}

.stepItemIndicatorContainer {
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 50%;
  /* margin-bottom: 10px; */
  margin-right: 10px;
  position: relative;
  background-color: #ffffff;
}

.stepItemContainer {
  margin-bottom: 10px;
  cursor: pointer;
}

.stepItemContainer .stepItemCurrentText {
  display: flex;
  align-items: center;
}

.stepItemContainer:hover .stepItemFill {
  background-color: #222222;
}

.stepItemContainer:hover .stepItemCurrentText {
  color: #222222;
}

.stepItemTickContainer .stepContainerText {
  color: #8bc334 !important;
}

.stepItemCrossContainer .stepContainerText {
  color: #f56666 !important;
}

.stepItemFill {
  width: 7px;
  height: 7px;
  top: 14px;
  left: 14px;
  border: none;
  border-radius: 50%;
  position: absolute;
  background-color: #c4c4c4;
}

.stepItemFillDone {
  position: absolute;
  top: 10px;
  right: 13px;
  display: inline-block;
  transform: rotate(45deg);
  height: 14px;
  width: 7px;
  border-bottom: 3px solid #8bc334;
  border-right: 3px solid #8bc334;
}

.stepItemUnfinished {
  top: 9px;
  left: 8px;
  position: absolute;
  font-size: 16px !important;
  color: #f56666 !important;
}

.stepItemUnfinished {
  top: 9px;
  left: 8px;
  position: absolute;
  font-size: 16px !important;
  color: #f56666 !important;
}

.CurrentItemContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: -25px;
}

.CurrentItemContainer .currentItemFill {
  background-color: #222222;
}

.CurrentItemContainer .stepItemCurrentText {
  color: #222222;
}

.stepItemCurrent {
  display: inline-block;
  width: 60px;
  height: 35px;
  border: none;
  border-radius: 0 50px 50px 0;
  margin-right: 10px;
  position: relative;
  background-color: #ffffff;
}

.currentItemFill {
  width: 7px;
  height: 7px;
  top: 14px;
  right: 14px;
  border: none;
  border-radius: 50%;
  position: absolute;
  background-color: #c4c4c4;
}

.stepItemCurrentText {
  color: #c0c2bf;
  /* width: 58%; */
}

.buttonHolders {
  display: flex;
  margin-top: 1.5rem;
}

.buttonHolders div {
  margin-right: 1rem;
}

.blueButtonHolder,
.skipButtonHolder {
  width: 50%;
  display: initial;
  margin-right: 10px;
}

.groupChildrenTitle {
  font-size: 18px;
}

.stepContainerText {
  color: #222222;
  display: flex;
  align-items: center;
}

.headerContainer {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

.headerContainer h1 {
  font-weight: 400;
  font-size: 39px;
  line-height: 50px;
  letter-spacing: -1px;
  color: #272827;
  margin-bottom: 0.8rem;
}

.headerContainer p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.required {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #f56666;
}

@media screen and (min-width: 992px) {
  .cardContent {
    width: 100%;
    padding: 5rem 4rem;
  }

  .stepsColumn {
    width: 200px;
  }

  .ageInput {
    width: 80px;
  }
}

@media screen and (min-width: 1024px) {
  .required {
    font-size: 20px;
  }
}

@media screen and (min-width: 1100px) {
  .cardContent {
    width: 872px;
  }

  .headerText {
    font-size: 39px;
  }

  .stepsColumn {
    width: unset;
  }

  .mainCard {
    display: flex;
  }
  .mainCard,
  .headerContainer {
    max-width: 1037px;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
  }
}

@media screen and (min-width: 1150px) {
  .stepItemDoneText {
    width: unset;
  }
}
