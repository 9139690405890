.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardContainer {
  max-width: 900px;
  display: flex;
  margin: 7.5rem 4rem 0;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.spinnerDiv {
  margin-top: 30vh;
  text-align: center;
}

.headerText {
  font-weight: normal;
  font-size: 32px;
  line-height: 46px;
  margin-bottom: 1rem;
  letter-spacing: -1px;
}

.lightText {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  font-style: normal;
}

.bottomMargin {
  margin-bottom: 3rem;
}

.cardLeft,
.cardRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem 3rem 2rem;
}

.checkTextGroup svg {
  margin-top: 0.2rem;
}

.checkTextGroup {
  display: flex;
  margin-bottom: 1rem;
}

.checkText {
  font-size: 16px;
  margin-left: 5px;
  line-height: 19px;
  font-weight: normal;
}

.buttonContainer {
  margin-top: 2.5rem;
  align-self: flex-start;
}

.inputGroup {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

.inputBox {
  font-size: 20px;
  padding: 16px 8px;
  margin-top: 0.5rem;
  border-radius: 5px;
  border: 1px solid black;
}

.loginButton {
  padding: 1.3rem 4rem;
  align-self: flex-start;
  background-color: #f1f8e7;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: #8bc334;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  text-align: center;
  margin-top: 1.5rem;
  outline: none;
}

.loginButton:hover {
  color: #ffffff;
  background-color: #8bc334;
}

.forgotPasswordGroup {
  color: #f56666;
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.forgotPasswordGroup:hover {
  opacity: 0.7;
}

.forgotPasswordIcon {
  font-size: 30px !important;
  color: #f56666 !important;
}

.forgotPasswordText {
  color: #f56666;
  margin-left: 5px;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  text-decoration-line: underline;
}

.forgotPasswordText:hover {
  color: rgb(245 102 102 / 0.8);
  text-decoration: underline;
}

@media screen and (min-width: 992px) {
  .headerText {
    font-size: 39px;
  }
}

@media screen and (min-width: 1024px) {
  .cardContainer {
    margin: 7.5rem 0 0;
  }
  .cardLeft,
  .cardRight {
    padding: 4.5rem 4rem 5rem 3.5rem;
  }
}
