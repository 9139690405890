.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
}

.backButtonContainer {
  text-align: right;
}

.backButtonText {
  width: 50px;
  height: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  align-self: flex-end;
  border-radius: 50%;
  background-color: #ffffff;
  outline: none;
}

.backButton {
  top: 13px;
  left: 13px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.backButtonText:hover > .backButton {
  color: #222222 !important;
}

.content {
  padding: 0 4rem;
  margin-bottom: 2rem;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.headerTextGroup {
  display: flex;
  flex-direction: column;
}

.headerText {
  font-size: 39px;
  line-height: 50px;
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 1rem;
}

.headerSubText {
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
}

.backToDashboardButton {
  height: 50px;
  padding: 0 3rem;
  border: none;
  cursor: pointer;
  color: #c9c9c9;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: #ffffff;
  outline: none;
}

.backToDashboardButton:hover {
  color: #222222;
}

.cardContainer {
  display: flex;
  margin-top: 4rem;
  flex-direction: column;
  background-color: #ffffff;
}

.detailsContent {
  display: flex;
  flex-direction: column;
  padding: 4rem;
  border-bottom: 1px solid #e7e7e7;
}

.cardHeaderText {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
  margin-bottom: 1.5rem;
}

.required {
  color: #f56666;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}

.inputRow {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  justify-content: space-between;
}

.inputRow div:not(:only-child) {
  -ms-flex: 0 0 49%;
  flex: 0 0 49%;
  min-width: unset !important;
}

.inputRow div:only-child {
  flex: 1;
}

.inputBox {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #222222;
}

.inputBox::-webkit-outer-spin-button,
.inputBox::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.inputBox[type="number"] {
  -moz-appearance: textfield;
}

.dropdownGroup {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

.dropdownGroup1 {
  width: 100%;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

.dropdownBox {
  padding: 1.37rem 1rem !important;
  font-size: 20px !important;
  margin-top: 0.8rem !important;
  border-radius: 5px !important;
  border: 1px solid black !important;
}

.dropdownText {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
}

.inputRow1 {
  width: 100%;
  display: flex;
  margin-bottom: 1.5rem;
  justify-content: center !important;
}

.inputRow1 div:not(:only-child) {
  width: 100%;
}

.inputRow1 div:nth-of-type(1) {
  margin-right: 1%;
}

.inputRow1 div:nth-of-type(2) {
  margin-left: 1%;
}

.dropdownBox1 {
  padding: 0.675rem !important;
  font-size: 20px !important;
  margin-top: 0.8rem !important;
  border-radius: 5px !important;
  border: 1px solid black !important;
}

.dropdownBox1 a {
  font-weight: 400 !important;
  font-size: 20px;
  padding: 0.5rem !important;
  margin: 0.3rem !important;
}

.blueButton {
  text-align: center;
  margin-top: 2rem;
}

@media screen and (max-width: 820px) {
  .headerTextGroup {
    max-width: 380px;
  }
}

@media screen and (min-width: 992px) {
  .detailsContent {
    padding: 4rem 5rem 4rem;
  }
}

@media screen and (min-width: 1024px) {
  .detailsContent {
    padding: 4rem 8rem 4rem;
  }

  .required {
    font-size: 20px;
  }
}

@media screen and (min-width: 1100px) {
  .content {
    padding: 0 8rem;
  }
}

@media screen and (min-width: 1200px) {
  .detailsContent {
    padding: 4rem 12.5rem 4rem;
  }
}
