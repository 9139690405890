.main {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
}

.cardContent {
  padding: 4rem;
  background: #ffffff;
}

.content {
  display: flex;
  padding: 0 4rem;
  flex-direction: column;
  margin-bottom: 4rem;
}

.mainContainer {
  display: flex;
  flex-direction: column;
}

.headerText {
  font-style: normal;
  font-weight: normal;
  font-size: 39px;
  line-height: 46px;
  color: #272827;
  letter-spacing: -1px;
  margin-bottom: 1.8rem;
}

.inputRow {
  display: flex;
  justify-content: space-between;
}

.inputRow div {
  flex: 0.5;
}

.inputRow div:nth-child(1) {
  margin-right: 2rem;
}

.blueButton {
  margin-top: 2rem;
  align-self: center;
}

.inputBox {
  height: 60px;
  max-width: unset;
  padding: 0.5rem 1rem;
  /* font-size: 20px; */
  margin-top: 0.5rem;
  border-radius: 3px;
  border: 1px solid #000000;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
}

.inputName {
  font-size: 20px;
  color: #272828;
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
}

.inputCCName {
  font-family: sans-serif !important;
  color: #272828;
  font-weight: 400;
  font-size: 20px;
  height: 2.75em;
  line-height: 1.2em;
}

.subscriptionHeader {
  font-size: 20px;
  color: #272828;
}

.subscriptionData {
  font-size: 16px;
  color: #272828;
}

.deleteButton {
  font-size: 14px !important;
  line-height: 26px !important;
  padding: 0 !important;
  /* max-width: 24px;
    max-height: 24px; */
  width: 24px;
  height: 24px;
  margin-right: 0 !important;
  color: #f56666 !important;
  background-color: #feeded !important;
}

.deleteButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.warning {
  color: #f56666;
}

.warningText {
  font-size: 15px;
  color: #272828;
}

.headerContainer {
  display: block;
}

.headerTextGroup {
  margin-top: calc(50px + 2rem);
  margin-right: 1rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
}

.headerSubText {
  width: 70%;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  color: #272828;
}

.warningMessage {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  color: #ffffff;
  background: #f56666;
}

@media screen and (max-width: 820px) {
  .headerTextGroup {
    max-width: 380px;
  }
}

@media screen and (min-width: 1100px) {
  .content {
    padding: 0 8rem;
  }
}
