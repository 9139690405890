.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 6rem;
  margin-bottom: 4rem;
}

.backButtonContainer {
  text-align: right;
  display: none;
}

.backButtonText {
  width: 50px;
  height: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  align-self: flex-end;
  border-radius: 50%;
  background-color: #ffffff;
  outline: none;
}

.backButton {
  top: 13px;
  left: 13px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.backButtonText:hover > .backButton {
  color: #222222 !important;
}

.mainCard {
  display: flex;
  justify-content: center;
  padding: 0 1rem;
}

.cardContent {
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* max-width: 500px; */
  max-width: 900px;
  /* height: 673px; */
}

.headerText {
  color: #272827;
  font-size: 39px;
  line-height: 50px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 0.8rem;
}

.descriptionText {
  font-size: 16px;
  color: #272827;
  font-weight: 300;
  line-height: 25px;
  font-style: normal;
}

.stripeCard {
  margin-top: 2rem;
}

.blueButton {
  margin-top: 2rem;
  align-self: flex-start;
}

@media screen and (min-width: 500px) {
  .mainCard {
    padding: 0 4rem;
  }
  .cardContent {
    padding: 5rem 4rem;
  }
}

@media screen and (min-width: 768px) {
  .mainContainer {
    margin-top: 0;
  }
  .backButtonContainer {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .mainCard {
    display: flex;
    padding: 0 8rem;
    justify-content: center;
  }
}

@media screen and (min-width: 1100px) {
  .headerText {
    font-size: 39px;
  }
}
