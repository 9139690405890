.userCardContainer {
    /* display: flex; */
    /* flex-wrap: wrap; */
    margin-bottom: 4px;
    background-color: white;
    padding: 4rem 3rem 3rem 3.5rem;
}

.userCardTextGroup {
    /* -ms-flex: 0 0 100%; */
    /* flex: 0 0 100%; */
    /* max-width: 100%; */
    display: flex;
    flex-direction: column;
	padding-right: 30px;
}

.userCardHeaderText {
    font-size: 30px;
    line-height: 36px;
    font-weight: normal;
    margin-bottom: 1rem;
}

.userCardSubTextGroup {
    display: flex;
}

.userCardSubTextRight, .userCardSubTextLeft {
    font-size: 20px;
    font-weight: normal;
}

/* .userCardSubTextLeft { */
    /* -ms-flex: 0 0 30%; */
    /* flex: 0 0 30%; */
    /* max-width: 30%; */
/* } */

.userCardSubTextLeft {
    line-height: 32px;
	display: block;
    margin-right: 30px;
}

.userCardSubTextRight {
    line-height: 30px;
}

.userCardSubTextServices {
    /* -ms-flex: 0 0 70%; */
    /* flex: 0 0 70%; */
    /* max-width: 70%; */
    display: flex;
    flex-direction: column;
}

.userCardSubTextServices div {
    display: flex;
	overflow-wrap: break-word;
	max-width: 150px;
}

.userCardSubTextServices div:not(:last-child) {
    margin-bottom: .5rem;
}

.userCardSubTextServices i {
    margin-top: .4rem !important;
}

.userCardSubTextServiceCheck {
    color: #8BC334 !important;
}

.userCardActionsGroup {
    /* -ms-flex: 0 0 50%; */
    /* flex: 0 0 100%; */
    /* max-width: 290px; */
    /* margin-top: 2rem; */
    display: flex;
    flex-direction: column;
}

.userCardActionsRow {
    display: flex;
    justify-content: flex-start;
}

.userCardButtonGroup {
    display: flex;
    flex-direction: column;
}

.userCardButtonGroup:not(:first-child) {
    margin-left: 2rem;
}

.userCardTasksButton, .userCardEditButton, .userCardRemoveButton {
    font-size: 40px!important;
    line-height: 46px!important;
    padding: 1rem!important;
}

.userCardTasksButton {
    margin-right: 0 !important;
    color: #8BC334 !important;
    background-color: #f1f8e7 !important;
}

.userCardTasksButton:hover {
    color: #ffffff !important;
    background-color: #8BC334 !important;
}

.userCardEditButton {
    margin-right: 0 !important;
    color: #388CEF !important;
    background-color: #e7f1fd !important;
}

.userCardEditButton:hover {
    color: #ffffff !important;
    background-color: #388CEF !important;
}

.userCardRemoveButton {
    margin-right: 0 !important;
    color: #F56666 !important;
    background-color: #feeded !important;
}

.userCardRemoveButton:hover {
    color: #ffffff !important;
    background-color: #F56666 !important;
}

.userCardButtonText {
    margin-top: 1rem;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}

.somethingButton {
    margin-top: 1.5rem;
    padding: 1.3rem 0rem;
    border: none;
    cursor: pointer;
    color: black;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    border-radius: 5px;
    background: rgb(228 228 228 / .5);
}

.somethingButton:hover {
    background: #E5E5E5;
}

@media screen and (min-width: 1024px) {
    
    /* .userCardTextGroup { */
        /* -ms-flex: 0 0 60%; */
        /* flex: 0 0 60%; */
        /* max-width: 60%; */
        /* margin-right: 2%; */
    /* } */
    
    /* .userCardActionsGroup { */
        /* -ms-flex: 0 0 38%; */
        /* flex: 0 0 38%; */
        /* max-width: 38%; */
        /* margin-top: 0; */
    /* } */
    
    /* .userCardActionsRow { */
        /* justify-content: flex-end; */
    /* } */
	
	.userCardSubTextServices div {
		max-width: unset;
	}
	
	.userCardSubTextLeft {
		margin-right: 100px;
	}
	
	.userCardContainer {
		padding: 4rem 3rem 3rem 4.5rem;
	}

}

@media screen and (min-width: 1100px) {
    
    /* .userCardTextGroup { */
        /* -ms-flex: 0 0 65%; */
        /* flex: 0 0 65%; */
        /* max-width: 65%; */
    /* } */
    
    /* .userCardActionsGroup { */
        /* -ms-flex: 0 0 33%; */
        /* flex: 0 0 33%; */
        /* max-width: 33%; */
    /* } */
    
}

@media screen and (min-width: 1200px) {
    
    /* .userCardTextGroup { */
        /* margin-right: 5%; */
    /* } */
    
    /* .userCardActionsGroup { */
        /* -ms-flex: 0 0 30%; */
        /* flex: 0 0 30%; */
        /* max-width: 30%; */
    /* } */
    
}