.closeButton {
  top: 0;
  right: 0;
  position: absolute;
  margin-right: 0 !important;
  color: #f56666 !important;
  border-radius: none !important;
  background-color: #feeded !important;
}

.closeButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.headerText {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
}

.subText {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
}

.tab,
.selectedTab {
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
  height: 80px;
  width: 50%;
  cursor: pointer;
}

.tab,
.selectedTab1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
  height: 80px;
  width: 50%;
  cursor: pointer;
}

.selectedTab {
  color: #ffffff;
  background: #8bc334;
  position: relative;
}

.selectedTab1 {
  color: #ffffff;
  background: #ffb800;
  position: relative;
}

.selectedTab::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-top-color: rgb(140, 195, 54);
  border-bottom: 0;
  margin-left: -20px;
  margin-bottom: -15px;
}

.selectedTab1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-top-color: #ffb800;
  border-bottom: 0;
  margin-left: -20px;
  margin-bottom: -15px;
}

.tab {
  color: #b7b8b6;
  background: #f7f8f6;
}

.saveButton {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #85c341;
  background-color: #e7f3d9;
  border-radius: 5px;
  height: 60px;
  width: 240px;
}

.saveButton:hover {
  color: #ffffff;
  background-color: #85c341;
}

textarea {
  height: 130px;
}
