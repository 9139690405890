.modalContainer {
    display: flex;
    padding: 2rem 4rem;
    flex-direction: column;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0 !important;
    color: #F56666 !important;
    border-radius: 0!important;
    background-color: #feeded !important;
}  

.closeButton:hover {
    color: #ffffff !important;
    background-color: #F56666 !important;
}

.headerText {
    margin-top: 1rem;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    font-weight: normal;
    margin-bottom: 3rem;
}

.filterButton {
    align-self: center;
}