.mainContainer {
  display: flex;
  flex-direction: column;
}

.headerText {
  font-size: 30px;
  line-height: 36px;
  font-weight: normal;
  margin-bottom: 3rem;
}

.invoiceFiltersRow {
  margin-bottom: 1.5rem;
}

.filterDropdown > i {
  font-size: 16px !important;
}

.filterDropdown > div {
  font-size: 16px !important;
}

.filterDropdown > * {
  line-height: 28px !important;
  color: #bebebe !important;
  font-weight: 400 !important;
}

.invoice {
  display: flex;
  padding: 2rem 0;
  justify-content: space-between;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.invoiceTextDetailsColumn {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.invoiceServiceLabel {
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
}

.sendEmailButton {
  margin-left: 20px;
  padding: 1.3rem 2rem;
  border: none;
  cursor: pointer;
  color: #8bc334;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: #f1f8e7;
  outline: none;
}

.sendEmailButton:hover {
  color: #ffffff;
  background-color: #8bc334;
}

.download button {
  width: 220px;
  height: 60px;
  padding: 0 !important;
}

.date {
  margin-right: 30px;
}

.invoicenumber,
.date {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
}

.invoicenumber {
	display: block;
}

.subtext {
  margin-top: 13px;
}

@media screen and (min-width: 992px) {
  .filterDropdown > i {
    font-size: 20px !important;
  }

  .filterDropdown > div {
    font-size: 22px !important;
  }
  
  .invoicenumber {
	display: unset;
  }
}

@media screen and (min-width: 1024px) {
  .filterDropdown > div {
    font-size: 24px !important;
  }	
}

@media screen and (min-width: 1100px) {

}

@media screen and (min-width: 1150px) {
  .invoiceTextDetailsColumn {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
}
