.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10rem;
  margin-top: 6rem;
}

.backButtonContainer {
  text-align: right;
  display: none;
}

.backButtonText {
  /* width: 50px;
    height: 50px; */
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  align-self: flex-end;
  /* border-radius: 50%; */
  background-color: #ffffff;
  outline: none;
}

.backButtonArrow {
  width: 50px;
  height: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  align-self: flex-end;
  border-radius: 50%;
  background-color: #ffffff;
  outline: none;
}

.backButtonArrow:hover > .backButton {
  color: #222222 !important;
}

.backButton {
  top: 13px;
  left: 13px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.backButtonText:hover > .backButton {
  color: #222222 !important;
}

.headerText {
  margin-bottom: 3rem;
}

.headerText h1 {
  font-weight: 400;
  font-size: 39px;
  line-height: 46px;
  letter-spacing: -1px;
  color: #272827;
}
.headerText text {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #272828;
}

.headerText {
  padding: 0 1rem;
}
.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 1rem;
}

.cardTabHighlighted,
.cardTabFaded {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 49.4%;
  padding: 1.5rem 0;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  background-color: #ffffff;
  border-bottom: 4px solid #ffffff;
}

.cardTabFaded {
  cursor: pointer;
  color: #c9c9c9;
  background-color: #f7f8f6;
}

.cardTabBorder {
  cursor: pointer;
  border-bottom: 4px solid #eaeee8;
}

.cardTabBorder.left {
  margin-right: 4px;
}

.cardTabBorder.right {
  margin-left: 4px;
}

.emptyColumn {
  flex-grow: 1;
}

.cardGroup {
  display: flex;
  flex-direction: column;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 360px;
}
.cardGroup .cardGroupHeader {
  display: flex;
}

.loggedOut .cardGroup:first-child .cardGroupHeader,
.loggedOut .cardGroup:first-child .checkTextGroup {
  display: none;
}

.cardGroup:not(:last-child) {
  margin-bottom: 1rem;
}

.cardContainer {
  padding-bottom: 2rem;
  background-color: #ffffff;
  box-shadow: 4px 8px 8px -10px #888;
  height: 100%;
}

.cardContent {
  display: flex;
  padding: 0 2rem;
  flex-direction: column;
}

.cardHeaderGroup {
  display: flex;
  margin-top: 2.85rem;
  margin-bottom: 1rem;
}

.selectedCardHeader {
  font-size: 70px;
  line-height: 83px;
  font-weight: normal;
  letter-spacing: -1px;
  align-self: flex-end;
}

.selectedCardSubheader {
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 50px;
  margin-top: 0;
}

.selectedCardDescription {
  font-size: 17px;
  font-weight: 300;
  font-style: normal;
  line-height: 28px;
  margin-bottom: 2rem;
}

.checkTextGroup {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.checkText {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 19px;
  margin-left: 5px;
}

.blueButton {
  margin-top: 2rem;
  align-self: flex-start;
}

.blueButton:hover {
  color: #ffffff;
  background-color: #388cef;
}

.cardDescriptionGreen {
  font-size: 12px;
  line-height: 14px;
  color: #8bc334;
  padding: 12px;
  background: #f4f9eb;
  border: 1px solid rgba(139, 195, 52, 0.3);
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.cardDescriptionGreen .cardDescriptionGreenIcon {
  margin-right: 0.5rem;
}

.asterisk {
  color: #f56666;
}

.pricingFooterText {
  margin-top: 1.5rem;
}
.pricingFooterText text {
  font-weight: 300;
  font-style: italic;
  font-size: 16px;
  line-height: 25px;
}
.pricesText {
  text-align: center;
  margin-top: 2.5rem;
}
.pricesText text {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #272828;
}

@media screen and (min-width: 470px) {
  .cardsContainer,
  .headerText {
    padding: 0 4rem;
  }
}

@media screen and (min-width: 768px) {
  .mainContainer {
    margin-bottom: 6rem;
    margin-top: 0;
  }

  .backButtonContainer {
    display: block;
  }

  .loggedOut .cardsContainer {
    justify-content: unset;
  }
  .cardGroup {
    -ms-flex: 0 0 50%;
    flex: 0 0 49%;
  }
  .cardGroup:not(:last-child) {
    margin-bottom: 0;
  }
  .loggedOut .cardGroup:last-child {
    margin-top: 2%;
  }
  .cardGroup:nth-child(3n + 2) {
    margin-left: 2%;
  }
  .loggedOut .cardGroup:first-child .checkTextGroup {
    display: block;
  }
  .loggedOut .cardGroup:first-child .cardGroupHeader {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .headerText {
    max-width: 630px;
  }
}

@media screen and (min-width: 1100px) {
  .cardsContainer {
    padding: 0 8rem;
  }

  .headerText {
    padding: 0 0 0 8rem;
  }
}

@media screen and (min-width: 1366px) {
  .cardGroup:nth-child(3n + 2) {
    margin-right: 22px;
    margin-left: 22px;
  }
  .loggedOut .cardGroup:last-child {
    margin-top: 0;
  }
  .cardGroup:last-child {
    margin-top: 0;
  }
  .headerText {
    padding: 0 0 0 8.64rem;
  }
  .cardsContainer {
    margin-top: 1rem;
    padding: 0 8.64rem;
    justify-content: center;
  }
}
