.backButtonContainer {
  text-align: right;
}

.backButtonText {
  width: 50px;
  height: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  align-self: flex-end;
  border-radius: 50%;
  background-color: #ffffff;
  outline: none;
}

.backButton {
  top: 13px;
  left: 13px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.backButtonText:hover > .backButton {
  color: #222222 !important;
}

.mainCard {
  display: flex;
  padding: 0 4rem;
}

.cardContent {
  /* width: 747px; */
  width: 490px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 4rem 2.5rem;
}

.headerText {
  font-size: 36px;
  line-height: 46px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 10px;
}

.descriptionText {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  font-style: normal;
}

.dropdownGroup {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

.dropdownName {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
}

.dropdownBox {
  height: 60px;
  padding: 0.5rem 1rem;
  font-size: 20px;
  margin-top: 0.5rem;
  border-radius: 5px;
  border: 1px solid black;
  line-height: 0;
  width: 100%;
  max-width: 100%;
  text-shadow: #ffffff;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  /* background-image: url('../../static/images/dropdown-arrow.png'); */
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
}

.buttonGroup {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.buttonGroup div {
  margin-right: 1rem;
}

.buttonStep {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.buttonStep button {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #979797;
  cursor: pointer;
  background-color: unset;
  border: unset;
  padding: 0;
}

.buttonStep button:hover {
  color: #222222;
}

.buttonStep button i {
  width: unset;
}

.buttonStep {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.buttonStep button {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #979797;
  cursor: pointer;
  background-color: unset;
  border: unset;
  padding: 0;
}

.buttonStep button i {
  width: unset;
}

.buttonStep button.buttonStepBack i {
  margin-right: 0.5rem;
}

.buttonStep button.buttonFinishLater i {
  margin-left: 0.5rem;
  margin-right: 0;
}

.buttonStep i {
  background-color: unset !important;
}

.buttonNextStep {
  padding: 1.3rem 3rem;
  background-color: rgb(139 195 52 / 0.12);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #8bc334;
}

.buttonNextStep:hover {
  color: #ffffff;
  background-color: #8bc334;
}

.stepsColumn {
  width: 150px;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.stepItem {
  color: #ffffff;
  text-align: center;
  background: #8bc334;
  border-radius: 222px;
  padding: 0.5rem 1rem;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  margin-bottom: 10px;
}

.stepItemIndicatorContainer {
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 50%;
  /* margin-bottom: 10px; */
  margin-right: 10px;
  position: relative;
  background-color: #ffffff;
}

.stepItemContainer {
  margin-bottom: 10px;
  cursor: pointer;
}

.stepItemContainer .stepItemCurrentText {
  display: flex;
  align-items: center;
}

.stepItemContainer:hover .stepItemFill {
  background-color: #222222;
}

.stepItemContainer:hover .stepItemCurrentText {
  color: #222222;
}

.stepItemFill {
  width: 7px;
  height: 7px;
  top: 14px;
  left: 14px;
  border: none;
  border-radius: 50%;
  position: absolute;
  background-color: #c4c4c4;
}

.stepItemFillDone {
  position: absolute;
  top: 10px;
  left: 15px;
  display: inline-block;
  transform: rotate(45deg);
  height: 14px;
  width: 7px;
  border-bottom: 3px solid #8bc334;
  border-right: 3px solid #8bc334;
}

.stepItemUnfinished {
  top: 6px !important;
  left: 9px !important;
  position: absolute;
  font-size: 16px !important;
  color: #f56666 !important;
}

.CurrentItemContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: -25px;
}

.CurrentItemContainer .currentItemFill {
  background-color: #222222;
}

.CurrentItemContainer .stepItemCurrentText {
  color: #222222;
}

.stepItemCurrent {
  display: inline-block;
  width: 60px;
  height: 35px;
  border: none;
  border-radius: 0 50px 50px 0;
  margin-right: 10px;
  position: relative;
  background-color: #ffffff;
}

.currentItemFill {
  width: 7px;
  height: 7px;
  top: 14px;
  right: 14px;
  border: none;
  border-radius: 50%;
  position: absolute;
  background-color: #c4c4c4;
}

.stepItemCurrentText {
  color: #c0c2bf;
}

.stepContainerText {
  color: #222222;
  display: flex;
  align-items: center;
}

.stepItemTickContainer .stepContainerText {
  color: #8bc334 !important;
}

.stepItemCrossContainer .stepContainerText {
  color: #f56666 !important;
}

.inputRow {
  margin-top: 1rem;
}

.inputColumn {
  display: flex;
}

.inputColumn:first-child > div:first-child {
  flex: 1;
}

.inputColumn:not(:first-child) > div:first-child {
  margin-right: 1rem;
}

.inputColumn:not(:first-child) > div {
  flex: 0.5;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  margin-bottom: 2rem;
  padding: 0 4rem;
}

.headerContainer h1 {
  font-weight: 400;
  font-size: 39px;
  line-height: 50px;
  letter-spacing: -1px;
  color: #272827;
  margin-bottom: 0.8rem;
}

.headerContainer p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.required {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #f56666;
}

.stepItemCurrentText,
.stepsName {
  width: 120px;
}

@media screen and (min-width: 992px) {
  .cardContent {
    width: 100%;
    padding: 5rem 4rem;
  }

  .stepsColumn {
    width: 200px;
  }

  .inputColumn:not(:first-child) > div:first-child {
    margin-right: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .required {
    font-size: 20px;
  }
}

@media screen and (min-width: 1100px) {
  .mainCard {
    display: flex;
  }
  .mainCard,
  .headerContainer {
    max-width: 1037px;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
  }

  .headerText {
    font-size: 39px;
  }

  .cardContent {
    width: 872px;
  }

  .stepsColumn {
    width: unset;
  }
}
