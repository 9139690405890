.mainContainer {
  /* display: flex;
  flex-wrap: wrap; */
}

.headerText {
  font-size: 30px;
  line-height: 36px;
  font-weight: normal;
  margin-bottom: 2rem;
  display: block;
}

.subscriptionServicesDetailsColumn:first-child {
  margin-bottom: 3rem;
}

.subscriptionServicesDetailsColumn {
  width: 100%;
}

.subscriptionServicesDetails {
  margin-bottom: 4rem;
}

.subscriptionrow {
  display: flex;
  justify-content: space-between;
}

.subscriptionlabel .subsdetailsText:first-child,
.subscriptiondata .subsdetailsText:first-child {
  margin-bottom: 10px;
}

.subsdetailsText {
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
  display: block;
}

.buttonColumn {
  display: flex;
}

.monthlyPlan {
  margin-right: 20px;
  padding: 1.3rem 3rem;
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: #8bc334;
  display: block;
  outline: none;
}

.monthlyPlan:hover {
  background-color: #97d439;
}

.yearlyPlan {
  padding: 1.3rem 3rem;
  border: none;
  cursor: pointer;
  color: #333333;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: rgb(244 244 244 / 0.5);
  display: block;
  outline: none;
}

.yearlyPlan:hover {
  background-color: #e5e5e5;
}

.cancelsubsGroup {
  margin-top: 4rem;
  display: flex;
  align-items: center;
}

.cancelsubsGroup:hover {
  opacity: 0.7;
}

.cancelsubsIcon {
  font-size: 18px !important;
  /* background-color: #f56666; */
  color: #ffffff;
  /* border-radius: 50%; */
  /* padding: 9px 25px 25px 10px; */
}

.cancelsubsText {
  color: #f56666;
  margin-left: 5px;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  text-decoration-line: underline;
}

.cancelsubsText:hover {
  color: rgb(245 102 102 / 0.8);
  text-decoration: underline;
}

.servicesContainer {
}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e7e7e7;
  /* border-bottom: 1px solid #e7e7e7; */
  padding: 25px 0px;
}

.servicesDetailsGroup {
  width: 60%;
}
.servicesActionsGroup {
  width: 40%;
}

.serviceName {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: #272828;
}

.serviceLabel {
  font-style: normal;
  font-weight: normal;
  color: #272828;
}

.serviceLabel1 {
  font-style: normal;
  font-weight: normal;
}

.subsType,
.subsCost {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #272828;
  display: block;
}

.subsCostFailed {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;

  display: block;
}

.subsType {
  margin-bottom: 4px;
}

.subtext {
  margin-top: 10px;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
}

.buttonGroup {
  /* text-align: center; */
  /* width: 50%; */
}

.btnUpgrade,
.BtnEdit {
  font-size: 20px !important;
  /* line-height: 29px !important; */
  margin-right: 0px !important;
  width: 190px;
  height: 60px;
  /* padding: 15px 20px !important; */
}

.btnUpgrade {
  color: #8bc334 !important;
  background-color: #f1f8e7 !important;
}

.btnUpgrade:hover {
  color: #ffffff !important;
  background-color: #8bc334 !important;
}

.BtnEdit {
  color: #388cef !important;
  background-color: #e7f1fd !important;
}

.BtnEdit:hover {
  color: #ffffff !important;
  background-color: #388cef !important;
}

.buttonText {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  /* text-align: center;	 */
  /* margin-top: 1rem; */
  display: block;
}

.btnReview {
  width: 283px;
  height: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #f56666;
  border-radius: 5px;
  background: rgba(245, 102, 102, 0.12);
}

.btnReview:hover {
  background: rgba(245, 102, 102, 0.18);
}

.addNewbtnContainer {
  text-align: right;
  margin-top: 4rem;
}

.btnAddNew {
  padding: 1.3rem 3rem;
  border: none;
  cursor: pointer;
  color: #8bc334;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  border-radius: 5px;
  text-decoration: none;
  background-color: #f1f8e7;
  outline: none;
}

.btnAddNew:hover {
  color: #ffffff;
  background-color: #8bc334;
}

@media screen and (min-width: 992px) {
  .servicesDetailsGroup {
    width: 70%;
  }
  .servicesActionsGroup {
    width: 30%;
  }
}

@media screen and (min-width: 1260px) {
  .subscriptionServicesDetailsColumn:first-child {
    margin-right: 4%;
  }
  .subscriptionServicesDetailsColumn {
    width: 48%;
  }

  .servicesDetailsGroup {
    width: 55%;
  }
  .servicesActionsGroup {
    width: 45%;
  }
}

@media screen and (min-width: 1300px) {
  .servicesDetailsGroup {
    width: 60%;
  }
  .servicesActionsGroup {
    width: 40%;
  }
}

.btnResubscribe {
  width: 190px;
  height: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #f56666;
  background: rgba(245, 102, 102, 0.12);
}

.btnResubscribe:hover {
  background: rgba(245, 102, 102, 0.18);
}
